import moment from "moment";
import { string, object, mixed, number, array } from "yup";

const validatePhone = (phone) =>
  /[0]{1}[1-7|9]{1}[0-9]{8}/.test(phone.replace(/ /g, "")) &&
  phone.replace(/ /g, "").length === 10;

export const validNumberComma = (number) => {
  const numberDot = +number.replace(/,/gi, ".");
  if (numberDot >= 0) {
    return true;
  }
  return false;
};

export const VALID_TYPE_DOCUMENT = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "image/heic",
  "image/heif",
];

export const validateDocumentType = ([file = {}]) => {
  return VALID_TYPE_DOCUMENT.includes(getDocumentTypeValue(file));
};

export const getDocumentTypeValue = (source) => {
  if (source.name?.toLowerCase().indexOf(".heic") !== -1) {
    return "image/heic";
  } else if (source.name?.toLowerCase().indexOf(".heif") !== -1) {
    return "image/heif";
  } else {
    return source.type;
  }
};

export const getDocumentType = (source) => {
  return new Promise((resolve) => {
    resolve(getDocumentTypeValue(source));
  });
};

export const validateDocumentSize = ([file = {}]) => {
  // Size < 20 MB
  return file.size < 2e7;
};

export const agencySchema = () =>
  object().shape({
    name: string().required("Ce champ est requis"),
    firstname: string()
      .matches(/[a-zA-Z-]+$/, "Saisie incorrecte")
      .required("Ce champ est requis"),
    lastname: string()
      .matches(/[a-zA-Z-]+$/, "Saisie incorrecte")
      .required("Ce champ est requis"),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
    phone: mixed().test("check-phone", "Format invalide", (value) =>
      value ? validatePhone(value) : false
    ),
    address: string().required("Ce champ est requis"),
    zipCode: string()
      .length(5, "Code postal invalide")
      .required("Ce champ est requis"),
    city: string()
      .matches(/[a-zA-Z-]+$/, "Saisie incorrecte")
      .required("Ce champ est requis"),
  });

export const agentSchema = () =>
  object().shape({
    firstname: string()
      .matches(/[a-zA-Z-]+$/, "Saisie incorrecte")
      .required("Ce champ est requis"),
    lastname: string()
      .matches(/[a-zA-Z-]+$/, "Saisie incorrecte")
      .required("Ce champ est requis"),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
    phone: mixed().test("check-phone", "Format invalide", (value) =>
      value ? validatePhone(value) : false
    ),
    agencyId: string().required("Ce champ est requis"),
  });

export const bankSchema = () =>
  object().shape({
    name: string().required("Ce champ est requis"),
    firstname: string().required("Ce champ est requis"),
    lastname: string().required("Ce champ est requis"),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
    phone: mixed().test("check-phone", "Format invalide", (value) =>
      value ? validatePhone(value) : false
    ),
    address: string().required("Ce champ est requis"),
    zipCode: string()
      .length(5, "Code postal invalide")
      .required("Ce champ est requis"),
    city: string().required("Ce champ est requis"),
  });

export const folderAdminSchema = () =>
  object().shape({
    address: string().nullable(),
    agencyFees: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    amountTaxIncome: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier")
      .required("Ce champ est requis"),
    city: string().nullable(),
    contribution: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    dateLimitClauses: mixed().test(
      "check-date",
      "Ce champ est requis",
      (value) => moment(value).isValid()
    ),
    dateSignAgreement: mixed().test(
      "check-date",
      "Ce champ est requis",
      (value) => moment(value).isValid()
    ),
    dateSignNotary: mixed().test("check-date", "Ce champ est requis", (value) =>
      moment(value).isValid()
    ),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
    emailNotary: string().email("L'e-mail n'est pas valide").nullable(),
    estateAmount: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    firstname: string().required("Ce champ est requis"),
    fiscalReference: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    fiscalShare: mixed()
      .test("check-number", "Ce champ doit être un nombre positif", (value) =>
        value ? validNumberComma(value) : false
      )
      .required("Ce champ est requis"),
    lastname: string().required("Ce champ est requis"),
    loanAmount: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif"),
    loanDuration: number()
      .integer("Ce champ doit être un nombre entier positif")
      .typeError("Ce champ doit être un nombre entier positif")
      .max(999, "Le nombre ne peut pas être supérieur à 999")
      .required("Ce champ est requis"),
    loanType: string().required("Ce champ est requis"),
    notaryFees: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    offerSocieteGenerale: object().shape({
      rate: mixed().test(
        "check-number",
        "Ce champ doit être un nombre positif",
        (value) => (value ? validNumberComma(value) : false)
      ),
      applicationFees: number()
        .integer("Ce champ doit être un nombre entier positif")
        .typeError("Ce champ doit être un nombre entier positif")
        .min(0, "Les frais de dossier ne peuvent pas être négatif")
        .required("Ce champ est requis"),
      consultingFees: number()
        .integer("Ce champ doit être un nombre entier positif")
        .typeError("Ce champ doit être un nombre entier positif")
        .min(0, "Les frais de conseil ne peuvent pas être négatif")
        .required("Ce champ est requis"),
    }),
    offerOfficer: object().shape({
      rate: mixed().test(
        "check-number",
        "Ce champ doit être un nombre positif",
        (value) => (value ? validNumberComma(value) : false)
      ),
      applicationFees: number()
        .integer("Ce champ doit être un nombre entier positif")
        .typeError("Ce champ doit être un nombre entier positif")
        .min(0, "Les frais de dossier ne peuvent pas être négatif")
        .required("Ce champ est requis"),
      consultingFees: number()
        .integer("Ce champ doit être un nombre entier positif")
        .typeError("Ce champ doit être un nombre entier positif")
        .min(0, "Les frais de conseil ne peuvent pas être négatif")
        .required("Ce champ est requis"),
    }),
    phone: mixed().test("check-phone", "Format invalide", (value) =>
      value ? validatePhone(value) : true
    ),
    worksAmount: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    zipCode: string().length(5, "Code postal invalide").nullable(),
  });

export const folderAgentSchema = () =>
  object().shape({
    address: string().nullable(),
    agencyFees: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    city: string().nullable(),
    contribution: mixed()
      .test(
        "integer",
        "Ce champ doit être un nombre entier positif",
        (value) => +value >= 0 && Number.isInteger(+value)
      )
      .test({
        name: "contribution",
        exclusive: false,
        message: "Minimum de 10% du plan de financement du compromis",
        test: function (value) {
          return (
            +value >=
            (+this.parent.estateAmount +
              +this.parent.agencyFees +
              +this.parent.notaryFees +
              +this.parent.worksAmount) *
              0.1
          );
        },
      }),
    dateLimitClauses: mixed().test(
      "check-date",
      "Ce champ est requis",
      (value) => moment(value).isValid()
    ),
    dateSignAgreement: mixed().test(
      "check-date",
      "Ce champ est requis",
      (value) => moment(value).isValid()
    ),
    dateSignNotary: mixed().test("check-date", "Ce champ est requis", (value) =>
      moment(value).isValid()
    ),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
    emailNotary: string().email("L'e-mail n'est pas valide").nullable(),
    estateAmount: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    files: array()
      .test("check-type", "Compromis manquant ou invalide", (value) =>
        value ? validateDocumentType(value) : false
      )
      .test("check-size", "Compromis trop lourd. 20 MB max.", (value) =>
        value ? validateDocumentSize(value) : false
      ),
    firstname: string().required("Ce champ est requis"),
    lastname: string().required("Ce champ est requis"),
    loanAmount: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif"),
    loanDuration: number()
      .integer("Ce champ doit être un nombre entier positif")
      .typeError("Ce champ doit être un nombre entier positif")
      .max(999, "Le nombre ne peut pas être supérieur à 999")
      .required("Ce champ est requis"),
    loanType: string().required("Ce champ est requis"),
    notaryFees: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    phone: mixed().test("check-phone", "Format invalide", (value) =>
      value ? validatePhone(value) : false
    ),
    secondBuyerEmail: string()
      .email("L'e-mail n'est pas valide")
      .when("loanType", {
        is: "deux",
        then: string().required("Ce champ est requis"),
      }),
    secondBuyerFirstname: string().when("loanType", {
      is: "deux",
      then: string().required("Ce champ est requis"),
    }),
    secondBuyerLastname: string().when("loanType", {
      is: "deux",
      then: string().required("Ce champ est requis"),
    }),
    secondBuyerPhone: mixed().when("loanType", {
      is: "deux",
      then: mixed().test("check-phone", "Format invalide", (value) =>
        value ? validatePhone(value) : false
      ),
    }),
    worksAmount: number()
      .integer("Nombre entier uniquement")
      .typeError("Ce champ doit être un nombre entier positif")
      .min(0, "Ce nombre doit être positif")
      .required("Ce champ est requis"),
    zipCode: string().length(5, "Code postal invalide").nullable(),
  });

export const userDocumentSchema = () =>
  object().shape({
    taxNotices: array()
      .test("check-type", "Avis d'imposition manquant ou invalide", (value) =>
        value ? validateDocumentType(value) : true
      )
      .test("check-size", "Avis d'imposition trop lourd. 20 MB max.", (value) =>
        value ? validateDocumentSize(value) : false
      ),
  });

export const successDocumentSchema = () =>
  object().shape({
    phone: mixed().test("check-phone", "Format invalide", (value) =>
      value ? validatePhone(value) : true
    ),
  });
