import JwtDecode from "jwt-decode";

export const isTokenValid = (token) => {
  try {
    JwtDecode(token);
    return true;
  } catch (err) {
    return false;
  }
};
