import { Box, makeStyles, Typography } from "@material-ui/core";

const InformationLine = ({
  name,
  value,
  fontSizeValue = "1rem",
  fontSizeName = "1rem",
  subname,
  ...props
}) => {
  const styleInformationLine = makeStyles((theme) => ({
    name: { color: "#4B5563", fontSize: fontSizeName },
    value: { color: "#002345", fontSize: fontSizeValue, fontWeight: 800 },
  }));
  const classes = styleInformationLine();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flex={1}
      paddingBottom="1rem"
      {...props}
    >
      <Typography component="span" className={classes.name}>
        {name}
        <Typography component="p" className={classes.name}>
          {subname}
        </Typography>
      </Typography>
      <Typography component="span" className={classes.value}>
        {value}
      </Typography>
    </Box>
  );
};

export default InformationLine;
