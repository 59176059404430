import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FolderIcon from "@material-ui/icons/Folder";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";

import AddAgencyAdminPage from "./pages/Admin/AddAgencyAdminPage";
import AddAgentAdminPage from "./pages/Admin/AddAgentAdminPage";
import AddBankAdminPage from "./pages/Admin/AddBankAdminPage";
import AddSnapdocAgentPage from "./pages/Agent/AddSnapdocAgentPage";
import CCMPage from "./pages/CCMPage";
import CGUPage from "./pages/CGUPage";
import EditAgencyAdminPage from "./pages/Admin/EditAgencyAdminPage";
import EditAgentAdminPage from "./pages/Admin/EditAgentAdminPage";
import EditBankAdminPage from "./pages/Admin/EditBankAdminPage";
import EditSnapdocAdminPage from "./pages/Admin/EditSnapdocAdminPage";
import IndexUserPage from "./pages/User/IndexUserPage";
import ListAgencyAdminPage from "./pages/Admin/ListAgencyAdminPage";
import ListAgentAdminPage from "./pages/Admin/ListAgentAdminPage";
import ListBankAdminPage from "./pages/Admin/ListBankAdminPage";
import ListSnapdocAdminPage from "./pages/Admin/ListSnapdocAdminPage";
import ListSnapdocAgentPage from "./pages/Agent/ListSnapdocAgentPage";
import LoginPage from "./pages/Auth/LoginPage";
import LostPage from "./pages/Auth/LostPage";
import POLCONFPage from "./pages/POLCONFPage";
import ProfilPage from "./pages/ProfilPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import ResetPage from "./pages/Auth/ResetPage";
import ShowBankAdminPage from "./pages/Admin/ShowBankAdminPage";

export const disconnected = {
  login: {
    path: "/connexion",
    component: LoginPage,
    text: "Connexion",
    description:
      "Connectez-vous à votre espace client afin d’accéder à votre dossier",
  },
  loginWithEmail: {
    path: "/connexion/:email?",
    component: LoginPage,
    text: "Connexion",
  },
  register: {
    path: "/inscription",
    component: RegisterPage,
    text: "Inscription",
  },
  registerWithCode: {
    path: "/inscription/:activationCode/:email?",
    component: RegisterPage,
    text: "Inscription",
  },
  lostPass: {
    path: "/perte-motdepasse",
    component: LostPage,
    text: "Perte du mot de passe",
  },
  resetPass: {
    path: "/reset-motdepasse",
    component: ResetPage,
    text: "Réinitialisation du mot de passe",
  },
  resetPassWithCode: {
    path: "/reset-motdepasse/:resetToken/:email",
    component: ResetPage,
    text: "Réinitialisation du mot de passe",
  },
  cgu: {
    path: "/cgu",
    component: CGUPage,
    text: "Conditions Générales d’Utilisation",
  },
  polconf: {
    path: "/politique-de-confidentialité",
    component: POLCONFPage,
    text: "Politique de confidentialité",
  },
  ccm: {
    path: "/comment-ca-marche",
    component: CCMPage,
    text: "Comment ça marche",
  },
};

export const connected = {
  profil: {
    path: "/profil",
    component: ProfilPage,
    text: "Mon compte",
    icon: <SearchIcon />,
  },
};

export const userRoutes = {
  index: {
    path: "/",
    component: IndexUserPage,
    text: "Mon projet",
    icon: <SearchIcon />,
  },
};

export const privateRoutes = {
  addSnapdoc: {
    text: "Nouveau dossier",
    component: AddSnapdocAgentPage,
    path: "/agent/dossiers/ajout",
    icon: <AddCircleIcon />,
  },
  listSnapdoc: {
    text: "Suivi des dossiers",
    component: ListSnapdocAgentPage,
    path: "/agent/dossiers",
    icon: <SearchIcon />,
  },
};

export const superAdmin = {
  addAgency: {
    path: "/admin/agences/ajout",
    component: AddAgencyAdminPage,
    text: "Ajouter une agence",
    icon: <AddCircleIcon />,
  },
  editAgency: {
    path: "/admin/agences/edit/:agencyId",
    component: EditAgencyAdminPage,
    text: "Modifier une agence",
    icon: <AddCircleIcon />,
    hide: true,
  },
  listAgency: {
    path: "/admin/agences",
    component: ListAgencyAdminPage,
    text: "Liste des agences",
    icon: <HomeIcon />,
  },
  addAgent: {
    path: "/admin/utilisateurs/ajout",
    component: AddAgentAdminPage,
    text: "Ajouter un agent",
    icon: <PersonAddIcon />,
  },
  editAgent: {
    path: "/admin/utilisateurs/edit/:agentId",
    component: EditAgentAdminPage,
    text: "Modifier un agent",
    icon: <AddCircleIcon />,
    hide: true,
  },
  listAgent: {
    path: "/admin/utilisateurs",
    component: ListAgentAdminPage,
    text: "Liste des agents",
    icon: <PeopleIcon />,
  },
  addBank: {
    path: "/admin/banques/ajout",
    component: AddBankAdminPage,
    text: "Ajouter une banque",
    icon: <AddCircleIcon />,
  },
  editBank: {
    path: "/admin/banques/edit/:bankId",
    component: EditBankAdminPage,
    text: "Modifier une banque",
    icon: <AddCircleIcon />,
    hide: true,
  },
  listBank: {
    path: "/admin/banques",
    component: ListBankAdminPage,
    text: "Liste des banques",
    icon: <AccountBalanceIcon />,
  },
  showBank: {
    path: "/admin/banques/page",
    component: ShowBankAdminPage,
    text: "Affichage des banques",
    icon: <VisibilityIcon />,
  },
  listSnapdoc: {
    path: "/admin/dossiers",
    component: ListSnapdocAdminPage,
    text: "Liste des dossiers",
    icon: <FolderIcon />,
  },
  editSnapdoc: {
    path: "/admin/dossiers/edit/:userId",
    component: EditSnapdocAdminPage,
    text: "Modifier un dossier",
    icon: <AddCircleIcon />,
    hide: true,
  },
};
