import Chip from "@material-ui/core/Chip";
import React from "react";
import { makeStyles } from "@material-ui/core";

import { SNAPDOC_STATUS } from "../utils/enum";

const useStyles = makeStyles((theme) => ({
  chip: {
    color: "white",
  },
}));

const ProjectStatusChip = ({ status }) => {
  const classes = useStyles();

  return (
    status && (
      <Chip
        className={classes.chip}
        label={SNAPDOC_STATUS[status].label}
        style={{ backgroundColor: SNAPDOC_STATUS[status].color }}
      />
    )
  );
};

export default ProjectStatusChip;
