import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { formatNumberDisplay } from "../utils/common";
import { getMonthlyPayment, getTotalCredit } from "../utils/offers";
import InformationLine from "./InformationLine";

const useCardOfferStyle = makeStyles(() => ({
  avatar: {
    zIndex: 0,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  cardActions: {
    justifyContent: "center",
    paddingBottom: 0,
  },
  formControlLabel: {
    background: "#DBF5FF",
    paddingRight: "5rem",
    borderRadius: 10,
    display: "flex",
    margin: "1rem",
    marginTop: "1.5rem",
    border: " 1px solid #DBF5FF",
    width: "100%",
  },
  formControlLabelUnchecked: {
    background: "#fff",
    paddingRight: "5rem",
    borderRadius: 10,
    display: "flex",
    margin: "1rem",
    marginTop: "1.5rem",
    border: " 1px solid #CBD5E1",
    width: "100%",
  },
  cardContent: {
    padding: 0,
  },
  checkboxLabel: {
    color: "#034370",
  },
}));

const CardOffer = ({ avatar, offer, offerIsSelected, onChange, name }) => {
  const classes = useCardOfferStyle();
  return (
    <Grid item xs={12} md={6}>
      <Card>
        <div className={classes.cardHeader}>
          <img
            aria-label="bank-logo"
            className={classes.avatar}
            src={avatar}
            alt="bank-logo"
          />
        </div>

        <CardContent className={classes.cardContent}>
          <Box marginX="2rem">
            <InformationLine name="Taux" value={`${offer.rate} %`} />
            <InformationLine
              name="Frais de dossier"
              value={formatNumberDisplay(offer.applicationFees)}
            />
            <InformationLine
              name="Frais de conseil"
              value={formatNumberDisplay(offer.consultingFees)}
            />
            <Divider />
            <InformationLine
              fontSizeName="14px"
              fontSizeValue="14px"
              name="Coût total"
              subname="(hors assurance)"
              marginTop="1.5rem"
              value={formatNumberDisplay(getTotalCredit(offer))}
            />
            <InformationLine
              fontSizeName="14px"
              fontSizeValue="14px"
              name="Mensualité"
              subname="(hors assurance)"
              value={formatNumberDisplay(getMonthlyPayment(offer))}
              marginBottom="0.5rem"
            />
            <Divider />
          </Box>
          <CardActions className={classes.cardActions}>
            <FormControlLabel
              className={
                offerIsSelected === true
                  ? classes.formControlLabel
                  : classes.formControlLabelUnchecked
              }
              classes={{ label: classes.checkboxLabel }}
              control={
                <Checkbox
                  checked={offerIsSelected}
                  onChange={onChange}
                  name={name}
                />
              }
              label="Cette offre m’intérresse"
            />
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default CardOffer;
