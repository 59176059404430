import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

function Infobar({
  icon: Icon,
  message,
  color = "#11699B",
  backgroundColor = "#EEFAFF",
  textProps,
  ...props
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: backgroundColor,
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      minHeight: "60px",
      borderRadius: 4,
      borderLeft: `${color} 5px solid`,
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "100%",
      ...props,
    },
    icon: {
      fontSize: "30px",
      color: color,
    },
    text: {
      color: "#002345",
      padding: theme.spacing(1, 2),
      ...textProps,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Icon && <Icon color="action" className={classes.icon} />}
      <Typography component="p" className={classes.text}>
        {message}
      </Typography>
    </div>
  );
}

export default Infobar;
