import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import GetAppIcon from "@material-ui/icons/GetApp";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import fileDownload from "js-file-download";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import { superAdmin } from "../routes";
import {
  formatError,
  formatNumberWithComma,
  formatObjectWithoutNull,
} from "../utils/common";
import AdminService from "../services/AdminService";
import { folderAdminSchema } from "../utils/schema";
import { getLoanAmount } from "../utils/offers";
import ActionsFooter from "../components/ActionsFooter";
import { Box } from "@material-ui/core";
import TitleBar from "../components/TitleBar";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "3rem",
  },
  caption: {
    marginLeft: "1rem",
    marginBottom: "1rem",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    marginRight: "1rem",
    width: "fit-content",
    zIndex: 5,
  },
  buttonDirection: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    color: theme.palette.primary.main,
  },
  group: {
    flexDirection: "row",
  },
  formLabel: {
    fontSize: "12px",
  },
  helperError: {
    fontSize: "0.75rem",
    color: "#f44336",
    marginLeft: theme.spacing(2),
  },
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },
  },
  box: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: "8rem",
    },
    margin: "1rem",
    marginTop: "5rem",
    marginBottom: "11rem",
  },
}));

const constructFormData = (values) => {
  const folderData = new FormData();
  if (values.address) {
    folderData.set("address", values.address);
  }
  folderData.set("agencyFees", values.agencyFees || 0);
  folderData.set("amountTaxIncome", values.amountTaxIncome);
  if (values.city) {
    folderData.set("city", values.city);
  }
  folderData.set("dateLimitClauses", moment(values.dateLimitClauses).format());
  folderData.set(
    "dateSignAgreement",
    moment(values.dateSignAgreement).format()
  );
  folderData.set("dateSignNotary", moment(values.dateSignNotary).format());
  folderData.set("email", values.email);
  if (values.emailNotary) {
    folderData.set("emailNotary", values.emailNotary);
  }
  folderData.set("estateAmount", values.estateAmount);
  folderData.set("firstname", values.firstname);
  folderData.set("fiscalReference", values.fiscalReference);
  folderData.set("fiscalShare", values.fiscalShare);
  folderData.set("lastname", values.lastname);
  folderData.set("loanAmount", getLoanAmount(values));
  folderData.set("loanDuration", values.loanDuration);
  folderData.set("loanType", values.loanType);
  folderData.set("notaryFees", values.notaryFees);
  folderData.set("phone", values.phone);
  folderData.set("contribution", values.contribution);
  folderData.set("worksAmount", values.worksAmount || 0);
  if (values.zipCode) {
    folderData.set("zipCode", values.zipCode);
  }

  folderData.set("societeGeneraleRate", values.offerSocieteGenerale.rate);
  folderData.set(
    "societeGeneraleApplicationFees",
    values.offerSocieteGenerale.applicationFees
  );
  folderData.set(
    "societeGeneraleConsultingFees",
    values.offerSocieteGenerale.consultingFees
  );
  folderData.set("officerRate", values.offerOfficer.rate);
  folderData.set("officerApplicationFees", values.offerOfficer.applicationFees);
  folderData.set("officerConsultingFees", values.offerOfficer.consultingFees);
  if (values.comments) {
    folderData.set("isCommented", true);
  }
  return folderData;
};

function SnapdocAdminForm({
  enqueueSnackbar,
  user: { createdAt, id, updateAt, agency, project, ...user } = {},
}) {
  const history = useHistory();
  const classes = useStyles();

  const handleEditSubmit = (values, setSubmitting) => {
    const folderData = constructFormData(values);
    AdminService.patchSnapdoc(id, folderData)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: superAdmin.listSnapdoc.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const downloadDocument = (documentName) => {
    AdminService.downloadDocument(id, documentName)
      .then((response) => {
        fileDownload(response, documentName);
      })
      .catch((error) => {
        enqueueSnackbar("Impossible de télécharger le fichier.", {
          variant: "error",
        });
      });
  };

  const downloadAllDocuments = async (agreement, taxNotice) => {
    try {
      downloadDocument(agreement);
      downloadDocument(taxNotice);
    } catch (error) {
      enqueueSnackbar("Impossible de télécharger le fichier.", {
        variant: "error",
      });
    }
  };

  const offerOfficer =
    project.offers && project.offers.find((offer) => offer.bank === "Officer");
  const offerSocieteGenerale =
    project.offers &&
    project.offers.find((offer) => offer.bank === "Société Générale");

  return (
    <Formik
      initialValues={{
        ...user,
        ...formatObjectWithoutNull(project),
        secondBuyerEmail: project.secondBuyer?.email,
        secondBuyerFirstname: project.secondBuyer?.firstname,
        secondBuyerLastname: project.secondBuyer?.lastname,
        secondBuyerPhone: project.secondBuyer?.phone,
        offerOfficer,
        offerSocieteGenerale,
      }}
      validationSchema={folderAdminSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleEditSubmit(values, setSubmitting);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box margin="2rem" className={classes.box}>
            <TitleBar title={superAdmin.editSnapdoc.text} />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={`Informations acquéreur ${
                      values.loanType === "deux" ? "1" : ""
                    }`}
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstname}
                          fullWidth
                          id="firstname"
                          label="Prénom"
                          name="firstname"
                          required
                          helperText={
                            errors.firstname &&
                            touched.firstname &&
                            errors.firstname
                          }
                          error={errors.firstname && touched.firstname}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastname}
                          fullWidth
                          id="lastname"
                          label="Nom"
                          name="lastname"
                          required
                          helperText={
                            errors.lastname &&
                            touched.lastname &&
                            errors.lastname
                          }
                          error={errors.lastname && touched.lastname}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          required
                          helperText={
                            errors.email && touched.email && errors.email
                          }
                          error={errors.email && touched.email}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          fullWidth
                          id="phone"
                          label="Téléphone"
                          name="phone"
                          required
                          helperText={
                            errors.phone && touched.phone && errors.phone
                          }
                          error={errors.phone && touched.phone}
                        />
                      </Grid>
                      {values.comments && (
                        <Grid item xs={12}>
                          <TextField
                            multiline
                            rows="4"
                            rowsMax="4"
                            variant="outlined"
                            margin="dense"
                            value={values.comments}
                            fullWidth
                            id="comments"
                            label="Commentaires"
                            name="comments"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {values.loanType === "deux" && (
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title="Informations acquéreur 2"
                      titleTypographyProps={{ variant: "h6" }}
                      className={classes.cardHeader}
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            value={values.secondBuyerFirstname}
                            fullWidth
                            id="secondBuyerFirstname"
                            label="Prénom"
                            name="secondBuyerFirstname"
                            disabled
                            classes={{
                              root: classes.root,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            value={values.secondBuyerLastname}
                            fullWidth
                            id="secondBuyerLastname"
                            label="Nom"
                            name="secondBuyerLastname"
                            disabled
                            classes={{
                              root: classes.root,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            value={values.secondBuyerEmail}
                            fullWidth
                            id="secondBuyerEmail"
                            label="Email"
                            name="secondBuyerEmail"
                            disabled
                            classes={{
                              root: classes.root,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            value={values.secondBuyerPhone}
                            fullWidth
                            id="secondBuyerPhone"
                            label="Téléphone"
                            name="secondBuyerPhone"
                            disabled
                            classes={{
                              root: classes.root,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Plan de financement"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.estateAmount}
                          fullWidth
                          id="estateAmount"
                          label="Acquisition"
                          name="estateAmount"
                          placeholder="250000"
                          required
                          helperText={
                            errors.estateAmount &&
                            touched.estateAmount &&
                            errors.estateAmount
                          }
                          error={errors.estateAmount && touched.estateAmount}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.agencyFees}
                          fullWidth
                          id="agencyFees"
                          label="Frais d'agence"
                          name="agencyFees"
                          placeholder="90000"
                          helperText={
                            errors.agencyFees &&
                            touched.agencyFees &&
                            errors.agencyFees
                          }
                          error={errors.agencyFees && touched.agencyFees}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.worksAmount}
                          fullWidth
                          id="worksAmount"
                          label="Travaux"
                          name="worksAmount"
                          placeholder="30000"
                          helperText={
                            errors.worksAmount &&
                            touched.worksAmount &&
                            errors.worksAmount
                          }
                          error={errors.worksAmount && touched.worksAmount}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.notaryFees}
                          fullWidth
                          id="notaryFees"
                          label="Frais de notaire estimés"
                          name="notaryFees"
                          placeholder="14500"
                          required
                          helperText={
                            errors.notaryFees &&
                            touched.notaryFees &&
                            errors.notaryFees
                          }
                          error={errors.notaryFees && touched.notaryFees}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contribution}
                          fullWidth
                          id="contribution"
                          label="Apport"
                          name="contribution"
                          placeholder="10000"
                          required
                          helperText={
                            errors.contribution &&
                            touched.contribution &&
                            errors.contribution
                          }
                          error={errors.contribution && touched.contribution}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.loanAmount}
                          fullWidth
                          id="loanAmount"
                          label="Montant du prêt"
                          name="loanAmount"
                          placeholder="250000"
                          helperText={
                            errors.loanAmount &&
                            touched.loanAmount &&
                            errors.loanAmount
                          }
                          error={errors.loanAmount && touched.loanAmount}
                          InputProps={{
                            disabled: true,
                            value: getLoanAmount(values),
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.loanDuration}
                          fullWidth
                          id="loanDuration"
                          label="Durée du prêt"
                          name="loanDuration"
                          placeholder="120"
                          required
                          helperText={
                            errors.loanDuration &&
                            touched.loanDuration &&
                            errors.loanDuration
                          }
                          error={errors.loanDuration && touched.loanDuration}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                mois
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Projet"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          fullWidth
                          id="address"
                          label="Adresse du bien"
                          name="address"
                          helperText={
                            errors.address && touched.address && errors.address
                          }
                          error={errors.address && touched.address}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zipCode}
                          fullWidth
                          id="zipCode"
                          label="Code postal"
                          name="zipCode"
                          helperText={
                            errors.zipCode && touched.zipCode && errors.zipCode
                          }
                          error={errors.zipCode && touched.zipCode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          fullWidth
                          id="city"
                          label="Ville"
                          name="city"
                          helperText={
                            errors.city && touched.city && errors.city
                          }
                          error={errors.city && touched.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DatePicker
                          autoOk
                          id="dateSignAgreement"
                          name="dateSignAgreement"
                          cancelLabel=""
                          okLabel=""
                          margin="dense"
                          format="DD MMM YYYY"
                          inputVariant="outlined"
                          fullWidth
                          label="Signature Projet"
                          disableToolbar
                          minDate={moment().subtract(7, "days")}
                          required
                          error={
                            errors.dateSignAgreement &&
                            touched.dateSignAgreement
                          }
                          helperText={
                            errors.dateSignAgreement &&
                            touched.dateSignAgreement &&
                            errors.dateSignAgreement
                          }
                          value={values.dateSignAgreement}
                          onChange={(value) => {
                            setFieldValue("dateSignAgreement", value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="Calendar">
                                  <DateRangeIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DatePicker
                          autoOk
                          id="dateLimitClauses"
                          name="dateLimitClauses"
                          cancelLabel=""
                          okLabel=""
                          margin="dense"
                          format="DD MMM YYYY"
                          inputVariant="outlined"
                          fullWidth
                          label="Date limite clauses suspensives"
                          disableToolbar
                          disablePast
                          required
                          error={
                            errors.dateLimitClauses && touched.dateLimitClauses
                          }
                          helperText={
                            errors.dateLimitClauses &&
                            touched.dateLimitClauses &&
                            errors.dateLimitClauses
                          }
                          value={values.dateLimitClauses}
                          onChange={(value) => {
                            setFieldValue("dateLimitClauses", value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="Calendar">
                                  <DateRangeIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DatePicker
                          autoOk
                          id="dateSignNotary"
                          name="dateSignNotary"
                          cancelLabel=""
                          okLabel=""
                          margin="dense"
                          format="DD MMM YYYY"
                          inputVariant="outlined"
                          fullWidth
                          label="Signature définitive"
                          disableToolbar
                          disablePast
                          required
                          error={
                            errors.dateSignNotary && touched.dateSignNotary
                          }
                          helperText={
                            errors.dateSignNotary &&
                            touched.dateSignNotary &&
                            errors.dateSignNotary
                          }
                          value={values.dateSignNotary}
                          onChange={(value) => {
                            setFieldValue("dateSignNotary", value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="Calendar">
                                  <DateRangeIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emailNotary || ""}
                          fullWidth
                          id="emailNotary"
                          label="Email notaire"
                          name="emailNotary"
                          helperText={
                            errors.emailNotary &&
                            touched.emailNotary &&
                            errors.emailNotary
                          }
                          error={errors.emailNotary && touched.emailNotary}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl
                          component="fieldset"
                          error={errors.loanType && touched.loanType}
                        >
                          <FormLabel
                            component="legend"
                            className={classes.formLabel}
                            required
                          >
                            Type d'emprunt
                          </FormLabel>
                          <RadioGroup
                            className={classes.group}
                            required
                            onChange={handleChange}
                            value={values.loanType}
                            aria-label="loanType"
                            id="loanType"
                            name="loanType"
                          >
                            <FormControlLabel
                              value="seul"
                              control={<Radio color="primary" />}
                              label="Seul"
                            />
                            <FormControlLabel
                              value="deux"
                              control={<Radio color="primary" />}
                              label="À deux"
                            />
                          </RadioGroup>
                          {errors.loanType && touched.loanType && (
                            <span className={classes.helperError}>
                              {errors.loanType}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title="Offre personnalisée Société Générale"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={formatNumberWithComma(
                            values.offerCocieteGenerale?.rate
                          )}
                          fullWidth
                          id="offerSocieteGenerale.rate"
                          label="Taux personnalisé"
                          name="offerSocieteGenerale.rate"
                          placeholder="2.5"
                          required
                          helperText={
                            errors.offerSocieteGenerale?.rate &&
                            touched.offerSocieteGenerale?.rate &&
                            errors.offerSocieteGenerale?.rate
                          }
                          error={
                            errors.offerSocieteGenerale?.rate &&
                            touched.offerSocieteGenerale?.rate
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.offerSocieteGenerale?.applicationFees}
                          fullWidth
                          id="offerSocieteGenerale.applicationFees"
                          label="Frais de dossier"
                          name="offerSocieteGenerale.applicationFees"
                          placeholder="100"
                          required
                          helperText={
                            errors.offerSocieteGenerale?.applicationFees &&
                            touched.offerSocieteGenerale?.applicationFees &&
                            errors.offerSocieteGenerale?.applicationFees
                          }
                          error={
                            errors.offerSocieteGenerale?.applicationFees &&
                            touched.offerSocieteGenerale?.applicationFees
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.offerSocieteGenerale?.consultingFees}
                          fullWidth
                          id="offerSocieteGenerale.consultingFees"
                          label="Frais de conseil"
                          name="offerSocieteGenerale.consultingFees"
                          placeholder="3000"
                          required
                          helperText={
                            errors.offerSocieteGenerale?.consultingFees &&
                            touched.offerSocieteGenerale?.consultingFees &&
                            errors.offerSocieteGenerale?.consultingFees
                          }
                          error={
                            errors.offerSocieteGenerale?.consultingFees &&
                            touched.offerSocieteGenerale?.consultingFees
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title="Offre personnalisée OFFICER"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={formatNumberWithComma(
                            values.offerOfficer?.rate
                          )}
                          fullWidth
                          id="offerOfficer.rate"
                          label="Taux personnalisé"
                          name="offerOfficer.rate"
                          placeholder="2.5"
                          required
                          helperText={
                            errors.offerOfficer?.rate &&
                            touched.offerOfficer?.rate &&
                            errors.offerOfficer?.rate
                          }
                          error={
                            errors.offerOfficer?.rate &&
                            touched.offerOfficer?.rate
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.offerOfficer?.applicationFees}
                          fullWidth
                          id="offerOfficer.applicationFees"
                          label="Frais de dossier"
                          name="offerOfficer.applicationFees"
                          placeholder="100"
                          required
                          helperText={
                            errors.offerOfficer?.applicationFees &&
                            touched.offerOfficer?.applicationFees &&
                            errors.offerOfficer?.applicationFees
                          }
                          error={
                            errors.offerOfficer?.applicationFees &&
                            touched.offerOfficer?.applicationFees
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.offerOfficer?.consultingFees}
                          fullWidth
                          id="offerOfficer.consultingFees"
                          label="Frais de conseil"
                          name="offerOfficer.consultingFees"
                          placeholder="3000"
                          required
                          helperText={
                            errors.offerOfficer?.consultingFees &&
                            touched.offerOfficer?.consultingFees &&
                            errors.offerOfficer?.consultingFees
                          }
                          error={
                            errors.offerOfficer?.consultingFees &&
                            touched.offerOfficer?.consultingFees
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title="Documents"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <div className={classes.demo}>
                      <List dense>
                        {values.agreement && (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <DescriptionIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Compromis"
                              secondary={values.agreement}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  downloadDocument(values.agreement)
                                }
                              >
                                <GetAppIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                        {values.taxNotice && (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <DescriptionIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Dernier avis d'imposition"
                              secondary={values.taxNotice}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  downloadDocument(values.taxNotice)
                                }
                              >
                                <GetAppIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </List>
                    </div>
                  </CardContent>
                  <Divider />
                  <CardActions className={classes.cardActions}>
                    <div className={classes.wrapper}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={() =>
                          downloadAllDocuments(
                            values.agreement,
                            values.taxNotice
                          )
                        }
                      >
                        Tout télécharger
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          className={classes.buttonProgress}
                          size={24}
                          thickness={5}
                        />
                      )}
                    </div>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title="Impôts"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fiscalReference}
                          fullWidth
                          id="fiscalReference"
                          label="Total des salaires et assimilés"
                          name="fiscalReference"
                          placeholder="25000"
                          required
                          helperText={
                            errors.fiscalReference &&
                            touched.fiscalReference &&
                            errors.fiscalReference
                          }
                          error={
                            errors.fiscalReference && touched.fiscalReference
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fiscalShare}
                          fullWidth
                          id="fiscalShare"
                          label="Nombre de parts"
                          name="fiscalShare"
                          placeholder="2"
                          required
                          helperText={
                            errors.fiscalShare &&
                            touched.fiscalShare &&
                            errors.fiscalShare
                          }
                          error={errors.fiscalShare && touched.fiscalShare}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.amountTaxIncome}
                          fullWidth
                          id="amountTaxIncome"
                          label="Montant annuel"
                          name="amountTaxIncome"
                          placeholder="3000"
                          required
                          helperText={
                            errors.amountTaxIncome &&
                            touched.amountTaxIncome &&
                            errors.amountTaxIncome
                          }
                          error={
                            errors.amountTaxIncome && touched.amountTaxIncome
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <ActionsFooter>
            <div className={`${classes.wrapper} ${classes.buttonDirection}`}>
              <Typography variant="caption" className={classes.caption}>
                En validant ces informations, le statut passera à "complété" et
                un email sera envoyé à l'emprunteur
              </Typography>
              <div className={classes.buttons}>
                <Button
                  color="default"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.button}
                  onClick={() =>
                    history.push({
                      pathname: superAdmin.listSnapdoc.path,
                    })
                  }
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  className={classes.button}
                >
                  Enregistrer
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                    thickness={5}
                  />
                )}
              </div>
            </div>
          </ActionsFooter>
        </form>
      )}
    </Formik>
  );
}

export default withSnackbar(SnapdocAdminForm);
