import request, { METHOD } from "../utils/request";
import { formatQuery } from "../utils/common";

export default class AdminService {
  // AGENCY
  static postAgency(agencyData) {
    return request({
      url: "/admin/agencies",
      method: METHOD.POST,
      data: agencyData,
    });
  }
  static patchAgency(agencyId, agencyData) {
    return request({
      url: `/admin/agencies/${agencyId}`,
      method: METHOD.PUT,
      data: agencyData,
    });
  }
  static getAgency(id) {
    return request({
      url: `/admin/agencies/${id}`,
      method: METHOD.GET,
    });
  }
  static getAllAgencies({ page, orderBy, order }) {
    const query = formatQuery({ page, orderBy, order });
    return request({
      url: `/admin/agencies/${query}`,
      method: METHOD.GET,
    });
  }
  static deleteAgency(agencyId) {
    return request({
      url: `/admin/agencies/${agencyId}`,
      method: METHOD.DELETE,
    });
  }

  // USER
  static postUser(userData) {
    return request({
      url: "/admin/users",
      method: METHOD.POST,
      data: userData,
    });
  }
  static patchUser(agentId, agentData) {
    return request({
      url: `/admin/users/${agentId}`,
      method: METHOD.PUT,
      data: agentData,
    });
  }
  static updateActivationUser(userId) {
    return request({
      url: `/admin/users/${userId}/updateActivation`,
      method: METHOD.PATCH,
    });
  }
  static getUser(id) {
    return request({
      url: `/admin/users/${id}`,
      method: METHOD.GET,
    });
  }
  static getAllUsers({ page, orderBy, order }) {
    const query = formatQuery({ page, orderBy, order });
    return request({
      url: `/admin/users/${query}`,
      method: METHOD.GET,
    });
  }
  static deleteUser(userId) {
    return request({
      url: `/admin/users/${userId}`,
      method: METHOD.DELETE,
    });
  }

  // BANK
  static postBank(bankData) {
    return request({
      url: "/admin/banks",
      method: METHOD.POST,
      data: bankData,
    });
  }
  static patchBank(bankId, bankData) {
    return request({
      url: `/admin/banks/${bankId}`,
      method: METHOD.PUT,
      data: bankData,
    });
  }
  static getBank(id) {
    return request({
      url: `/admin/banks/${id}`,
      method: METHOD.GET,
    });
  }
  static getAllBanks({ page, orderBy, order }) {
    const query = formatQuery({ page, orderBy, order });
    return request({
      url: `/admin/banks/${query}`,
      method: METHOD.GET,
    });
  }
  static deleteBank(bankId) {
    return request({
      url: `/admin/banks/${bankId}`,
      method: METHOD.DELETE,
    });
  }

  // SNAPDOC
  static getAllSnapdocs({ page, orderBy, order }) {
    const query = formatQuery({ page, orderBy, order });
    return request({
      url: `/admin/snapdocs/${query}`,
      method: METHOD.GET,
    });
  }
  static getSnapdoc(userId) {
    return request({
      url: `/admin/snapdocs/${userId}`,
      method: METHOD.GET,
    });
  }
  static patchSnapdoc(userId, snapdocData) {
    return request({
      url: `/admin/snapdocs/${userId}`,
      method: METHOD.PUT,
      data: snapdocData,
    });
  }
  static downloadDocument(userId, documentName) {
    return request({
      url: `admin/snapdocs/${userId}/downloadDocument/${documentName}`,
      method: METHOD.GET,
      responseType: "blob",
    });
  }
  static deleteSnapdoc(userId) {
    return request({
      url: `/admin/users/${userId}`,
      method: METHOD.DELETE,
    });
  }
}
