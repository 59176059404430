import {
  Box,
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import InformationIcon from "./InformationIcon";

const useStyle = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    justifyContent: "center",
  },
  dialogContent: {
    padding: "1rem",
  },
  cross: {
    color: "#94A3B8",
    position: "absolute",
    top: 16,
    right: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  logo: {
    margin: "auto",
    marginTop: "2rem",
    textAlign: "center",
    width: "90px",
  },
  title: {
    color: "#034370",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    fontSize: "1.5rem",
    marginBottom: "0.5rem",
  },
  description: {
    color: "#034370",
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1rem",
    marginBottom: "1rem",
    lineHeight: "1.2rem",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem",
    flexDirection: "row",
  },
  cancelButton: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    background: "#F1F5F9",
  },
  submitButton: {
    "&:hover": {
      background: "#FECACA",
    },
    fontSize: "1rem",
    borderRadius: "0.5rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    background: "#FEF2F2",
    color: "#B91C1C",
    marginLeft: "1rem",
  },
}));

const ModalNoOffers = ({ open, onClose, onClickSubmit }) => {
  const classes = useStyle();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      fullWidth
      maxWidth="sm"
    >
      <Button onClick={onClose} className={classes.cross}>
        <CloseOutlined />
      </Button>

      <InformationIcon variant="warning" className={classes.logo} />
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.title}>Êtes-vous sûr ?</Typography>
        <Typography className={classes.description}>
          En refusant les propositions reçues, vous ne serez pas recontacté par
          les banques partenaires de votre agent immobilier et votre compte
          snapdoc sera clôturé.
        </Typography>
        <Box className={classes.buttons}>
          <Button
            onClick={onClose}
            color="primary"
            className={classes.cancelButton}
          >
            Annuler
          </Button>
          <Button
            onClick={onClickSubmit}
            color="primary"
            className={classes.submitButton}
          >
            Quitter
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ModalNoOffers;
