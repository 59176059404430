import Paper from "@material-ui/core/Paper";
import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";

import Loader from "./Loader";

function EnhancedTableHead({
  classes,
  columns,
  order,
  orderBy,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column.id} align="left" size="medium">
            {column.sort ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={order === "DESC" ? "desc" : "asc"}
                onClick={createSortHandler(column.id, column.sort)}
              >
                <Typography variant="subtitle2">{column.label}</Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="subtitle2">{column.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const ROWS_PER_PAGE = 30;

function SnapdocTable({ columns, body, query, refresh, enqueueSnackbar }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [countData, setCountData] = useState(0);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    setLoading(true);
    try {
      const response = await query({
        page,
        orderBy,
        order,
      });
      setLoading(false);
      setData(response.data);
      setCountData(response.countData);
    } catch (error) {
      enqueueSnackbar("Impossible de charger les données.", {
        variant: "error",
      });
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, [page, order, orderBy, query, refresh, enqueueSnackbar]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "DESC";
    setOrder(isDesc ? "ASC" : "DESC");
    setOrderBy(property);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            padding="normal"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
            />
            {body(data)}
          </Table>
        </div>
        <TablePagination
          rowsPerPage={ROWS_PER_PAGE}
          rowsPerPageOptions={[ROWS_PER_PAGE]}
          component="div"
          count={countData}
          page={page}
          backIconButtonProps={{
            "aria-label": "page précedente",
          }}
          nextIconButtonProps={{
            "aria-label": "page suivante",
          }}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count}`
          }
        />
      </Paper>
    </div>
  );
}

export default withSnackbar(SnapdocTable);
