import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import AppShell from "./pages/AppShell";
import CookieService from "./services/CookieService";
import NotFound from "./pages/NotFound";
import { USER_ROLE } from "./utils/enum";
import {
  disconnected,
  privateRoutes,
  superAdmin,
  connected,
  userRoutes,
} from "./routes";
import { isTokenValid } from "./utils/jwtUtils";
import Loader from "./components/Loader";

class App extends Component {
  state = { loading: true };

  componentDidMount() {
    const { sessionStore } = this.props;
    sessionStore.init().finally(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <BrowserRouter>
        <AppShell>
          <Switch>
            {Object.keys(disconnected).map((key) => (
              <Route
                key={key}
                exact
                path={disconnected[key].path}
                component={disconnected[key].component}
              />
            ))}
            {Object.keys(connected).map((key) => (
              <ConnectedRoute
                exact
                key={key}
                path={connected[key].path}
                component={connected[key].component}
              />
            ))}

            {Object.keys(userRoutes).map((key) => (
              <UserRoute
                exact
                key={key}
                path={userRoutes[key].path}
                component={userRoutes[key].component}
              />
            ))}

            {Object.keys(privateRoutes).map((key) => (
              <PrivateRoute
                exact
                key={key}
                path={privateRoutes[key].path}
                component={privateRoutes[key].component}
              />
            ))}
            {Object.keys(superAdmin).map((key) => (
              <SuperAdmin
                exact
                key={key}
                path={superAdmin[key].path}
                component={superAdmin[key].component}
              />
            ))}
            <Redirect path="/" to="/connexion" />
            <Route component={NotFound} />
          </Switch>
        </AppShell>
      </BrowserRouter>
    );
  }
}

const ConnectedRoute = inject("sessionStore")(
  ({ component: Component, sessionStore, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isTokenValid(CookieService.getCookie("token")) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: disconnected.login.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
);

const UserRoute = inject("sessionStore")(
  ({ component: Component, sessionStore, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStore.user.role === USER_ROLE.USER ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: disconnected.login.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
);
const PrivateRoute = inject("sessionStore")(
  ({ component: Component, sessionStore, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStore.user.role === USER_ROLE.AGENT ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: disconnected.login.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
);

const SuperAdmin = inject("sessionStore")(
  ({ component: Component, sessionStore, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStore.user.role === USER_ROLE.ADMIN ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: disconnected.login.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
);

export default inject("sessionStore")(observer(App));
