import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import { string, object, ref } from "yup";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import TitleBar from "../components/TitleBar";
import UserService from "../services/UserService";
import { Formik } from "formik";
import { connected } from "../routes";
import { formatError } from "../utils/common";
import { Box } from "@material-ui/core";
import ActionsFooter from "../components/ActionsFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "2rem",
    marginLeft: "2rem",
    marginBottom: "8rem",
  },
  card: {
    marginTop: "2rem",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    color: "#034370",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  wrapper: {
    position: "relative",
  },
  button: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    marginRight: "1rem",
    width: "fit-content",
    zIndex: 5,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  margin: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    marginTop: "5rem",
  },
}));

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const changePasswordSchema = () =>
  object().shape({
    currentPassword: string().required("Ce champ est requis"),
    newPassword: string()
      .matches(
        /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/,
        "Le mot de passe doit contenir au moins 8 caractères dont : 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial (!@#$%^&*)"
      )
      .required("Ce champ est requis"),
    confirmPassword: string().oneOf(
      [ref("newPassword"), null],
      "Le mot de passe n'est pas identique"
    ),
  });

function ProfilPage({ sessionStore, enqueueSnackbar }) {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values, setSubmitting, resetForm) => {
    UserService.changePassword(values)
      .then((response) => {
        setSubmitting(false);
        resetForm(initialState);
        enqueueSnackbar(response, {
          variant: "success",
        });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Formik
        initialValues={initialState}
        validationSchema={changePasswordSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form>
            <Box className={classes.root}>
              <Grid item xs={12} className={classes.margin}>
                <TitleBar title={connected.profil.text} />
                <Card>
                  <CardHeader
                    title="Mes informations"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          margin="dense"
                          value={sessionStore.user.firstname}
                          fullWidth
                          id="firstname"
                          label="Prénom"
                          name="firstname"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          margin="dense"
                          value={sessionStore.user.lastname}
                          fullWidth
                          id="lastname"
                          label="Nom"
                          name="lastname"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          margin="dense"
                          value={sessionStore.user.email}
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          margin="dense"
                          value={sessionStore.user.phone}
                          fullWidth
                          id="phone"
                          label="Téléphone"
                          name="phone"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    title="Changement du mot de passe"
                    titleTypographyProps={{ variant: "h6" }}
                    className={classes.cardHeader}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isSubmitting}
                          fullWidth
                          margin="dense"
                          id="currentPassword"
                          label="Mot de passe actuel"
                          error={
                            errors.currentPassword && touched.currentPassword
                          }
                          helperText={
                            errors.currentPassword &&
                            touched.currentPassword &&
                            errors.currentPassword
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="********"
                          required
                          type={showPassword ? "text" : "password"}
                          value={values.currentPassword}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isSubmitting}
                          fullWidth
                          id="newPassword"
                          label="Nouveau mot de passe"
                          margin="dense"
                          error={errors.newPassword && touched.newPassword}
                          helperText={
                            errors.newPassword &&
                            touched.newPassword &&
                            errors.newPassword
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="********"
                          required
                          type={showPassword ? "text" : "password"}
                          value={values.newPassword}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isSubmitting}
                          fullWidth
                          id="confirmPassword"
                          label="Confirmation du mot de passe"
                          margin="dense"
                          error={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          helperText={
                            errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="********"
                          required
                          type={showPassword ? "text" : "password"}
                          value={values.confirmPassword}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                </Card>
              </Grid>
            </Box>
            <Box className={classes.cardActions}>
              <ActionsFooter>
                <div className={classes.wrapper}>
                  <Button
                    color="default"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.button}
                    onClick={() => history.goBack()}
                  >
                    Annuler
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Modifier
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      className={classes.buttonProgress}
                      size={24}
                      thickness={5}
                    />
                  )}
                </div>
              </ActionsFooter>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default inject("sessionStore")(withSnackbar(ProfilPage));
