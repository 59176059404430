import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import CustomizedSteppers from "../../components/CustomStepper";

import { ProjectInformations } from "../../components/ProjectInformations";
import InformationUserForm from "../../forms/InformationUserForm";
import SuccessPostCommentsPage from "./SuccessPostCommentsPage";

const useStyles = makeStyles((theme) => ({
  header: {
    position: "fixed",
    width: "100%",
    zIndex: 5,
    top: "55px",
    [theme.breakpoints.up("md")]: {
      top: 0,
    },
  },
  root: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      paddingTop: "7rem",
    },
    paddingTop: "11rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    color: "#034370",
    fontSize: "1.5rem",
    padding: `0 ${theme.spacing(3)}px`,
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  description: {
    fontSize: "1rem",
    color: "#034370",
    maxWidth: "95%",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
  },
  descriptionPadding: {
    padding: `0 ${theme.spacing(3)}px`,
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  boldText: {
    fontWeight: "bold",
  },
}));

function InformationUserPage() {
  const [hasCommented, setHasCommented] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return hasCommented ? (
    <SuccessPostCommentsPage />
  ) : (
    <>
      <Box className={classes.header}>
        <ProjectInformations activeStep={1} />
        <CustomizedSteppers stepperActiveStep={1} />
      </Box>
      <Box className={classes.root}>
        <Typography className={`${classes.title} ${classes.boldText}`}>
          Analyse de vos documents
        </Typography>
        <div className={classes.descriptionPadding}>
          <Typography className={classes.description}>
            Validez vos données pour découvrir vos offres
          </Typography>
        </div>
        <InformationUserForm setHasCommented={setHasCommented} />
      </Box>
    </>
  );
}

export default InformationUserPage;
