import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core";

import logoIcon from "../../assets/snapdoc-icon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  logo: {
    width: "150px",
  },
  margin: {
    margin: theme.spacing(3, 0),
  },
  text: {
    fontSize: "18px",
  },
  divider: {
    margin: theme.spacing(3, 8),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  marginTop: {
    marginTop: "6px",
  },
}));

function SuccessPostCommentsPage({ sessionStore }) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={10} sm={8} md={8} lg={6}>
        <img src={logoIcon} alt="logo" className={classes.logo} />
        <Typography
          variant="h1"
          component="h3"
          align="center"
          className={classes.margin}
          color="primary"
        >
          snapdoc a bien reçu votre demande
        </Typography>
        <Typography color="primary" className={classes.text}>
          Dès l’analyse de votre demande effectuée, vous recevrez un email (
          <b>{sessionStore.user.email}</b>) ainsi qu'un SMS (
          <b>{sessionStore.user.phone}</b>) pour découvrir vos offres
          personnalisées.
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
}

export default inject("sessionStore")(SuccessPostCommentsPage);
