import {
  Box,
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import InformationIcon from "./InformationIcon";

const useStyle = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    justifyContent: "center",
  },
  dialogContent: {
    padding: "1rem",
  },
  cross: {
    color: "#94A3B8",
    position: "absolute",
    top: 16,
    right: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  logo: {
    margin: "auto",
    marginTop: "2rem",
    textAlign: "center",
    width: "90px",
  },
  title: {
    color: "#034370",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    fontSize: "1.5rem",
    marginBottom: "0.5rem",
  },
  description: {
    color: "#034370",
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1rem",
    marginBottom: "1rem",
    lineHeight: "1.2rem",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem",
    flexDirection: "row",
  },
  cancelButton: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    background: "#F1F5F9",
  },
  submitButton: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginLeft: "1rem",
  },
}));

const ModalInformationError = ({
  open,
  handleClose,
  comments,
  onClickButtonComment,
  onChange,
}) => {
  const classes = useStyle();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      fullWidth
      maxWidth="sm"
    >
      <Button onClick={handleClose} className={classes.cross}>
        <CloseOutlined />
      </Button>

      <InformationIcon variant="info" className={classes.logo} />
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.title}>Ajouter une précision</Typography>
        <Typography className={classes.description}>
          Si des erreurs sont présentes sur vos informations, dites-nous sur
          lequelle(s), nous nous chargons de les corriger.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          id="comments"
          label="Commentaires"
          type="comments"
          fullWidth
          placeholder="Vous pouvez laisser ce champs vide, nous vous recontacterons au plus
          vite pour gérer avec vous directement."
          multiline
          maxRows="10"
          minRows="4"
          value={comments}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box className={classes.buttons}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.cancelButton}
          >
            Annuler
          </Button>
          <Button
            onClick={onClickButtonComment}
            color="primary"
            variant="contained"
            className={classes.submitButton}
          >
            Valider
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ModalInformationError;
