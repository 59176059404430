import React from "react";

import { inject, observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";

import ChallengeBankUserPage from "./ChallengeBankUserPage";
import InformationUserPage from "./InformationUserPage";
import SuccessDocumentPage from "./SuccessDocumentPage";
import UserDocumentForm from "../../forms/UserDocumentForm";
import { SNAPDOC_STATUS } from "../../utils/enum";
import FinishWithoutOffersPage from "./FinishWithoutOffersPage";
import FinishWithOffersPage from "./FinishWithOffersPage";

const useStyles = makeStyles((theme) => ({
  header: { padding: 0 },
}));

function IndexUserPage({ sessionStore }) {
  const classes = useStyles();
  const { project } = sessionStore.user;

  return (
    <>
      {(!project.dateSendDocuments || !project.taxNotice) &&
        project.status === SNAPDOC_STATUS.INIT.text && (
          <UserDocumentForm user={sessionStore.user} />
        )}

      {project.status === SNAPDOC_STATUS.WAITING_REVIEW.text &&
        project.dateSendDocuments &&
        project.taxNotice && <SuccessDocumentPage />}

      {project.status === SNAPDOC_STATUS.COMPLETED.text && (
        <InformationUserPage />
      )}

      {project.status === SNAPDOC_STATUS.VALIDATED_INFO.text && (
        <ChallengeBankUserPage />
      )}
      {project.status === SNAPDOC_STATUS.CHALLENGE_SEND.text &&
        sessionStore.hasSelectedOffers === "true" && <FinishWithOffersPage />}

      {project.status === SNAPDOC_STATUS.CHALLENGE_SEND.text &&
        sessionStore.hasSelectedOffers === "false" && (
          <FinishWithoutOffersPage />
        )}
    </>
  );
}

export default inject("sessionStore")(observer(IndexUserPage));
