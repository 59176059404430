import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import AdminService from "../../services/AdminService";
import ProjectStatusChip from "../../components/ProjectStatusChip";
import SnapdocTable from "../../components/SnapdocTable";
import TableAction from "../../components/TableActions";
import ConfirmationModal, {
  stopPropagationForConfirmation,
} from "../../components/ConfirmationModal";
import TitleBar from "../../components/TitleBar";
import { superAdmin } from "../../routes";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: "#7d819d",
  },
  dialogText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  margin: {
    margin: theme.spacing(2),
  },
  box: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    marginTop: "4rem",
  },
}));

function ListSnapdocAdminPage({ enqueueSnackbar }) {
  const history = useHistory();
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const handleClickOpen = (data) => {
    setOpen(true);
    setData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeletion = (userId) => {
    AdminService.deleteSnapdoc(userId)
      .then((response) => {
        enqueueSnackbar(response, {
          variant: "success",
        });
        setRefresh(!refresh);
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };
  const columns = [
    {
      id: "project.status",
      label: "Statut",
      sort: false,
    },
    {
      id: "user.lastname",
      label: "Acquéreur",
      sort: true,
    },
    {
      id: "user.activationDate",
      label: "Activation",
      sort: true,
    },
    {
      id: "project.dateSendOfficer",
      label: "Mise en relation",
      sort: true,
    },
    {
      id: "project.datePaiement",
      label: "Date réglement",
      sort: true,
    },
    {
      id: "agency.name",
      label: "Agence",
      sort: true,
    },
    {
      id: "project.createdAt",
      label: "Création",
      sort: true,
    },
    {
      id: "project.dateSendDocuments",
      label: "Date envoi documents",
      sort: true,
    },
    { id: "actions", label: "Actions" },
  ];

  function renderBody(data) {
    return (
      <TableBody>
        {data.map((row) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            style={{ cursor: "pointer" }}
          >
            <TableCell onClick={() => handleClickOpen(row)}>
              <Badge
                className={classes.margin}
                badgeContent={row.comments ? 1 : 0}
                color="secondary"
              >
                <ProjectStatusChip status={row.status} />
              </Badge>
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.user.firstname} {row.user.lastname}
              {row.secondBuyer && (
                <>
                  <br />
                  {row.secondBuyer.firstname} {row.secondBuyer.lastname}
                </>
              )}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.user.active || row.user.activationDate
                ? moment(row.user.activationDate).format("DD MMM YYYY, HH:mm")
                : "Compte non activé"}{" "}
              {!row.user.active && row.user.activationCode && (
                <>
                  <br /> Code activation : {row.user.activationCode}
                </>
              )}
              {!row.user.active && row.user.endActivationDate && (
                <>
                  <br /> Expire :{" "}
                  {moment(row.user.endActivationDate).format(
                    "DD MMM YYYY, HH:mm"
                  )}
                </>
              )}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.dateSendOfficer
                ? moment(row.dateSendOfficer).format("DD MMM YYYY, HH:mm")
                : "Aucune date"}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.datePaiement
                ? moment(row.datePaiement).format("DD MMM YYYY, HH:mm")
                : "Aucune date"}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.user.agency.name}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {moment(row.createdAt).format("DD MMM YYYY")}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.dateSendDocuments
                ? moment(row.dateSendDocuments).format("DD MMM YYYY, HH:mm")
                : "Aucune date"}
            </TableCell>
            <TableCell>
              <TableAction>
                <MenuItem
                  onClick={() =>
                    history.push(
                      superAdmin.editSnapdoc.path.replace(
                        ":userId",
                        row.user.id
                      )
                    )
                  }
                >
                  Modifier
                </MenuItem>
                <ConfirmationModal
                  situationText={`Êtes-vous sûr de vouloir supprimer le dossier : ${row.user.firstname} ${row.user.lastname} ?`}
                >
                  {(confirmBeforeAct) => (
                    <MenuItem
                      onClick={(e) =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleDeletion(row.user.id))
                        )
                      }
                    >
                      Supprimer
                    </MenuItem>
                  )}
                </ConfirmationModal>
              </TableAction>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }
  const handleUpdate = () => {
    history.push(superAdmin.editSnapdoc.path.replace(":userId", data.user.id));
  };
  return (
    <>
      <Box margin="1rem" className={classes.box}>
        <TitleBar title={superAdmin.listSnapdoc.text} />
        <SnapdocTable
          refresh={refresh}
          columns={columns}
          body={renderBody}
          query={AdminService.getAllSnapdocs}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        TransitionProps={{ onExit: () => setData({}) }}
      >
        <DialogTitle id="alert-dialog-title">
          Dossier : {data.user && data.user.firstname}{" "}
          {data.user && data.user.lastname}{" "}
          {data.secondBuyer && (
            <>
              - {data.secondBuyer.firstname} {data.secondBuyer.lastname}
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Adresse mail
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.user && data.user.email}
                {data.secondBuyer && (
                  <>
                    <br />
                    {data.secondBuyer.email}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Téléphone
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.user && data.user.phone}
                {data.secondBuyer && (
                  <>
                    <br />
                    {data.secondBuyer.phone}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Email notaire
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.emailNotary}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Signature Projet
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {moment(data.dateSignAgreement).format("DD MMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Date limite clauses suspensives
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {moment(data.dateLimitClauses).format("DD MMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Signature définitive
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {moment(data.dateSignNotary).format("DD MMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Acquisition
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.estateAmount} €
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Montant du prêt
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.loanAmount} €
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Durée
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.loanDuration} mois
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Apport
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.contribution} €
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Frais d'agence
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.agencyFees} €
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Frais de notaire estimés
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.notaryFees} €
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Adresse du bien
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.address} <br />
                {data.zipCode} {data.city}
              </Typography>
            </Grid>
            {data.comments && (
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.dialogTitle}>
                  Commentaires
                </Typography>
                <Typography variant="body1" className={classes.dialogText}>
                  {data.comments}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" autoFocus>
            Ok
          </Button>
          <Button color="primary" variant="contained" onClick={handleUpdate}>
            Modifier
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withSnackbar(ListSnapdocAdminPage);
