import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import { connected } from "../routes";
import { PowerSettingsNewOutlined } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#39B7DF",
    width: "100%",
  },
  item: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
}));

const AccountMenu = ({ sessionStore, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickProfil() {
    history.push({ pathname: connected.profil.path });
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.button}
      >
        {children}
        <PowerSettingsNewOutlined style={{ marginRight: "2rem" }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MenuItem onClick={handleClickProfil}>Mon profil</MenuItem>
        <MenuItem onClick={() => sessionStore.signOut()}>Déconnexion</MenuItem>
      </Menu>
    </>
  );
};

export default inject("sessionStore")(AccountMenu);
