import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";

export default function ConfirmationModal({
  children,
  confirmText,
  cancelText,
  situationText,
  title,
}) {
  const [open, setOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  function handleClose() {
    setOpen(false);
    setCallback(null);
  }

  function handleShow(callback) {
    return () => {
      setOpen(true);
      setCallback(() => callback);
    };
  }

  function confirm() {
    callback();
    handleClose();
  }

  return (
    <>
      {children(handleShow)}
      <Dialog
        modal="false"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title || "Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {situationText ||
              "Êtes-vous sûr de vouloir effectuer cette action ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="contained">
            {cancelText || "Annuler"}
          </Button>
          <Button
            onClick={confirm}
            color="primary"
            autoFocus
            variant="contained"
          >
            {confirmText || "Oui"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const stopPropagationForConfirmation = (e, action) => {
  e.stopPropagation();
  action();
};
