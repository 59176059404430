import Button from "@material-ui/core/Button";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import logoSG from "../../assets/banque-sg.svg";
import logoOfficer from "../../assets/banque-officer.svg";
import UserService from "../../services/UserService";
import { SNAPDOC_STATUS } from "../../utils/enum";
import { formatError, formatNumberDisplay } from "../../utils/common";
import { withSnackbar } from "notistack";
import { Box, Typography } from "@material-ui/core";
import { ProjectInformations } from "../../components/ProjectInformations";
import CustomizedSteppers from "../../components/CustomStepper";

import CardOffer from "../../components/CardOffer";
import ModalNoOffers from "../../components/ModalNoOffers";

const useStyles = makeStyles((theme) => ({
  header: {
    position: "fixed",
    width: "100%",
    zIndex: 5,
    top: "50px",
    [theme.breakpoints.up("md")]: {
      top: 0,
    },
  },
  title: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "8rem",
    },
    paddingTop: "11.5rem",
    fontSize: "1.5rem",
    color: "#034370",
    lineHeight: "2rem",
  },
  description: {
    fontSize: "1rem",
    color: "#034370",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
  },
  boldText: {
    fontWeight: "bold",
  },
  root: {
    marginBottom: "5rem",
    padding: `0 ${theme.spacing(3)}px`,
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
    display: "flex",
    flexDirection: "column",
  },
  wrapper: {
    position: "fixed",
    display: "flex",
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    padding: "1.5rem",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
      padding: "1.5rem",
      paddingTop: 0,
      paddingBottom: 0,
      width: "calc(100% - 370px)",
    },
    justifyContent: "flex-end",
    background: "white",
    bottom: 0,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  cardHeader: {
    justifyContent: "center",
    fontWeight: "bold",
  },
  cardHeaderContent: {
    flex: "none",
  },
  cardHeaderTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
  },
  tableCell: {
    border: "none",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    fontSize: "1rem",
    borderRadius: "0.5rem",
    position: "relative",
    bottom: "0",
    [theme.breakpoints.up("md")]: {
      width: "fit-content",
    },
  },
  submitNoOffers: {
    margin: theme.spacing(2, 0, 2),
    paddingLeft: "1.3rem",
    paddingRight: "1.3rem",
    fontSize: "1rem",
    borderRadius: "0.5rem",
    position: "relative",
    bottom: "0",
    background: "#FEF2F2",
    color: "#B91C1C",
    "&:hover": {
      background: "#FECACA",
    },
    [theme.breakpoints.up("md")]: {
      width: "fit-content",
      alignSelf: "center",
    },
  },
}));

function ChallengeBankUserPage({ sessionStore, enqueueSnackbar }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState({
    societeGenerale: true,
    officer: true,
  });

  const project = sessionStore.user.project;
  const offerOfficer = project.offers.find((offer) => offer.bank === "Officer");
  const offerSocieteGenerale = project.offers.find(
    (offer) => offer.bank === "Société Générale"
  );

  const handleChange = (e) => {
    setOffers({
      ...offers,
      [e.target.name]: e.target.checked,
    });
  };
  const [openNoOffersModal, setOpenNoOffersModal] = React.useState(false);
  const handleSubmitOffers = () => {
    sessionStore.updateHasSelectedOffers(true);
    setLoading(true);
    UserService.challengeBanks(offers)
      .then((response) => {
        setLoading(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        sessionStore.updateProject(
          "status",
          SNAPDOC_STATUS.CHALLENGE_SEND.text
        );
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };
  const handleSubmitNoOffers = () => {
    sessionStore.updateHasSelectedOffers(false);
    setLoading(true);
    UserService.challengeBanks(offers)
      .then((response) => {
        setLoading(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        sessionStore.updateProject(
          "status",
          SNAPDOC_STATUS.CHALLENGE_SEND.text
        );
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const handleOpenModal = () => {
    setOpenNoOffersModal(true);
  };
  const handleClose = () => {
    setOpenNoOffersModal(false);
  };

  const offersValuesLength =
    offers && (Object.values(offers) || []).filter((value) => value).length;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box className={classes.header}>
        <ProjectInformations activeStep={1} />
        <CustomizedSteppers stepperActiveStep={2} />
      </Box>
      <Box className={classes.root}>
        <Typography
          component="span"
          className={`${classes.title} ${classes.boldText}`}
        >
          Mes offres personnalisées
        </Typography>

        <Typography component="span" className={classes.description}>
          Pour un prêt de{" "}
          <span className={classes.boldText}>
            {formatNumberDisplay(project.loanAmount)} sur une durée de{" "}
            {project.loanDuration} mois
          </span>
          , voici vos offres personnalisées
        </Typography>
        <Grid container spacing={2}>
          <CardOffer
            avatar={logoOfficer}
            offerIsSelected={offers.officer}
            onChange={handleChange}
            name="officer"
            offer={offerOfficer}
          />
          <CardOffer
            avatar={logoSG}
            offerIsSelected={offers.societeGenerale}
            onChange={handleChange}
            name="societeGenerale"
            offer={offerSocieteGenerale}
          />
        </Grid>
      </Box>
      <Box className={classes.wrapper} boxShadow={3}>
        {offersValuesLength > 0 ? (
          <Button
            className={classes.submit}
            color="primary"
            type="submit"
            onClick={handleSubmitOffers}
            variant="contained"
            fullWidth
            disabled={loading}
          >
            Demander {offersValuesLength}{" "}
            {offersValuesLength > 1 ? "propositions" : "proposition"}
          </Button>
        ) : (
          <Button
            className={classes.submitNoOffers}
            type="submit"
            onClick={handleOpenModal}
            fullWidth
            disabled={loading}
          >
            Aucune proposition ne m'intéresse
          </Button>
        )}
        <ModalNoOffers
          open={openNoOffersModal}
          onClose={handleClose}
          onClickSubmit={handleSubmitNoOffers}
        />
        {loading && (
          <CircularProgress
            className={classes.buttonProgress}
            size={24}
            thickness={5}
          />
        )}
      </Box>
    </>
  );
}

export default inject("sessionStore")(withSnackbar(ChallengeBankUserPage));
