import Grid from "@material-ui/core/Grid";
import Infobar from "../../components/Infobar";
import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { inject } from "mobx-react";
import { Box, makeStyles } from "@material-ui/core";
import { withSnackbar } from "notistack";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { ProjectInformations } from "../../components/ProjectInformations";
import CustomizedSteppers from "../../components/CustomStepper";
import InformationIcon from "../../components/InformationIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "8rem",
    },
    paddingTop: "11.5rem",
  },
  header: {
    position: "fixed",
    width: "100%",
    zIndex: 5,
    top: "55px",
    [theme.breakpoints.up("md")]: {
      top: 0,
    },
  },
  container: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  logo: {
    width: "150px",
  },
  title: {
    color: "#034370",
    fontSize: "1.5rem",
  },
  description: {
    marginTop: "1rem",
    color: "#034370",
  },
  boldText: {
    fontWeight: "bold",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function SuccessDocumentPage({ sessionStore, enqueueSnackbar }) {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box className={classes.header}>
        <ProjectInformations activeStep={1} />
        <CustomizedSteppers stepperActiveStep={0} />
      </Box>
      <div className={classes.root}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={10} sm={8} md={8} lg={6}>
            <InformationIcon variant="analysis" className={classes.logo} />
            <Typography
              component="p"
              align="center"
              className={`${classes.title} ${classes.boldText}`}
            >
              Merci !
            </Typography>
            <Typography color="primary" className={classes.description}>
              snapdoc analyse vos documents justificatifs !
            </Typography>
            <Typography color="primary" className={classes.description}>
              Vous serez notifié par email et sms pour valider vos informations
              personnelles
            </Typography>
            <Infobar
              marginTop="2rem"
              textProps={{
                fontWeight: "bold",
                color: "#11699B",
                textAlign: "left",
              }}
              icon={InfoOutlinedIcon}
              message="Pensez à rafraichir la page lors de votre prochaine connexion"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default inject("sessionStore")(withSnackbar(SuccessDocumentPage));
