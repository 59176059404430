import React from "react";

import AgentAdminForm from "../../forms/AgentAdminForm";

function AddAgentAdminPage() {
  return (
    <>
      <AgentAdminForm />
    </>
  );
}

export default AddAgentAdminPage;
