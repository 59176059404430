import request, { METHOD } from "../utils/request";

export default class UserService {
  static signIn(email, password, remember) {
    return request({
      url: "/users/login",
      method: METHOD.POST,
      data: {
        email,
        password,
        remember,
      },
    });
  }
  static register(userData) {
    return request({
      url: "/users/register",
      method: METHOD.POST,
      data: userData,
    });
  }
  static me() {
    return request({
      url: "/users/me",
      method: METHOD.GET,
    });
  }
  static lostPassword(userData) {
    return request({
      url: "/users/lost-password",
      method: METHOD.POST,
      data: userData,
    });
  }
  static changePassword(userData) {
    return request({
      url: "/users/change-password",
      method: METHOD.POST,
      data: userData,
    });
  }
  static changePasswordByToken(userData) {
    return request({
      url: "/users/change-password-token",
      method: METHOD.POST,
      data: userData,
    });
  }
  static postDocument(documentData) {
    return request({
      url: "/users/snapdocs",
      method: METHOD.POST,
      data: documentData,
    });
  }
  static downloadDocument(documentName) {
    return request({
      url: `/users/snapdocs/downloadDocument/${documentName}`,
      method: METHOD.GET,
      responseType: "blob",
    });
  }
  static patchUserContact(userPhone) {
    return request({
      url: "/users/contact",
      method: METHOD.PATCH,
      data: userPhone,
    });
  }
  static postComments(comments) {
    return request({
      url: "/users/comments",
      method: METHOD.POST,
      data: { comments },
    });
  }
  static validSnapdoc() {
    return request({
      url: "/users/valid-snapdoc",
      method: METHOD.POST,
    });
  }
  static getAllBanks() {
    return request({
      url: "/users/banks",
      method: METHOD.GET,
    });
  }
  static challengeBanks(banks) {
    return request({
      url: "/users/challenge",
      method: METHOD.POST,
      data: { banks },
    });
  }
  static closeAccount() {
    return request({
      url: "/users/close-account",
      method: METHOD.POST,
    });
  }
}
