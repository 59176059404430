import AuthLayout from "./AuthLayout";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { string, object } from "yup";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import UserService from "../../services/UserService";
import { disconnected } from "../../routes";
import { formatError, redirectPage } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    fontSize: "1.125rem",
    borderRadius: "0.25rem",
  },
  backToLogin: {
    color: "#2093C6",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const lostSchema = () =>
  object().shape({
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
  });

const LostPage = ({ sessionStore, enqueueSnackbar }) => {
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    redirectPage(sessionStore, history);
  }, [history, sessionStore]);

  return (
    <AuthLayout title={disconnected.lostPass.text}>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={lostSchema}
        onSubmit={(values, { setSubmitting }) => {
          UserService.lostPassword(values)
            .then((response) => {
              setSubmitting(false);
              enqueueSnackbar(response, {
                variant: "success",
              });
              history.push({ pathname: disconnected.resetPass.path });
            })
            .catch((error) => {
              enqueueSnackbar(formatError(error), {
                variant: "error",
              });
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              autoComplete="email"
              error={errors.email && touched.email}
              disabled={isSubmitting}
              fullWidth
              id="email"
              label="Identifiant snapdoc"
              margin="normal"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Email@snapdoc.fr"
              required
              value={values.email}
              variant="outlined"
              helperText={errors.email && touched.email && errors.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Grid container>
              <Grid item xs>
                <Link
                  to={disconnected.login.path}
                  style={{ textDecoration: "none" }}
                  className={classes.backToLogin}
                >
                  {"Revenir à la connexion"}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={disconnected.resetPass.path}
                  style={{ textDecoration: "none" }}
                  className={classes.backToLogin}
                >
                  {"J'ai un code de réinitialisation"}
                </Link>
              </Grid>
            </Grid>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
                fullWidth
                type="submit"
                variant="contained"
              >
                Réinitialiser
              </Button>
              {isSubmitting && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                  thickness={5}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default inject("sessionStore")(withSnackbar(LostPage));
