import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import logoSnapdoc from "../assets/snapdoc-immo.svg";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import sessionStore from "../stores/sessionStore";

const styles = (theme) => ({
  appBar: {
    backgroundColor: "white",
    position: "fixed",
  },
  menuButton: {
    marginRight: -theme.spacing(1),
    padding: 0,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  toolbar: {
    minHeight: 0,
  },
  img: {
    maxWidth: 120,
    maxHeight: 50,
  },
});

function MenuHead({ classes, onDrawerToggle }) {
  const logoURL =
    sessionStore.user.agency && sessionStore.user.agency.logo
      ? sessionStore.user.agency.logo
      : logoSnapdoc;
  return (
    <>
      <AppBar className={classes.appBar} position="sticky" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <img src={logoURL} className={classes.img} />
            <Grid item xs />
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon style={{ color: "#94A3B8" }} />
              </IconButton>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default withStyles(styles)(MenuHead);
