import Hidden from "@material-ui/core/Hidden";
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import Menu from "../components/Menu";
import MenuHead from "../components/MenuHead";

const drawerWidth = 370;

const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    padding: 0,
  },
});

function Layout({ classes, children, routes }) {
  const [openMenu, handleMenu] = useState(false);

  const toggleMenu = () => {
    handleMenu(!openMenu);
  };

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Menu
            variant="temporary"
            open={openMenu}
            onClose={toggleMenu}
            routes={routes}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Menu routes={routes} />
        </Hidden>
      </nav>
      <div className={classes.appContent}>
        <MenuHead onDrawerToggle={toggleMenu} />
        <main className={classes.mainContent}>{children}</main>
      </div>
    </div>
  );
}

export default withStyles(styles)(Layout);
