import request, { METHOD } from "../utils/request";
import { formatQuery } from "../utils/common";

export default class AgentService {
  static postSnapdoc(userData) {
    return request({
      url: "/agents/users/projects",
      method: METHOD.POST,
      data: userData,
    });
  }
  static updateActivationUser(userId) {
    return request({
      url: `/agents/users/${userId}/updateActivation`,
      method: METHOD.PATCH,
    });
  }
  static getSnapdoc(userId) {
    return request({
      url: `/agents/users/${userId}/projects`,
      method: METHOD.GET,
    });
  }
  static getAllSnapdocs({ page, orderBy, order }) {
    const query = formatQuery({ page, orderBy, order });
    return request({
      url: `/agents/users/projects/${query}`,
      method: METHOD.GET,
    });
  }
  static deleteSnapdoc(userId) {
    return request({
      url: `/agents/users/${userId}/projects`,
      method: METHOD.DELETE,
    });
  }
}
