import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import Loader from "../../components/Loader";
import { formatError } from "../../utils/common";
import { withSnackbar } from "notistack";
import TitleBar from "../../components/TitleBar";
import { superAdmin } from "../../routes";
import AdminService from "../../services/AdminService";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 330,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    margin: theme.spacing(1, 1),
    backgroundSize: "contain",
  },
  wrapper: {
    position: "relative",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(3, 0),
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  box: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    marginTop: "4rem",
  },
}));

function ShowBankAdminPage({ enqueueSnackbar }) {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(true);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await AdminService.getAllBanks({})
        .then((response) => {
          const allBanks = response.data.map((bank) => ({
            ...bank,
            checked: true,
            isClient: false,
          }));
          setBanks(allBanks);
          setLoadingData(false);
        })
        .catch((error) => {
          setLoadingData(false);
          enqueueSnackbar(formatError(error), {
            variant: "error",
          });
        });
    }
    fetchData();
  }, [enqueueSnackbar]);

  if (loadingData) {
    return <Loader />;
  }

  return (
    <>
      <Box margin="1rem" className={classes.box}>
        <TitleBar title={superAdmin.showBank.text}></TitleBar>

        <Grid container spacing={4}>
          {banks.map((bank) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={bank.id}>
              <Card className={classes.card}>
                <CardMedia
                  component="div"
                  className={classes.media}
                  image={bank.logo}
                  title={bank.name}
                />
                <CardActions disableSpacing>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={bank.checked}
                          color="primary"
                          name="checked"
                          value={bank.checked}
                        />
                      }
                      label="Solliciter cette banque"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={bank.isClient}
                          color="primary"
                          name="isClient"
                          value={bank.isClient}
                        />
                      }
                      label="Je suis client de cette banque"
                    />
                  </FormGroup>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default withSnackbar(ShowBankAdminPage);
