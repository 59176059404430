import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import sessionStore from "../stores/sessionStore";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import moment from "moment";
import {
  Box,
  ClickAwayListener,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import { CheckCircle } from "@material-ui/icons";

const CheckCircleColor = () => {
  return (
    <CheckCircle
      style={{ color: "#22C55E", marginLeft: "0.9rem", fontSize: "1.2rem" }}
      viewBox="0 0 24 24"
    />
  );
};

const StepIconStyle = () => {
  return (
    <RadioButtonUncheckedIcon
      style={{ color: "#CBD5E1", marginLeft: "0.9rem" }}
    />
  );
};

const StepConnectorWithStyle = withStyles({
  alternativeLabel: {},
  active: {
    "& $line": {
      backgroundColor: "#22C55E",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#22C55E",
      paddingBottom: 0,
    },
  },
  line: {
    height: 32,
    border: 0,
    backgroundColor: "#FFF",
    width: 2,
    marginLeft: "0.7rem",
  },
  vertical: {
    padding: 0,
  },
})(StepConnector);

const useStepIconCustomStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    marginLeft: "1.01rem",
  },
  completed: {
    marginLeft: "1rem",
  },
});

const StepIconCustom = (props) => {
  const classes = useStepIconCustomStyles();
  const { active, completed } = props;
  const icon = [StepIconStyle];
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icon[String(props.icon)]}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f4f6f8",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 390px)",
    },
  },
  stepper: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    background: "white",
    background: "#11699B",
    paddingBottom: "1rem",
    paddingRight: "0.5rem",
    padding: "0.2rem",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
    },
  },
  informations: { alignItems: "center", fontSize: "0.875rem" },
  titleHeader: { fontSize: "0.625rem" },
  button: {
    backgroundColor: "#11699B",
    "&:hover": {
      backgroundColor: "#11699B",
    },
    color: "#DBF5FF",
    padding: 0,
    borderRadius: 0,
    justifyContent: "left",
    paddingRight: "0.813rem",
    paddingTop: "0.813rem",
    paddingBottom: "0.813rem",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  homeIcon: {
    fontSize: "1.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    padding: "0",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  boxSteps: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  stepText: {
    color: "#EEFAFF",
    fontWeight: "bold",
  },
  stepDates: {
    color: "#EEFAFF",
    fontSize: "0.8rem",
    fontWeight: "bold",
    marginLeft: "0.5rem",
    textTransform: "capitalize",
  },
  stepLabels: {
    fontSize: "0.8rem",
    textAlign: "right",
  },
  secondDate: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
}));

export const ProjectInformations = ({ className, activeStep }) => {
  const [transform, setTransform] = useState("0");
  const [clickAway, setClickAway] = useState(false);
  const handleClick = () => {
    setTransform((currentTransform) => {
      if (currentTransform === "0") {
        return "180";
      }
      return "0";
    });
    setClickAway((prev) => !prev);
  };

  const handleClose = () => {
    setTransform("0");
    setClickAway(false);
  };
  const agency =
    sessionStore.user && sessionStore.user.agency
      ? sessionStore.user.agency
      : {};

  const project = sessionStore.user.project || {};

  const steps = [
    {
      date: moment(project.dateSignAgreement).format("dddd Do MMMM YYYY"),
      label: "Signature compromis",
    },
    {
      date: moment(project.dateLimitClauses).format("dddd Do MMMM YYYY"),
      label: "Offres de prêts",
    },
    {
      date: moment(project.dateSignNotary).format("dddd Do MMMM YYYY"),
      label: "Remise de vos clés",
    },
  ];

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={handleClose}>
        <div className={classes.root}>
          <Button
            variant="contained"
            onClick={handleClick}
            fullWidth
            className={classes.button}
          >
            <Box className={classes.header}>
              <HomeWorkOutlinedIcon className={classes.homeIcon} />
              <Box display="flex" flexDirection="column" textAlign="left">
                <Typography component="span" className={classes.titleHeader}>
                  MON PROJET
                </Typography>
                <Box display="flex" flexDirection="row">
                  <Typography component="span" className={classes.informations}>
                    {project.address} - {project.zipCode} {project.city}{" "}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <KeyboardArrowDownIcon
              style={{ transform: `rotate(${transform}deg)` }}
            />
          </Button>
          {clickAway ? (
            <Stepper
              orientation="vertical"
              className={classes.stepper}
              connector={<StepConnectorWithStyle />}
              activeStep={activeStep}
            >
              {steps.map((step, index) => {
                return (
                  <Step completed={index < activeStep} key={step.label}>
                    <StepLabel
                      StepIconComponent={
                        index < activeStep ? CheckCircleColor : StepIconCustom
                      }
                    >
                      <Typography component="span" className={classes.stepText}>
                        <Box className={classes.boxSteps}>
                          <Typography
                            component="span"
                            className={`${classes.stepDates} ${
                              step.date === steps[1].date && classes.secondDate
                            }`}
                          >
                            {step.date}
                          </Typography>
                          <Typography
                            component="span"
                            className={`${classes.stepLabels} ${
                              step.label === "Offres de prêts" &&
                              classes.secondDate
                            }`}
                          >
                            {step.label}
                          </Typography>
                        </Box>
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
};
