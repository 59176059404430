import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import AdminService from "../services/AdminService";
import MenuItem from "@material-ui/core/MenuItem";
import { agentSchema } from "../utils/schema";
import { superAdmin } from "../routes";
import { formatError } from "../utils/common";
import ActionsFooter from "../components/ActionsFooter";
import { Box } from "@material-ui/core";
import TitleBar from "../components/TitleBar";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  button: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    marginRight: "1rem",
    width: "fit-content",
    zIndex: 5,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  dialogTitle: {
    color: "#7d819d",
  },
  dialogText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  margin: {
    margin: "1rem",
    marginBottom: "9rem",
    marginTop: "5rem",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));

const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  agencyId: "",
};

function AgentAdminForm({
  enqueueSnackbar,
  agent: {
    createdAt,
    id,
    updateAt,
    agency: { id: agencyId } = {},
    ...cleanAgent
  } = {},
}) {
  const history = useHistory();
  const classes = useStyles();
  const [agencies, setAgencies] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openConfirmModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await AdminService.getAllAgencies({});
      setAgencies(
        response.data.map((agency) => ({
          value: agency.id,
          label: agency.name,
        }))
      );
    }
    fetchData();
  }, []);

  const getAgencyLabel = (agencyId) => {
    const agency = agencies.find((agency) => agency.value === agencyId);
    if (agency) {
      return agency.label;
    }
  };

  const handlePostSubmit = (values, setSubmitting) => {
    AdminService.postUser({
      ...values,
      lastname: values.lastname.toUpperCase(),
    })
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: superAdmin.listAgent.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const handleEditSubmit = (values, setSubmitting) => {
    AdminService.patchUser(id, {
      ...values,
      lastname: values.lastname.toUpperCase(),
    })
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: superAdmin.listAgent.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  return (
    <Formik
      initialValues={id ? { ...cleanAgent, agencyId } : initialState}
      validationSchema={agentSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!confirm) {
          openConfirmModal();
          setSubmitting(false);
        } else {
          if (id) {
            handleEditSubmit(values, setSubmitting);
          } else {
            handlePostSubmit(values, setSubmitting);
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <>
          <form onSubmit={openConfirmModal}>
            <Box className={classes.margin}>
              <TitleBar title={superAdmin.addAgent.text}></TitleBar>

              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstname}
                        fullWidth
                        id="firstname"
                        label="Prénom"
                        name="firstname"
                        required
                        helperText={
                          errors.firstname &&
                          touched.firstname &&
                          errors.firstname
                        }
                        error={errors.firstname && touched.firstname}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastname.toUpperCase()}
                        fullWidth
                        id="lastname"
                        label="Nom"
                        name="lastname"
                        required
                        helperText={
                          errors.lastname && touched.lastname && errors.lastname
                        }
                        error={errors.lastname && touched.lastname}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        required
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                        error={errors.email && touched.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        fullWidth
                        id="phone"
                        label="Téléphone"
                        name="phone"
                        required
                        helperText={
                          errors.phone && touched.phone && errors.phone
                        }
                        error={errors.phone && touched.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="agencyId"
                        name="agencyId"
                        select
                        fullWidth
                        label="Agence"
                        required
                        value={values.agencyId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        helperText={
                          errors.agencyId && touched.agencyId && errors.agencyId
                        }
                        error={errors.agencyId && touched.agencyId}
                      >
                        {agencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions className={classes.cardActions}></CardActions>
              </Card>
            </Box>
            <ActionsFooter>
              <div className={classes.wrapper}>
                <Button
                  color="default"
                  className={classes.button}
                  disabled={isSubmitting}
                  variant="contained"
                  onClick={() =>
                    history.push({ pathname: superAdmin.listAgent.path })
                  }
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isSubmitting}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Enregistrer
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                    thickness={5}
                  />
                )}
              </div>
            </ActionsFooter>
          </form>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              Veuillez confirmer l'enregistrement
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Agent
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.firstname} {values.lastname.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Adresse mail
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Téléphone
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Agence
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {getAgencyLabel(values.agencyId)}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="default"
                disabled={isSubmitting}
                variant="contained"
                onClick={handleClose}
              >
                Modifier
              </Button>
              <div className={classes.buttons}>
                <div className={classes.wrapper}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      setConfirm(true);
                      handleSubmit();
                    }}
                  >
                    Enregistrer
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      className={classes.buttonProgress}
                      size={24}
                      thickness={5}
                    />
                  )}
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Formik>
  );
}

export default withSnackbar(AgentAdminForm);
