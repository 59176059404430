import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import AdminService from "../services/AdminService";
import { bankSchema } from "../utils/schema";
import { superAdmin } from "../routes";
import { formatError } from "../utils/common";
import ActionsFooter from "../components/ActionsFooter";
import TitleBar from "../components/TitleBar";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  button: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    marginRight: "1rem",
    width: "fit-content",
    zIndex: 5,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonDelete: {
    color: red[500],
    borderColor: red[500],
  },
  logo: {
    maxWidth: "100%",
    maxHeight: 150,
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  dialogTitle: {
    color: "#7d819d",
  },
  dialogText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  margin: {
    [theme.breakpoints.up("md")]: {
      marginTop: "1rem",
      marginBottom: 0,
    },
    margin: "1rem",
    marginTop: "2rem",
    marginBottom: "4rem",
  },
  box: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
    },
    margin: "3rem",
    marginTop: "5rem",
    marginBottom: "4rem",
  },
}));

const constructFormData = (values) => {
  const bankData = new FormData();
  bankData.set("logo", values.logo);
  bankData.set("address", values.address);
  bankData.set("city", values.city);
  bankData.set("email", values.email);
  bankData.set("firstname", values.firstname);
  bankData.set("lastname", values.lastname.toUpperCase());
  bankData.set("name", values.name);
  bankData.set("phone", values.phone);
  bankData.set("zipCode", values.zipCode);
  return bankData;
};

const initialState = {
  name: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  address: "",
  zipCode: "",
  city: "",
};

function BankAdminForm({
  enqueueSnackbar,
  bank: { createdAt, id, updateAt, ...cleanBank } = {},
}) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openConfirmModal = () => {
    setOpen(true);
  };

  const handlePostSubmit = (values, setSubmitting) => {
    const bankData = constructFormData(values);
    AdminService.postBank(bankData)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: superAdmin.listBank.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const handleEditSubmit = (values, setSubmitting) => {
    const bankData = constructFormData(values);
    AdminService.patchBank(id, bankData)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: superAdmin.listBank.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  return (
    <Formik
      initialValues={id ? { ...cleanBank } : initialState}
      validationSchema={bankSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!confirm) {
          openConfirmModal();
          setSubmitting(false);
        } else {
          if (id) {
            handleEditSubmit(values, setSubmitting);
          } else {
            handlePostSubmit(values, setSubmitting);
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <>
          <form onSubmit={openConfirmModal}>
            <Box className={classes.box}>
              <TitleBar title={superAdmin.addBank.text}></TitleBar>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent style={{ textAlign: "center" }}>
                      {values.logo ? (
                        <img
                          src={
                            typeof values.logo === "string"
                              ? values.logo
                              : URL.createObjectURL(values.logo)
                          }
                          className={classes.logo}
                          alt="logo"
                        />
                      ) : (
                        <>Pas de Logo</>
                      )}
                    </CardContent>
                    <CardActions>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        name="logo"
                        value={
                          values.logo && values.logo[0] === "object"
                            ? values.logo[0]
                            : ""
                        }
                        onChange={(e) => {
                          setFieldValue("logo", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="outlined"
                          color="default"
                          fullWidth
                          component="span"
                          startIcon={<CloudUploadIcon />}
                        >
                          Télécharger un logo
                        </Button>
                      </label>
                      {values.logo && (
                        <Button
                          variant="outlined"
                          color="default"
                          className={classes.buttonDelete}
                          onClick={() => {
                            setFieldValue("logo", null);
                          }}
                          startIcon={<DeleteIcon />}
                        >
                          Supprimer
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>

                <Grid>
                  <Card className={classes.margin}>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            fullWidth
                            id="name"
                            label="Nom de la banque"
                            name="name"
                            required
                            helperText={
                              errors.name && touched.name && errors.name
                            }
                            error={errors.name && touched.name}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstname}
                            fullWidth
                            id="firstname"
                            label="Prénom du responsable"
                            name="firstname"
                            required
                            helperText={
                              errors.firstname &&
                              touched.firstname &&
                              errors.firstname
                            }
                            error={errors.firstname && touched.firstname}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastname.toUpperCase()}
                            fullWidth
                            id="lastname"
                            label="Nom du responsable"
                            name="lastname"
                            required
                            helperText={
                              errors.lastname &&
                              touched.lastname &&
                              errors.lastname
                            }
                            error={errors.lastname && touched.lastname}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            required
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                            error={errors.email && touched.email}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            fullWidth
                            id="phone"
                            label="Téléphone de la banque"
                            name="phone"
                            required
                            helperText={
                              errors.phone && touched.phone && errors.phone
                            }
                            error={errors.phone && touched.phone}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                            fullWidth
                            id="address"
                            label="Adresse de la banque"
                            name="address"
                            required
                            helperText={
                              errors.address &&
                              touched.address &&
                              errors.address
                            }
                            error={errors.address && touched.address}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zipCode}
                            fullWidth
                            id="zipCode"
                            label="Code postal"
                            name="zipCode"
                            required
                            error={errors.zipCode && touched.zipCode}
                            helperText={
                              errors.zipCode &&
                              touched.zipCode &&
                              errors.zipCode
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            fullWidth
                            id="city"
                            label="Ville"
                            name="city"
                            required
                            helperText={
                              errors.city && touched.city && errors.city
                            }
                            error={errors.city && touched.city}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>

                    <CardActions className={classes.cardActions}></CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <ActionsFooter>
              <div className={classes.wrapper}>
                <Button
                  color="default"
                  disabled={isSubmitting}
                  className={classes.button}
                  variant="contained"
                  onClick={() =>
                    history.push({ pathname: superAdmin.listBank.path })
                  }
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Enregistrer
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                    thickness={5}
                  />
                )}
              </div>
            </ActionsFooter>
          </form>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              Veuillez confirmer l'enregistrement
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Banque
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Représentant
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.firstname} {values.lastname.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Adresse mail
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Téléphone
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Adresse
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.address} <br />
                    {values.zipCode} {values.city}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="default"
                disabled={isSubmitting}
                variant="contained"
                onClick={handleClose}
              >
                Modifier
              </Button>
              <div className={classes.buttons}>
                <div className={classes.wrapper}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      setConfirm(true);
                      handleSubmit();
                    }}
                  >
                    Enregistrer
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      className={classes.buttonProgress}
                      size={24}
                      thickness={5}
                    />
                  )}
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Formik>
  );
}

export default withSnackbar(BankAdminForm);
