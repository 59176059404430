import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  wrapper: {
    textAlign: "center",
  },
  ccm: {
    maxWidth: "100%",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    color: "#034370",
  },
}));

const CCMPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Comment ça marche ?"
              titleTypographyProps={{ variant: "h6" }}
              className={classes.cardHeader}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className={classes.wrapper}>
                    <img
                      alt="explain"
                      src="https://snapdoc-public.s3.eu-west-3.amazonaws.com/CCM.PNG"
                      className={classes.ccm}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CCMPage;
