import amber from "@material-ui/core/colors/amber";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import indigo from "@material-ui/core/colors/indigo";
import teal from "@material-ui/core/colors/teal";
import green from "@material-ui/core/colors/green";

export const USER_ROLE = {
  USER: "USER",
  AGENT: "AGENT",
  ADMIN: "ADMIN",
};

export const SNAPDOC_STATUS = {
  INIT: {
    color: amber[600],
    label: "Initialisé",
    text: "INIT",
  },
  WAITING_REVIEW: {
    label: "En attente",
    color: blue[500],
    text: "WAITING_REVIEW",
  },
  COMPLETED: {
    label: "Complété",
    color: indigo[500],
    text: "COMPLETED",
  },
  VALIDATED_INFO: {
    label: "Informations validées",
    color: teal[500],
    text: "VALIDATED_INFO",
  },
  CHALLENGE_SEND: {
    label: "Challenge banques",
    color: green[500],
    text: "CHALLENGE_SEND",
  },
  CLOSED: {
    label: "Cloturé",
    color: red[700],
    text: "CLOSED",
  },
};
