import AuthLayout from "./AuthLayout";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { string, object } from "yup";
import { useHistory, useParams } from "react-router";
import { withSnackbar } from "notistack";

import CookieService from "../../services/CookieService";
import UserService from "../../services/UserService";
import {
  disconnected,
  privateRoutes,
  superAdmin,
  userRoutes,
} from "../../routes";
import { USER_ROLE } from "../../utils/enum";
import { formatError, redirectPage } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    fontSize: "1.125rem",
    borderRadius: "0.25rem",
  },
  wrapper: {
    position: "relative",
    textAlign: "center",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  lostPassword: {
    color: "#2093C6",
    marginBottom: "1rem",
  },
  checkboxColor: {
    color: "#E2E8F0",
  },
  centerText: {
    textAlign: "center",
  },
  textLinkStyle: {
    color: "#2093C6",
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

const loginSchema = () =>
  object().shape({
    password: string().required("Ce champ est requis"),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
  });

const LoginPage = ({ sessionStore, enqueueSnackbar }) => {
  const history = useHistory();
  const classes = useStyles();
  const { email = "" } = useParams();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    redirectPage(sessionStore, history);
  }, [history, sessionStore]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthLayout
      title={disconnected.login.text}
      description={disconnected.login.description}
    >
      <Formik
        initialValues={{ email, password: "", remember: false }}
        validationSchema={loginSchema}
        onSubmit={(values, { setSubmitting }) => {
          UserService.signIn(values.email, values.password, values.remember)
            .then((response) => {
              CookieService.setCookie(
                "token",
                response.token,
                values.remember ? 14 : 1
              );
              setSubmitting(false);

              sessionStore.init().then(() => {
                if (response.user && response.user.role === USER_ROLE.ADMIN) {
                  history.push({ pathname: superAdmin.listAgency.path });
                }
                if (response.user && response.user.role === USER_ROLE.AGENT) {
                  history.push({ pathname: privateRoutes.listSnapdoc.path });
                }
                if (response.user && response.user.role === USER_ROLE.USER) {
                  history.push({ pathname: userRoutes.index.path });
                }
              });
            })
            .catch((error) => {
              enqueueSnackbar(
                error.status === 403
                  ? "Identifiants invalides"
                  : "Erreur interne",
                {
                  variant: "error",
                }
              );
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              autoComplete="email"
              error={errors.email && touched.email}
              disabled={isSubmitting}
              fullWidth
              id="email"
              label="Identifiant snapdoc"
              margin="normal"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="email@snapdoc.fr"
              required
              value={values.email}
              variant="outlined"
              helperText={errors.email && touched.email && errors.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              disabled={isSubmitting}
              fullWidth
              id="password"
              label="Mot de passe"
              margin="normal"
              error={errors.password && touched.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="********"
              required
              type={showPassword ? "text" : "password"}
              value={values.password}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="current-password"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item xs className={classes.lostPassword}>
              <Link
                to={disconnected.lostPass.path}
                style={{ textDecoration: "none" }}
                className={classes.lostPassword}
              >
                Mot de passe oublié ?
              </Link>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.remember}
                  className={classes.checkboxColor}
                  color="primary"
                  disabled={isSubmitting}
                  name="remember"
                  onChange={handleChange}
                />
              }
              label="Rester connecté"
            />
            <div className={classes.wrapper}>
              <Button
                className={classes.submit}
                color="primary"
                disabled={isSubmitting}
                fullWidth
                type="submit"
                variant="contained"
              >
                Me connecter
              </Button>
              {isSubmitting && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                  thickness={5}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
      <Grid item className={classes.centerText}>
        <Link
          className={classes.textLinkStyle}
          to={disconnected.register.path}
          style={{ textDecoration: "none" }}
        >
          Première connexion ? Inscription
        </Link>
      </Grid>
    </AuthLayout>
  );
};

export default inject("sessionStore")(withSnackbar(LoginPage));
