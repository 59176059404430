import { ListItem, makeStyles, Typography } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  itemText: {
    fontSize: "0.875rem",
    color: "#034370",
    fontWeight: 600,
  },
  listItem: {
    alignItems: "start",
    textAlign: "start",
    paddingLeft: "1.375rem",
    paddingRight: "1.25rem",
    marginTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  icon: {
    marginRight: "0.813rem",
    color: "#94A3B8",
  },
}));

export const ListItemIconWithText = ({ icon, children }) => {
  const classes = useStyle();
  return (
    <ListItem className={classes.listItem}>
      <Typography component="span" className={classes.icon}>
        {icon}
      </Typography>
      <Typography component="span" className={classes.itemText}>
        {children}
      </Typography>
    </ListItem>
  );
};
