import React from "react";

import AgencyAdminForm from "../../forms/AgencyAdminForm";
import TitleBar from "../../components/TitleBar";
import { superAdmin } from "../../routes";

function AddAgencyAdminPage() {
  return (
    <>
      <AgencyAdminForm />
    </>
  );
}

export default AddAgencyAdminPage;
