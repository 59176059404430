import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import React, { useState } from "react";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";

import UserService from "../services/UserService";
import { formatError } from "../utils/common";
import { SNAPDOC_STATUS } from "../utils/enum";
import CardInformations from "../components/CardInformations";
import InformationLine from "../components/InformationLine";
import { formatNumberDisplay } from "../utils/common";
import { Box } from "@material-ui/core";
import ModalInformationError from "../components/ModalInformationError";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  errorButton: {
    "&:hover": {
      backgroundColor: "#F1F5F9",
    },
    color: "#334155",
    backgroundColor: "#F1F5F9",
    fontSize: "1rem",
    borderRadius: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      marginRight: "1rem",
      width: "fit-content",
    },
  },
  button: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      marginRight: "1rem",
      width: "fit-content",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  comment: {
    textDecoration: "underline",
    marginRight: theme.spacing(4),
    cursor: "pointer",
  },
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },
    flex: 1,
    marginBottom: "7rem",
    padding: `0 ${theme.spacing(3)}px`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10rem",
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  direction: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  boxButtons: {
    position: "fixed",
    bottom: 0,
    background: "white",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
    },
    padding: "1.5rem",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  },
}));

function InformationUserForm({
  enqueueSnackbar,
  sessionStore,
  setHasCommented,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState("");
  const values = sessionStore.user.project;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePostComments = () => {
    UserService.postComments(comments)
      .then((response) => {
        enqueueSnackbar(response, {
          variant: "success",
        });
        setHasCommented(true);
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const handleClick = () => {
    UserService.validSnapdoc()
      .then((response) => {
        enqueueSnackbar(response, {
          variant: "success",
        });
        sessionStore.updateProject(
          "status",
          SNAPDOC_STATUS.VALIDATED_INFO.text
        );
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };
  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={4}>
          <Box className={classes.direction}>
            <CardInformations title="Mon plan de financement">
              <InformationLine
                name="Acquisition"
                value={`${formatNumberDisplay(values.estateAmount)}`}
              />
              <InformationLine
                name="Travaux"
                value={`${formatNumberDisplay(values.worksAmount)}`}
              />
              <InformationLine
                name="Frais d’agence"
                value={`${formatNumberDisplay(values.agencyFees)}`}
              />
              <InformationLine
                name="Frais de notaire estimés"
                value={`${formatNumberDisplay(values.notaryFees)}`}
              />
              <Divider className={classes.divider} />
              <InformationLine
                name="Apport"
                value={`${formatNumberDisplay(values.contribution)}`}
              />
              <Divider className={classes.divider} />
              <InformationLine
                name="Montant du prêt"
                fontSizeValue="1.3rem"
                value={`${formatNumberDisplay(values.loanAmount)}`}
              />
              <InformationLine
                name="Durée"
                fontSizeValue="1.3rem"
                value={`${values.loanDuration} mois`}
              />
            </CardInformations>

            <CardInformations title="Mes revenus">
              <InformationLine
                name="Total des revenus"
                value={`${formatNumberDisplay(values.fiscalReference)}`}
              />
              <InformationLine
                name="Nombre de parts"
                value={`${values.fiscalShare}`}
              />
              <InformationLine
                name="Impôts annuel"
                value={`${formatNumberDisplay(values.amountTaxIncome)}`}
              />
            </CardInformations>
          </Box>
        </Grid>
      </Box>
      <Box>
        <Box className={classes.boxButtons} flex={1} boxShadow={3}>
          <Button
            className={classes.errorButton}
            type="submit"
            onClick={handleClickOpen}
          >
            Une précision ?
          </Button>
          <Button
            className={classes.button}
            color="primary"
            type="submit"
            variant="contained"
            onClick={handleClick}
          >
            Valider
          </Button>
        </Box>
      </Box>
      <ModalInformationError
        open={open}
        handleClose={handleClose}
        onClickButtonComment={handlePostComments}
        comments={comments}
        onChange={(e) => {
          setComments(e.target.value);
        }}
      />
    </>
  );
}

export default inject("sessionStore")(withSnackbar(InformationUserForm));
