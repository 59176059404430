import { Box, Card, Grid, makeStyles, Typography } from "@material-ui/core";

const CardInformations = ({ title, children, color = "#034370" }) => {
  const useStyle = makeStyles((theme) => ({
    root: {
      padding: "1rem",
      margin: "1rem",
    },

    title: {
      fontSize: 18,
      color: color,
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
    },
  }));
  const classes = useStyle();
  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <Typography className={classes.title} component="span">
          {title}
        </Typography>
        <Box marginTop="1.5rem">{children}</Box>
      </Card>
    </Grid>
  );
};

export default CardInformations;
