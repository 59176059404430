import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import logo from "../assets/snapdoc-immo.svg";
import logo2 from "../assets/snapdoc-icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  wrapper: {
    textAlign: "center",
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    color: "#034370",
  },
  title: {
    fontWeight: "bold",
    textDecoration: "underline",
    padding: theme.spacing(2, 0),
  },
  subTitle: {
    fontWeight: "bold",
    padding: theme.spacing(0, 0, 2, 4),
  },
  gridDisplay: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
}));

const CGUPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <img src={logo} alt="logo" className={classes.logo} />
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.gridDisplay}>
          <Card className={classes.card}>
            <CardHeader
              title="CONDITIONS GÉNÉRALES D’UTILISATION"
              titleTypographyProps={{ variant: "h6" }}
              className={classes.cardHeader}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    Les présentes conditions générales d’utilisation, ainsi que
                    tous les documents qu’elles incorporent (ci-après, les « CGU
                    »), ont pour objet de définir les conditions et modalités
                    d’utilisation de la plateforme SNAPDOC, disponible sur le
                    site internet{" "}
                    <a href="www.snapdoc.fr" target="_blank">
                      www.snapdoc.fr
                    </a>
                    , éditée par la société SNAPDOC.
                  </Typography>
                  <br />
                  <Typography>
                    La plateforme SNAPDOC a pour objectif de mettre à
                    disposition un espace sécurisé permettant d’assurer
                    électroniquement la collecte, la sauvegarde, la
                    consultation, l’analyse et le partage de documents à une
                    liste de destinataires proposée à l'Utilisateur. L’analyse
                    des documents consiste via des techniques algorithmiques à
                    extraire les données jugées pertinentes par SNAPDOC.
                  </Typography>
                  <br />
                  <Typography>
                    Les présentes CGU sont accessibles à tout moment par un lien
                    direct sur la plateforme et en page d’accueil de la
                    plateforme. La Société SNAP DOC invite l’Utilisateur à les
                    lire attentivement avant de commencer à utiliser la
                    plateforme.
                  </Typography>
                  <br />
                  <Typography>
                    Pour toute demande relative à l’utilisation de la
                    plateforme, la société SNAPDOC invite l’utilisateur à
                    envoyer un e-mail à l’adresse suivante :{" "}
                    <a href={`mailto:contact@snadoc.fr`} rel="noopener">
                      contact@snapdoc.fr
                    </a>
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 1. DÉFINITIONS
                  </Typography>
                  <Typography component="div">
                    Outre les termes qui seront définis ailleurs dans les CGU,
                    les termes ci-après énumérés et dont la première lettre
                    figurera en majuscule dans les CGU ont la signification
                    mentionnée ci-après. Ces termes auront la même signification
                    au singulier et au pluriel.
                    <br />
                    <br />
                    <b>Compte</b> <br />
                    désigne l’environnement sur le Site auquel un Utilisateur
                    Professionnel ou un Utilisateur Non-Professionnel a accès
                    via la fourniture d’éléments d’identification uniques (clé
                    d’accès, identifiant et mot de passe). La Société propose
                    deux types de comptes aux Utilisateurs déclinés comme suit :
                    <ul>
                      <li>Utilisateur Non-Professionnel ;</li>
                      <li>Utilisateur Professionnel.</li>
                    </ul>
                    <b>Documents</b> <br />
                    désigne les documents numériques déposés par l’Utilisateur
                    Professionnel ou l’Utilisateur Non-Professionnel sur le Site
                    de la Société. <br /> <br />
                    <b>Services</b> <br />
                    désignent les moyens et fonctionnalités mis à disposition de
                    l’Utilisateur Professionnel et de l’Utilisateur
                    Non-Professionnel, par la Société sur le Site, permettant
                    d’assurer électroniquement la collecte, la sauvegarde, la
                    consultation, l’analyse et le partage de Documents au profit
                    de tiers pré-identifiés par SNAPDOC.
                    <br /> <br />
                    <b>Site</b> <br />
                    désigne le site dont l’URL est{" "}
                    <a href="/" target="_blank">
                      https://www.snapdoc.fr
                    </a>{" "}
                    développé par la Société.
                    <br />
                    <br />
                    <b>SNAPDOC PERSONNEL</b> <br />
                    désigne le formulaire de données validées par l’Utilisateur
                    Non-Professionnel lors de son inscription.
                    <br /> <br />
                    <b>Société</b> <br />
                    désigne la société SNAP DOC, société par actions simplifiée
                    au capital de vingt-mille (20.000) euros, dont le siège
                    social est situé 2830 route de Lyons, 76160
                    Saint-Aubin-Épinay, immatriculée au registre du commerce et
                    des sociétés sous le numéro d’identification unique 849 774
                    161 RCS ROUEN. <br /> <br />
                    <b>Utilisateur</b> <br />
                    désigne toute personne, professionnelle ou non, qui navigue
                    sur le Site et qui bénéficie ou non des Services. Il inclut
                    sans s’y limiter l’Utilisateur Non-Professionnel et
                    l’Utilisateur Professionnel.
                    <br /> <br />
                    <b>Utilisateur Non-Professionnel</b> <br />
                    désigne un Utilisateur agissant à des fins personnelles
                    ayant reçu d’un Utilisateur Professionnel une invitation et
                    une clé d’accès lui permettant de créer un Compte afin de
                    bénéficier des Services. <br /> <br />
                    <b>Utilisateur Professionnel</b> <br />
                    désigne un Utilisateur agissant à des fins professionnelles
                    et ayant conclu un contrat avec la Société afin de
                    bénéficier d’un Compte lui permettant d’inviter ses clients
                    à créer un Compte afin de bénéficier des Services en leur
                    qualité d’Utilisateur Non-Professionnel.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 2. CONFIGURATION
                  </Typography>
                  <Typography>
                    Tout Utilisateur doit nécessairement bénéficier d’un accès
                    internet WiFi ou mobile avec une connexion internet
                    disposant d’un débit suffisant. A défaut, le Site peut
                    rencontrer des défaillances ou de forts ralentissements. Au
                    minimum, il est recommandé à l’Utilisateur d’utiliser une
                    connexion « 4G ».
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 3. CHAMPS D’APPLICATION DES CGU
                  </Typography>
                  <Typography className={classes.subTitle}>
                    3.1 Acceptation des CGU
                  </Typography>
                  <Typography>
                    L’accès aux Services est subordonné à l’acceptation des
                    présentes CGU accessibles à tout moment sur le Site.
                    <br />
                    <br />
                    L’acceptation des présentes CGU par les Utilisateurs qui
                    s’inscrivent sur le Site est matérialisée par une case à
                    cocher lors de l’inscription sur la Plateforme.
                    <br />
                    <br />
                    L’acceptation des présentes CGU ne peut être que pleine et
                    entière. Tout accès ou utilisation du Site suppose
                    l’acceptation concomitante et sans réserve des termes des
                    présentes CGU.
                    <br />
                    <br />
                  </Typography>
                  <Typography className={classes.subTitle}>
                    3.2 Délimitation géographique
                  </Typography>
                  <Typography>
                    Le Site s’adresse exclusivement à l’Utilisateur établi ou
                    résidant en France ou à une personne souhaitant conclure une
                    opération sur le territoire français.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 4. ACCÈS AU SITE ET AU COMPTE
                  </Typography>
                  <Typography className={classes.subTitle}>
                    4.1 Age requis
                  </Typography>
                  <Typography>
                    Le Site est réservé aux personnes de plus de dix-huit (18)
                    ans et disposant d’une pleine et entière capacité juridique.
                    <br />
                    <br />
                    Si l’Utilisateur n’a pas l’âge requis, une autorisation
                    préalable de ses parents est nécessaire.
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    4.2 Matériel et équipement, moyens techniques
                  </Typography>
                  <Typography>
                    Le Site est accessible gratuitement à tout Utilisateur
                    disposant d’un accès à internet. Tous les coûts afférents à
                    l’accès au Site sont exclusivement à la charge de
                    l’Utilisateur Non-Professionnel.
                    <br />
                    <br />
                    La Société met en œuvre tous les moyens raisonnables à sa
                    disposition pour assurer un accès de qualité au Site mais
                    n’est tenue à aucune obligation d’y parvenir.
                    <br />
                    <br />
                    La Société ne peut, en outre, être tenue responsable de tout
                    dysfonctionnement du réseau ou des serveurs ou de tout autre
                    événement échappant au contrôle raisonnable qui empêcherait
                    ou dégraderait l’accès au Site.
                    <br />
                    <br />
                    La Société se réserve la possibilité d’interrompre, de
                    suspendre momentanément ou de modifier sans préavis l’accès
                    à tout ou partie du Site afin d’en assurer la maintenance ou
                    pour toute autre raison, sans que l’interruption n’ouvre
                    droit à aucune obligation ni indemnisation.
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    4.3 Compte
                  </Typography>
                  <Typography>
                    L’accès aux Services est subordonné à la création d’un
                    Compte.
                    <br />
                    <br />
                    Aux fins de création d’un Compte, l’Utilisateur sera conduit
                    à fournir des informations personnelles, traitées selon la
                    politique de confidentialité de la Société disponible en
                    cliquant sur le lien suivant :{" "}
                    <a href="/politique-de-confidentialité" target="_blank">
                      https://www.snapdoc.fr/politique-de-confidentialité
                    </a>
                    <br />
                    <br />
                    L’Utilisateur garantit que toutes les informations
                    communiquées par lui dans le formulaire d’inscription sont
                    exactes, conformes à la réalité et sincères et ne sont
                    entachées d’aucun caractère trompeur. Il appartient à
                    l’Utilisateur de communiquer tout changement relatif à ses
                    informations personnelles.
                    <br />
                    <br />
                    Toute inscription contenant des informations inexactes ne
                    sera pas prise en compte.
                    <br />
                    <br /> Les informations telles que le mot de passe, la clé
                    d’accès au Compte et le nom d’utilisateur doivent être
                    traités de façon confidentielle. Il appartient à
                    l’Utilisateur de prendre les mesures nécessaires afin
                    d’assurer la confidentialité de ces informations.
                    <br />
                    <br />
                    L’Utilisateur s’engage à ne pas divulguer la clé d’accès au
                    Compte ainsi que son mot de passe et d’informer la Société
                    de tout accès non autorisé à son Compte.
                    <br />
                    <br />
                    L’Utilisateur s’engage à utiliser personnellement son Compte
                    et à ne permettre à aucun tiers de l’utiliser à sa place ou
                    pour son compte,{" "}
                    <b>sauf à en supporter l’entière responsabilité.</b>
                    <br />
                    <br />
                    La responsabilité de la Société ne saura être engagée dans
                    le cas inverse et elle se réserve, le cas échéant, le droit
                    de suspendre le Compte de l’Utilisateur concerné.
                    <br />
                    <br />
                    La Société se réserve par ailleurs la possibilité de
                    suspendre le Compte de l’Utilisateur si elle considère que
                    ce dernier a violé une des dispositions des CGU.
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    4.4 Durée des Services et clôture de Compte
                  </Typography>
                  <Typography>
                    L’Utilisateur Professionnel s’inscrit sur la Plateforme pour
                    une durée indéterminée conformément au contrat conclu entre
                    la Société et l’Utilisateur Professionnel.
                    <br />
                    <br />
                    L’Utilisateur Non-Professionnel dispose d’un Compte pour la
                    durée de réalisation des Services par la Société,
                    c’est-à-dire jusqu’à ce que l’Utilisateur Non-Professionnel
                    ait réalisé le partage de son SNAPDOC PERSONNEL aux tiers
                    pré-identifiés par la Société.
                    <br />
                    <br />
                    L’Utilisateur Non-Professionnel peut décider de fermer
                    définitivement son Compte à tout moment, de plein droit et
                    sans formalité judiciaire, en sollicitant cette clôture par
                    e-mail à l’adresse suivante :{" "}
                    <a href={`mailto:contact@snadoc.fr`} rel="noopener">
                      contact@snapdoc.fr
                    </a>
                    . La désinscription est effective immédiatement. Elle
                    entraîne la suppression automatique du Compte de
                    l’Utilisateur Non-Professionnel et de toutes les
                    informations qu’il contient.
                    <br />
                    <br />
                    La Société se réserve le droit de suspendre ou de clôturer
                    définitivement le Compte d’un Utilisateur sans préavis, ni
                    indemnité, dans les hypothèses prévues au sein des CGU,
                    notamment en cas de manquement aux dispositions des CGU. La
                    Société en informera l’Utilisateur par e-mail.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 5. CONTENU DU SITE ET DES COMPTES
                  </Typography>
                  <Typography className={classes.subTitle}>
                    5.1 Informations contenues sur le Site
                  </Typography>
                  <Typography>
                    La Société s’engage à rectifier dans les plus brefs délais
                    toutes informations inexactes ou obsolètes pouvant être
                    présentes sur le Site.
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    5.2 Compte Utilisateur Professionnel
                  </Typography>
                  <Typography>
                    L’Utilisateur Professionnel accède à son Compte grâce à un
                    code d’activation qui lui a été attribué par la Société puis
                    définit son propre mode de passe qu’il pourra modifier par
                    la suite.
                    <br />
                    <br /> L’Utilisateur Professionnel peut se connecter à tout
                    moment sur son Compte afin de consulter la liste des
                    Utilisateurs Non-Professionnel pour lesquels il a créé un
                    dossier. <br />
                    <br />
                    Afin de faire bénéficier à un de ses clients des Services de
                    la Société, l’Utilisateur Professionnel doit créer un
                    dossier propre à ce client sur son Compte en renseignant des
                    informations qui lui sont propres et éventuellement
                    télécharger un Document juridiquement valable correspondant.
                    <br />
                    <br /> Une fois l’ensemble des informations saisies, la
                    Société transmet par courriel un code d’activation à
                    validité temporaire l’Utilisateur Non-Professionnel. Le code
                    d’activation permet à l’Utilisateur Non-Professionnel de
                    créer son Compte afin de bénéficier des Services. <br />
                    <br />
                    L’Utilisateur Professionnel s’engage à transmettre par
                    courriel à l’Utilisateur Non-Professionnel ladite clé
                    d’accès dans un délai de deux (2) jours ouvrés suivant la
                    signature du Document. En cas de non-respect du délai, la
                    clé d’accès ne saurait être délivrée et donc le Compte de
                    l’Utilisateur Non-Professionnel ne pourra pas être créé.{" "}
                    <br />
                    <br />
                    L’Utilisateur est invité à consulter le processus mis en
                    place sur le Site par la Société en cliquant sur le lien
                    suivant :{" "}
                    <a href="/comment-ca-marche" target="_blank">
                      https://www.snapdoc.fr/comment-ca-marche
                    </a>
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    5.3 Compte Utilisateur Non-Professionnel
                  </Typography>
                  <Typography>
                    Lorsque l’Utilisateur Non-Professionnel ouvre l’e-mail
                    adressé par l’Utilisateur Professionnel et contenant le code
                    d’activation, il est invité à cliquer sur un lien donnant
                    accès à la page du Site où le code d’activation sera pré
                    renseigné.
                    <br />
                    <br /> L’Utilisateur Professionnel est ensuite invité à
                    définir son mot de passe personnel, modifiable à tout
                    moment, afin d’accéder définitivement à son Compte. Dans ce
                    cadre, l’Utilisateur Non-Professionnel devra renseigner un
                    mot de passe doté d’un niveau de sécurité suffisant. <br />
                    <br />
                    Lorsque l’Utilisateur Non-Professionnel accède à son Compte,
                    ce dernier peut contenir des Documents mis à disposition.
                    <br />
                    <br />
                    Le Compte est une fonctionnalité sécurisée, auquel seul
                    l’Utilisateur Non-Professionnel a accès. <br />
                    <br />
                    Afin d’être mis en relation avec les tiers pré-identifiés
                    par la Société, l’Utilisateur Non-Professionnel s’engage à
                    télécharger tous les documents requis par la Plateforme puis
                    à en demander la vérification par la Société dans le délai
                    imposé par le site.
                    <br />
                    <br />
                    La Société dispose ensuite de deux (2) jours maximum pour
                    traiter les Documents téléchargés.
                    <br />
                    <br />
                    L’Utilisateur Non-Professionnel dispose ensuite de deux (2)
                    jours pour formuler sa demande d’offres personnalisées
                    auprès des tiers pré-identifiés par la Société. <br />
                    <br />
                    En cas de non-respect des délais, le Compte de l’Utilisateur
                    Non-Professionnel sera automatiquement clôturé ; dans ce cas
                    il en sera averti par email. Le processus mise en place sur
                    la Plateforme par la Société est accessible sur le lien
                    suivant :{" "}
                    <a href="/comment-ca-marche" target="_blank">
                      https://www.snapdoc.fr/comment-ca-marche
                    </a>
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 6. PROPRIÉTÉ INTELLECTUELLE
                  </Typography>
                  <Typography>
                    La Société est propriétaire exclusive des droits de
                    propriété intellectuelle ou détient les droits d’usage sur
                    tous les éléments accessibles sur le Site parmi lesquels les
                    composantes visuelles et textuelles du Site, la marque et
                    les logos « <i>SNAPDOC</i> », la charte graphique du Site,
                    la structure et le logiciel mis en œuvre pour le
                    fonctionnement du Site.
                    <br />
                    <br /> L’ensemble de ces éléments est protégé par tous
                    droits de propriété intellectuelle ou droits des producteurs
                    de base de données en vigueur. Toute utilisation ou
                    reproduction, même partielle, non autorisée, de la
                    Plateforme ou de l’un des éléments qu’il contient est
                    strictement interdite et sera considérée comme constitutive
                    d’une contrefaçon. Tout contrevenant sera poursuivi devant
                    les juridictions compétentes. <br />
                    <br />A l'exception des éléments publiés sous une licence
                    libre, toutes reproductions ou représentations, même
                    partielles, par quelque procédé que ce soit, toutes requêtes
                    automatisées ou non visant la récupération des données
                    publiées sur cette plateforme, faites sans l'autorisation de
                    l'éditeur sont illicites et constituent une contrefaçon
                    conformément aux dispositions de la loi du 11 mars 1957,
                    articles 40,41 et 42 du Code Pénal.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 7. DONNÉES PERSONNELLES
                  </Typography>
                  <Typography>
                    La Société peut être amenée à collecter des données
                    personnelles en fonction du Compte dont bénéficie
                    l’Utilisateur.
                    <br />
                    <br /> Par conséquent la Société invite l’Utilisateur à lire
                    la politique de confidentialité et de traitement des données
                    personnelles de la Société en cliquant sur le lien suivant :{" "}
                    <a href="/politique-de-confidentialité" target="_blank">
                      https://www.snapdoc.fr/politique-de-confidentialité
                    </a>{" "}
                    Cette politique fait partie intégrante des présentes CGU.
                    L’acceptation des présentes CGU emporte l’acceptation de la
                    politique de confidentialité rattachée. <br />
                    <br />
                    Il est précisé que la politique de confidentialité est
                    conforme au Règlement européen 2016/679 du 27 avril 2016
                    relatif à la protection des personnes physiques à l’égard du
                    traitement des données à caractère personnel (RGPD).
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 8. UTILISATION DE LA PLATEFORME
                  </Typography>
                  <Typography className={classes.subTitle}>
                    8.1 Charte de bonne conduite
                  </Typography>
                  <Typography component="div">
                    La contribution des Utilisateurs sur le Site est réglementée
                    par la présente Charte de bonne conduite. Elle s’applique à
                    tous les Utilisateurs et à l’utilisation de l’ensemble des
                    Services mis à disposition sur le Site. Les contributions
                    des Utilisateurs doivent être conformes à la législation en
                    vigueur. Par conséquent, toute contribution ne doit pas, de
                    manière non limitative : <br />
                    <ul>
                      <li>
                        contenir un message diffamatoire, obscène, indécent,
                        vulgaire, malveillant, violent, xénophobe, raciste ou
                        autrement illicite ;
                      </li>
                      <li>
                        promouvoir du matériel sexuellement explicite ou
                        pornographique ;
                      </li>
                      <li>
                        être utilisé à des fins de propagande politique,
                        idéologique ou de prosélytisme ;
                      </li>
                      <li>
                        diffuser de la publicité pour des produits et marques
                        concurrentes à la ou les marques présentées sur le Site
                        ;
                      </li>
                      <li>
                        enfreindre tout brevet, marque commerciale, secret
                        commercial, droit d’auteur ou autre droit de propriété
                        intellectuelle ;
                      </li>
                      <li>
                        diffuser des informations personnelles et nominatives
                        sur une personne sans en avoir son consentement ;
                      </li>
                      <li>intimider ou harceler quiconque ;</li>
                      <li>
                        usurper l’identité d’une personne ou donner une image
                        fausse de l’identité de l’Utilisateur ou de son
                        affiliation avec une personne ou un organisme.
                      </li>
                    </ul>
                    Si l’Utilisateur repère un contenu faisant l’apologie de
                    crimes contre l’humanité, incitant à la haine raciale ou à
                    la violence ou relatif à la pornographie de mineurs, ou
                    portant atteinte à tout principe susmentionné, il doit en
                    informer la Société immédiatement à l’adresse suivante :{" "}
                    <a href={`mailto:contact@snadoc.fr`} rel="noopener">
                      contact@snapdoc.fr
                    </a>{" "}
                    <br /> <br />
                    Attention, le fait pour toute personne de présenter un
                    contenu comme illégal dans le seul but de faire cesser
                    l’activité d’une personne ou d’obtenir de retrait d’une
                    publication en présentant des informations fausses et
                    inexacte est illégal.
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    8.2 Usages interdits
                  </Typography>
                  <Typography component="div">
                    L’Utilisateur s’engage à utiliser le Site uniquement à des
                    fins légales et conformément aux présentes CGU. <br />{" "}
                    <br />
                    L’Utilisateur accepte de ne pas utiliser le Site : <br />
                    <ul>
                      <li>
                        d’une façon qui serait en violation des lois ou
                        réglementations applicables en France et au sein de
                        l’Union Européenne ;
                      </li>
                      <li>
                        dans le but d’exploiter, de nuire ou de tenter de nuire
                        ou d’exploiter de quelque sorte que ce soit à des
                        mineurs en les exposant à un contenu inapproprié, en
                        leur demandant des informations personnellement
                        identifiables ou autres.
                      </li>
                      <li>
                        en usurpant l’identité ou en tentant d’usurper
                        l’identité de la Société, d’un employé de la Société,
                        d’un autre Utilisateur ou de toute autre personne ou
                        entité, à quelque fin que ce soit ;
                      </li>
                      <li>
                        en adoptant toute conduite restreignant ou empêchant la
                        jouissance du Site à quelque personne que ce soit.
                      </li>
                    </ul>
                    En outre, l’Utilisateur accepte de ne pas : <br />
                    <ul>
                      <li>
                        utiliser le Site de quelque manière que ce soit qui
                        pourrait désactiver, surcharger, endommager ou
                        détériorer le Site ou gêner l’utilisation du Site par un
                        tiers, y compris sa capacité à se livrer à des activités
                        en temps réel via le Site ;
                      </li>
                      <li>
                        utiliser un robot, un moteur de recherche ou un autre
                        appareil, processus ou moyen automatique pour accéder au
                        Site à quelque fin que ce soit, y compris la
                        surveillance ou la copie de tout matériel sur le Site ;
                      </li>
                      <li>
                        utiliser un processus manuel pour contrôler ou copier
                        tout matériel sur le Site ou à toute autre fin non
                        autorisée sans notre consentement écrit préalable ;
                      </li>
                      <li>
                        utiliser n’importe quel appareil, logiciel ou routine
                        qui gêne le bon fonctionnement du Site ;
                      </li>
                      <li>
                        introduire des virus, des chevaux de Troie, des vers,
                        des bombes logiques ou tout autre matériel malveillant
                        ou nuisible sur le plan technologique ;
                      </li>
                      <li>
                        tenter d'obtenir un accès non autorisé, interférer,
                        endommager ou perturber toute partie du Site, le serveur
                        sur lequel le Site est stocké ou tout serveur,
                        ordinateur ou base de données connecté au Site ;
                      </li>
                      <li>
                        attaquer le Site via une attaque par déni de service ou
                        une attaque par déni de service distribuée ;
                      </li>
                      <li>essayer de nuire au bon fonctionnement du Site.</li>
                    </ul>
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    8.3 Sanction des manquements
                  </Typography>
                  <Typography component="div">
                    En cas de non-respect des présentes CGU, ou, plus
                    généralement, d’infraction aux lois et règlements en vigueur
                    par un Utilisateur, la Société se réserve le droit de
                    prendre toute mesure appropriée et notamment de : <br />
                    <ul>
                      <li>
                        suspendre ou limiter l’accès au Site de l’Utilisateur ;
                      </li>
                      <li>
                        supprimer tout contenu mis en ligne sur le Site par
                        l’Utilisateur ;
                      </li>
                      <li>avertir toute autorité concernée ;</li>
                      <li>engager toute poursuite judiciaire appropriée.</li>
                    </ul>
                    En cas de manquement de l’Utilisateur à une obligation
                    essentielle découlant des présentes CGU, la Société aura la
                    faculté de suspendre ou de clore le Compte de l’Utilisateur,
                    à tout moment, de plein droit et sans formalité judiciaire,
                    sans avis préalable, avec effet immédiat.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 9. AVERTISSEMENT – LIMITATION DE LA RESPONSABILITÉ
                    DE LA SOCIÉTÉ
                  </Typography>
                  <Typography component="div">
                    L’accès au Site est fourni par la Société « <i>tel quel</i>»
                    et «<i>dans la limite des disponibilités</i> ».
                    <br />
                    <br />
                    La Société, soumise à une recherche constante pour en
                    améliorer la performance et le progrès, ne garantit pas aux
                    Utilisateurs que le Site, sera totalement exempt d’erreurs,
                    de vices ou de défauts. Le Site est standard et est
                    nullement proposé à la seule intention d’un Utilisateur
                    donné. Dans ces conditions, la Société ne garantit pas que
                    le Site répondra spécifiquement au besoin de cet Utilisateur
                    donné.
                    <br />
                    <br />
                    La Société met tous les moyens en œuvre pour assurer l’accès
                    au Site et son bon fonctionnement de manière continue.
                    Néanmoins, compte tenu des limites liées à Internet, la
                    Société ne peut exclure que l’accès et le fonctionnement du
                    Site soient interrompus notamment en cas de force majeure,
                    de mauvais fonctionnement des équipements de l’Utilisateur,
                    de dysfonctionnements du réseau internet de l’Utilisateur,
                    ou d’opération de maintenance destinées à améliorer le Site
                    et ses fonctionnalités. De la même façon, une contrainte
                    liée à l’hébergeur, comme en matière de maintenance, ayant
                    des répercussions sur l’accessibilité du Site ne peuvent
                    entraîner la responsabilité de la Société.
                    <br />
                    La Société ne garantit ni l’absence de problème technique,
                    ni la compatibilité avec une configuration et/ou matériel
                    particulier, ni un délai de mise en ligne, et ne pourra être
                    tenue responsable pour des dysfonctionnements liés à
                    l’utilisation de logiciels annexes.
                    <br />
                    <br />
                    En conséquence, la Société ne saurait être tenue responsable
                    d’une interruption du Site, qu’elle soit volontaire ou non,
                    étant précisé qu’elle s’engage à faire ses meilleurs efforts
                    pour limiter les interruptions qui lui seraient imputables.
                    <br />
                    <br />
                    L’Utilisateur accepte expressément que la Société ne saurait
                    être tenue responsable des dommages directs, indirects,
                    fortuits, spéciaux, accidentels, consécutifs ou punitifs,
                    ainsi que pour toute perte de profits ou de revenus, pouvant
                    être encourus par l’Utilisateur directement ou indirectement
                    lié : <br />
                    <ul>
                      <li>à l’accès ou à l’utilisation du Site ;</li>
                      <li>
                        à l’incapacité de l’Utilisateur d’accéder ou d’utiliser
                        le Site ;
                      </li>
                      <li>
                        à tout comportement ou contenus soumis, publiés ou
                        postés sur le Site ;
                      </li>
                      <li>
                        à tout accès, utilisation ou altération non autorisés
                        des transmissions de l’Utilisateur.{" "}
                      </li>
                    </ul>
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 10. LICENCE
                  </Typography>
                  <Typography>
                    La Société accorde aux Utilisateurs une licence et un droit
                    personnels, non transférables et non exclusifs pour utiliser
                    les Services uniquement pour un usage interne, et à la
                    condition que l’Utilisateur renonce à effectuer (et à
                    permettre à un tiers d’effectuer) l’un des actes suivants :
                    copier, modifier, créer une œuvre dérivée, effectuer une
                    ingénierie inverse, assembler ou tenter de découvrir un code
                    source, vendre, céder, accorder des sous-licences, accorder
                    une sûreté ou transférer tout autre droit ou accès au
                    système ou accès à un quelconque de ses produits,
                    informations ou autres contenus disponibles.
                    <br />
                    <br />
                    L’Utilisateur accepte en outre de ne pas modifier les
                    Services ou une quelconque partie de ceux-ci, de quelque
                    manière que ce soit, ni d’utiliser des versions modifiées
                    des Services ou d’autres produits, logiciels ou autres
                    contenus sur le Site afin d’accéder sans autorisation aux
                    Services ou pour quelque raison que ce soit, sans le
                    consentement écrit exprès de la Société. Les Utilisateurs ne
                    peuvent pas essayer d’obtenir un accès non autorisé aux
                    Services ou à un quelconque de ses contenus associés, y
                    compris les systèmes informatiques, les logiciels ou les
                    réseaux.
                    <br />
                    <br />
                    Aucune utilisation commerciale ou redistribution d’un
                    quelconque contenu, logiciel ou autre produit de la Société
                    n’est autorisée, sauf mention expresse dans un accord
                    préalable entre l’Utilisateur et la Société.
                    <br />
                    <br />
                    La Société se réserve le droit, à sa seule discrétion, de
                    mettre fin à l’accès à tout ou partie du Site et aux
                    Services à tout moment, sans préavis.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 11. MODIFICATION DES CONDITIONS GÉNÉRALES
                    D’UTILISATION
                  </Typography>
                  <Typography>
                    Afin de se conformer à la législation en vigueur ou afin
                    d’améliorer l’utilisation du Site, il est possible que la
                    Société révise les présentes CGU.
                    <br />
                    <br />
                    Toute modification est applicable quinze (15) jours après la
                    notification par e-mail de la mise à jour des CGU aux
                    Utilisateurs.
                    <br />
                    <br />
                    L’utilisation continue du Site par l’Utilisateur après la
                    publication des CGU révisées signifie qu’il accepte et agrée
                    ces modifications.
                    <br />
                    <br />
                    En cas de désaccord avec les nouvelles dispositions des CGU,
                    l’Utilisateur s’engage à cesser toute utilisation du Site et
                    à demander la suppression de son Compte à l’adresse suivante
                    :{" "}
                    <a href={`mailto:contact@snadoc.fr`} rel="noopener">
                      contact@snapdoc.fr
                    </a>
                    .
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 12. DROIT APPLICABLE - LITIGES
                  </Typography>
                  <Typography>
                    Les présentes CGU sont régies par la loi française.
                    <br />
                    <br />
                    Tout litige né de l’exécution des présentes CGU ou en
                    relation avec les présentes CGU sera soumis aux juridictions
                    du ressort de la Cour d’Appel de Rouen, sous réserve des
                    dispositions applicables aux consommateurs.
                    <br />
                    <br />
                    Les présentes CGU sont à jour du règlement du parlement
                    européen et du conseil du 20 juin 2019 promouvant l’équité
                    et la transparence pour les entreprises utilisatrices de
                    services d’intermédiation en ligne.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 13. DISPOSITIONS DIVERSES
                  </Typography>
                  <Typography>
                    <b>13.1</b> La langue d’interprétation des présentes CGU est
                    la langue française.
                    <br />
                    <br />
                    <b>13.2</b> En cas de contradiction entre les CGU et
                    d’autres éléments, règles ou lignes directrices figurants
                    sur le Site, les CGU prévaudront.
                    <br />
                    <br />
                    <b>13.3</b> Si une ou plusieurs stipulations des présentes
                    CGU étai(en)t déclarée(s) nulle(s) en application d’une loi,
                    d’un règlement ou à la suite d’une décision définitive d’une
                    juridiction compétente, les autres stipulations garderont
                    tous leurs effets.
                    <br />
                    <br />
                    <b>13.4</b> Aucune tolérance quelle qu'en soit la nature,
                    l'ampleur, la durée ou la fréquence ne pourra être
                    considérée comme créatrice d'un quelconque droit, ni être
                    interprétée comme une renonciation à l'une quelconque des
                    dispositions des CGU.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 14. ENTRÉE EN VIGUEUR ET MISE À JOUR
                  </Typography>
                  <Typography>
                    Les présentes CGU sont entrées en vigueur le 01/12/2019.
                  </Typography>
                  <br />
                  <div className={classes.wrapper}>
                    <img src={logo2} alt="logo" className={classes.logo} />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CGUPage;
