import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";

import AdminService from "../../services/AdminService";
import BankAdminForm from "../../forms/BankAdminForm";
import Loader from "../../components/Loader";
import TitleBar from "../../components/TitleBar";
import { superAdmin } from "../../routes";

function EditBankAdminPage() {
  const history = useHistory();
  const { bankId } = useParams();
  const [loading, setLoading] = useState(true);
  const [bank, setBank] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await AdminService.getBank(bankId)
        .then((response) => {
          setBank(response);
          setLoading(false);
        })
        .catch(() => history.push({ pathname: superAdmin.listBank.path }));
    }
    fetchData();
  }, [bankId, history]);

  return (
    <>
      <TitleBar title={superAdmin.editBank.text}></TitleBar>
      {loading ? <Loader /> : <BankAdminForm bank={bank} />}
    </>
  );
}

export default EditBankAdminPage;
