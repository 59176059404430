import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";

import AdminService from "../../services/AdminService";
import TitleBar from "../../components/TitleBar";
import { superAdmin } from "../../routes";
import Loader from "../../components/Loader";
import AgentAdminForm from "../../forms/AgentAdminForm";

function EditAgentAdminPage() {
  const history = useHistory();
  const { agentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [agent, setAgent] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await AdminService.getUser(agentId)
        .then((response) => {
          setAgent(response);
          setLoading(false);
        })
        .catch(() => history.push({ pathname: superAdmin.listAgent.path }));
    }
    fetchData();
  }, [agentId, history]);

  return (
    <>
      <TitleBar title={superAdmin.editAgent.text}></TitleBar>
      {loading ? <Loader /> : <AgentAdminForm agent={agent} />}
    </>
  );
}

export default EditAgentAdminPage;
