import analysis from "../assets/analysis.svg";
import contact from "../assets/contact.svg";
import info from "../assets/info.svg";
import thanks from "../assets/thanks.svg";
import warning from "../assets/warning.svg";

const InformationIcon = ({ variant = "analysis", className }) => {
  switch (variant) {
    case "analysis":
      variant = analysis;
      break;
    case "contact":
      variant = contact;
      break;
    case "info":
      variant = info;
      break;
    case "thanks":
      variant = thanks;
      break;
    case "warning":
      variant = warning;
      break;

    default:
      variant = analysis;
      break;
  }
  return <img src={variant} className={className} />;
};
export default InformationIcon;
