import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import logo from "../assets/snapdoc-immo.svg";
import logo2 from "../assets/snapdoc-icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  wrapper: {
    textAlign: "center",
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    color: "#034370",
  },
  title: {
    fontWeight: "bold",
    textDecoration: "underline",
    padding: theme.spacing(2, 0),
  },
  subTitle: {
    fontWeight: "bold",
    padding: theme.spacing(0, 0, 2, 4),
  },
  gridDisplay: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
}));

const POLCONFPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <img src={logo} alt="logo" className={classes.logo} />
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.gridDisplay}>
          <Card className={classes.card}>
            <CardHeader
              title="POLITIQUE DE CONFIDENTIALITÉ"
              titleTypographyProps={{ variant: "h6" }}
              className={classes.cardHeader}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    La société SNAPDOC (ci-après « <b>SNAPDOC</b> ») est une
                    plateforme mettant à disposition de ses utilisateurs un
                    espace sécurisé permettant d’assurer électroniquement la
                    collecte, la sauvegarde, la consultation, l’analyse et le
                    partage de documents à une liste de destinataires proposée à
                    l'utilisateur.
                    <br />
                    <br />
                    Toute référence à <b>nous</b>, <b>notre</b> ou <b>nos </b>
                    dans la présente politique de confidentialité désignera
                    SNAPDOC, qui est une société par actions simplifiée au
                    capital de vingt-mille (20.000) euros, dont le siège social
                    est situé 2830 route de Lyons, 76160 Saint-Aubin-Épinay,
                    immatriculée au registre du commerce et des sociétés de
                    Rouen sous le numéro d’identification unique 849 774 161.
                    <br />
                    <br />
                    Soucieuse de la protection de la vie privée, SNAPDOC
                    s’engage à assurer le meilleur niveau de protection des
                    données à caractère personnel des Utilisateurs du présent
                    Site{" "}
                    <a href="www.snapdoc.fr" target="_blank">
                      www.snapdoc.fr
                    </a>{" "}
                    en sa version web et mobile.
                    <br />
                    <br />
                    En accédant au Site et en utilisant les Services,
                    l’Utilisateur reconnait avoir lu et compris la présente
                    politique de confidentialité. <br />
                    <br />
                    L’Utilisateur est invité à consulter les Conditions
                    générales d'utilisation du Site, s’il souhaite d’avantage
                    d’informations sur l’utilisation du Site.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 1. DÉFINITIONS
                  </Typography>
                  <Typography component="div">
                    Outre les termes qui seront définis ailleurs dans la
                    présente politique de confidentialité, les termes ci-après
                    énumérés et dont la première lettre figurera en majuscule
                    ont la signification suivante :
                    <br />
                    <br />
                    <b>Compte</b> <br />
                    désigne l’environnement sur le Site auquel un Utilisateur
                    Professionnel ou un Utilisateur Non-Professionnel a accès
                    via la fourniture d’éléments d’identification uniques (clé
                    d’accès, identifiant et mot de passe). La Société propose
                    deux types de comptes aux Utilisateurs déclinés comme suit :
                    <ul>
                      <li>Utilisateur Non-Professionnel et</li>
                      <li>Utilisateur Professionnel.</li>
                    </ul>
                    <b>Données à caractère personnel</b> <br />
                    désigne toute information se rapportant à une personne
                    physique identifiée ou identifiable, par exemple les
                    adresses électroniques, la date de naissance et le mot de
                    passe. Ces informations ne comprennent pas les données pour
                    lesquelles l'identité a été supprimée ou rendue anonyme
                    <br /> <br />
                    <b>Documents</b> <br />
                    désigne les documents numériques déposés par l’Utilisateur
                    Professionnel ou l’Utilisateur Non-Professionnel sur le Site
                    de la Société. <br /> <br />
                    <b>Services</b> <br />
                    désignent les moyens et fonctionnalités mis à disposition de
                    l’Utilisateur Professionnel et de l’Utilisateur
                    Non-Professionnel, par la Société sur le Site, permettant
                    d’assurer électroniquement la collecte, la sauvegarde, la
                    consultation, l’analyse et le partage de Documents au profit
                    de tiers préidentifiés.
                    <br /> <br />
                    <b>Site</b> <br />
                    désigne le site dont l’URL est{" "}
                    <a href="/" target="_blank">
                      https://www.snapdoc.fr
                    </a>{" "}
                    développé par la Société.
                    <br />
                    <br />
                    <b>Société</b> <br />
                    désigne la société SNAP DOC, société par actions simplifiée
                    au capital de vingt-mille (20.000) euros, dont le siège
                    social est situé 2830 route de Lyons, 76160
                    Saint-Aubin-Épinay, immatriculée au registre du commerce et
                    des sociétés sous le numéro d’identification unique 849 774
                    161 RCS ROUEN <br /> <br />
                    <b>Utilisateur</b> <br />
                    désigne toute personne, professionnelle ou non, qui navigue
                    sur le Site et qui bénéficie ou non des Services.
                    <br /> <br />
                    <b>Utilisateur Non-Professionnel</b> <br />
                    désigne un Utilisateur agissant à des fins personnelles
                    ayant reçu d’un Utilisateur Professionnel une invitation et
                    une clé d’accès lui permettant de créer un Compte afin de
                    bénéficier des Services. <br /> <br />
                    <b>Utilisateur Professionnel</b> <br />
                    désigne un Utilisateur agissant à des fins professionnelles
                    et ayant conclu un contrat avec la Société afin de
                    bénéficier d’un Compte lui permettant d’inviter ses clients
                    à créer un Compte afin de bénéficier des Services en leur
                    qualité d’Utilisateur Non-Professionnel.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 2. RESPONSABLE DE TRAITEMENT ET RESPECT DE LA
                    REGLEMENTATION APPLICABLE
                  </Typography>
                  <Typography>
                    La Société, en tant que responsable de traitement, s’engage
                    à respecter les dispositions de la loi Informatique et
                    Libertés du 6 janvier 1978 et du règlement européen 2016/679
                    du 27 avril 2016 relatif à la protection des personnes
                    physiques à l’égard du traitement des données à caractère
                    personnel et à la libre circulation de ces données (ci-après
                    «
                    <b>
                      <i>RGPD</i>
                    </b>
                    »).
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 3. COLLECTE DES DONNÉES A CARACTERE PERSONNEL
                  </Typography>
                  <Typography component="div">
                    Lorsque les Utilisateurs naviguent sur le Site et utilisent
                    les Services, la Société collecte et traite les Données à
                    caractère personnel qui ont été directement fournies par
                    l’Utilisateur et/ou enregistrées grâce à l’interaction de
                    l’Utilisateur avec les Services.
                    <br />
                    <br />
                    Pour les traitements nécessitant le recueil du consentement,
                    les Données à caractère personnel ne seront collectées que
                    si l’Utilisateur donne son consentement lorsque cela lui est
                    demandé.
                    <br />
                    <br />
                    Dans le cadre des Services proposés, la Société peut être
                    amenée à collecter les Données à caractère personnel
                    suivantes :
                    <ul>
                      <li>nom ; </li>
                      <li>prénom ; </li>
                      <li>
                        données de contact (adresse postale, adresse e-mail,
                        numéro de téléphone) ;
                      </li>
                      <li>sexe ;</li>
                      <li>
                        données fiscales et patrimoniales incluses dans les
                        Documents.
                      </li>
                    </ul>
                    Concernant, en particulier, l’Utilisateur Professionnel les
                    données suivantes collectées sur le Site pourront également
                    être considérées comme des données à caractère personnel
                    lorsqu’elles permettent d’identifier une personne{" "}
                    <u>physique</u> :
                    <ul>
                      <li>numéro de carte professionnelle ; </li>
                      <li>numéro SIRET ; </li>
                      <li>nom de l’agence immobilière ;</li>
                      <li>numéro de téléphone ;</li>
                      <li>adresse complète.</li>
                    </ul>
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 4. FONDEMENTS JURIDIQUES DU TRAITEMENT
                  </Typography>
                  <Typography>
                    La Société traite les Données à caractère personnel des
                    Utilisateurs uniquement dans la mesure où l’un des
                    fondements juridiques suivants s’applique :<br />
                    <br />
                    (i) le consentement de l’Utilisateur a été recueilli pour
                    une ou plusieurs finalités spécifiques ;<br />
                    <br />
                    (ii) le traitement est nécessaire pour l’exécution d’un
                    contrat auquel l’Utilisateur est partie, ou pour prendre des
                    mesures à votre demande avant de conclure un contrat ;<br />
                    <br />
                    (iii) le traitement est nécessaire pour le respect d’une
                    obligation légale à laquelle la Société est soumise ; et/ou
                    <br />
                    <br />
                    (iv) le traitement est nécessaire pour la poursuite des
                    intérêts légitimes de la Société ou des intérêts légitimes
                    d’une tierce partie, sauf si ces intérêts sont outrepassés
                    par vos intérêts, droits ou libertés.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 5. FINALITES DE TRAITEMENT
                  </Typography>
                  <Typography>
                    La Société collecte et traite les Données à caractère
                    personnel des Utilisateurs pour les finalités suivantes :
                    <br />
                    <br />
                    (i) <i>fonctionnement du Site et des Services</i> : les
                    Données à caractère personnel permettent à la Société de
                    fournir les Services aux Utilisateurs.
                    <br />
                    <br />
                    (ii) <i>amélioration du Site et des Services</i> : les
                    Données à caractère personnel permettent à la Société de
                    proposer à l’Utilisateur une expérience de navigation plus
                    fluide et plus pertinente ainsi que d’améliorer les
                    Services.
                    <br />
                    <br />
                    (iii) <i>gestion interne du Site et des Services</i> : la
                    Société traite les Données à caractère personnel de
                    l’Utilisateur afin de contrôler l’activité de l’Utilisateur
                    sur le Site, de sécuriser, de détecter et prévenir la fraude
                    et de prévenir et réparer les dysfonctionnements affectant
                    ses Services.
                    <br />
                    <br />
                    (iv) <i>respect de ses obligations légales</i> : la Société
                    peut être amenée à coopérer avec les autorités
                    administratives et judiciaires et traiter vos données dans
                    ce contexte.
                    <br />
                    <br />
                    La Société apporte une attention particulière à ne collecter
                    que les Données à caractère personnel strictement
                    nécessaires.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 6. DESTINATAIRE DES DONNÉES
                  </Typography>
                  <Typography component="div">
                    Les services internes à la Société peuvent avoir accès aux
                    Données à caractère personnel de l’Utilisateur.
                    <br />
                    <br />
                    La Société transmet les Données à caractère personnel de
                    l’Utilisateur, ce que ce dernier accepte, dans le cadre de
                    ses Services au profit de tiers pré-identifiés.
                    <br />
                    <br />
                    La Société peut utiliser des sous-traitants pour les
                    opérations suivantes :
                    <ul>
                      <li>
                        l’hébergement des données collectées par la Société et
                        le Site ;{" "}
                      </li>
                      <li>
                        les opérations de développement et de maintenance
                        technique du Site et des applicatifs internes ;{" "}
                      </li>
                      <li>
                        l’administration de l’infrastructure informatique
                        interne ;
                      </li>
                      <li>le recueil de la satisfaction client ;</li>
                      <li>
                        l’expédition des e-mails de prospection commerciale.
                      </li>
                    </ul>
                    La Société s’engage à faire ses meilleurs efforts pour que
                    ses éventuels sous-traitants :
                    <ul>
                      <li>respectent leurs obligations relatives au RGPD ;</li>
                      <li>
                        prennent toutes les mesures nécessaires à la protection
                        de la sécurité et de la confidentialité des Données à
                        caractère personnel, y compris en cas de transfert
                        international ;
                      </li>
                      <li>
                        fournissent les garanties suivantes pour veiller à la
                        mise en œuvre des mesures de confidentialité et de
                        sécurité :
                        <ul>
                          <li>indépendance ; </li>
                          <li>
                            conclusion des clauses contractuelles types
                            encadrant tout éventuel transfert des Données à
                            caractère personnel à tout sous-traitant secondaire
                            qui ne serait pas situé sur le territoire de l’Union
                            européenne, ou de tout dispositif équivalent dûment
                            reconnu par les autorités de contrôle ;{" "}
                          </li>
                          <li>
                            contrôles et audits internes réguliers de nature à
                            vérifier la permanence des dispositifs et procédures
                            de protection internes des Données à caractère
                            personnel, pendant tout le temps de leur
                            conservation par la Société, tous traitements
                            confondus ; et{" "}
                          </li>
                          <li>
                            mise en œuvre et maintien d’une procédure de
                            réception et d’exécution des demandes d’accès, de
                            rectification ou de suppression émanant des
                            personnes physiques concernées, et permettant
                            l’information corrélative du client desdites
                            demandes.{" "}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    La Société peut également partager les Données à caractère
                    personnel de l’Utilisateur pour se défendre contre toute
                    action en justice ou pour satisfaire à ses obligations
                    légales.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 7. TRANSFERT DES DONNÉES HORS ESPACE ECONOMIQUE
                    EUROPEEN
                  </Typography>
                  <Typography>
                    L’Utilisateur est informé que des Données à caractère
                    personnel le concernant peuvent être transférées, stockées
                    et utilisées dans des pays en dehors de l’Espace Économique
                    Européen (EEE), dont les lois sur la protection des données
                    à caractère personnel peuvent ne pas être équivalentes à
                    celles de l’EEE pour l’accomplissement des finalités
                    définies à l’article 5 des présentes, à des sociétés situées
                    dans des pays hors de l’Union Européenne.
                    <br />
                    <br /> Dans l’hypothèse d’un transfert hors EEE, la Société
                    mettra en place des sauvegardes appropriées pour la
                    protection des données à caractère personnel des
                    Utilisateurs, telles que la signature de clauses standards
                    de protection des données à caractère personnel entre la
                    Société et ses partenaires internationaux. <br />
                    <br />
                    Le cas échéant, la Société s’engage à en informer
                    l’Utilisateur.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 8. LES DROITS DES UTILISATEURS SUR LES DONNEES
                  </Typography>
                  <Typography className={classes.subTitle}>
                    8.1 Les droits des Utilisateurs
                  </Typography>
                  <Typography component="div">
                    En application des articles 14 à 22 du RGPD, toute personne
                    physique utilisant le service a la faculté d’exercer les
                    droits suivants :
                    <ul>
                      <li>un droits d’accès (article 15) ;</li>
                      <li>un droit de rectification (article 16) ;</li>
                      <li>
                        un droit d’effacement au traitement de ses données
                        (article 17) ;
                      </li>
                      <li>
                        un droit à la limitation du traitement (article 18) ;
                      </li>
                      <li>
                        un droit à la portabilité de ses données (article 20) ;
                      </li>
                      <li>
                        un droit d’opposition au profilage (articles 21 et 22).
                      </li>
                    </ul>
                  </Typography>
                  <br />
                  <Typography className={classes.subTitle}>
                    8.2 L’exercice des droits de l’Utilisateur
                  </Typography>
                  <Typography>
                    Ces droits peuvent être exercés directement auprès de la
                    Société par voie postale, à l’adresse suivante : SNAPDOC,
                    situé 2830 route de Lyons, 76160 Saint-Aubin-Épinay, ou par
                    voie électronique à l’adresse suivante :{" "}
                    <a href={`mailto:contact@snadoc.fr`} rel="noopener">
                      contact@snapdoc.fr
                    </a>{" "}
                    <br /> <br />
                    La demande doit mentionner le nom, prénom, adresse
                    électronique, adresse postale de l’Utilisateur et doit être
                    accompagnée d’un justificatif d’identité.
                    <br />
                    <br />
                    La Société adresse une réponse dans un délai d’un (1) mois
                    après l’exercice du droit. Si la complexité de la demande ou
                    le nombre de demandes formulées à la Société ne permet pas à
                    cette dernière de remplir cette obligation dans le temps
                    imparti, ce délai peut être prolongé de deux (2) mois après
                    information motivée de la personne concernée.
                    <br />
                    <br />
                    En l’absence de réponse donnée par la Société, l’Utilisateur
                    concerné a la faculté de saisir l’autorité de contrôle, la
                    CNIL, et de former un recours juridictionnel.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 9. DURÉE DE CONSERVATION DES DONNÉES
                  </Typography>
                  <Typography component="div">
                    Pour déterminer la période appropriée de conservation des
                    Données à caractère personnel, la Société tient compte de
                    leur nature et de leur caractère sensible, du risque de
                    préjudice pouvant découler de leur utilisation ou
                    divulgation non autorisée, des fins auxquelles la Société
                    les traite, et de la possibilité de réaliser ces fins par
                    d'autres moyens, ainsi que des exigences légales
                    applicables.
                    <br />
                    <br />
                    En vertu du Décret n°2011-219 du 25 février 2011 relatif à
                    la conservation et à la communication des données permettant
                    d’identifier toute personne ayant contribué à la création
                    d’un contenu mis en ligne, l’Utilisateur est informé que la
                    Société, en sa qualité d’hébergeur du Site, a l’obligation
                    de conserver pendant{" "}
                    <u>
                      une durée d’un an à compter du jour de la création des
                      contenus
                    </u>
                    , pour chaque opération contribuant à la création d’un
                    contenu :
                    <ul>
                      <li>
                        l’identifiant de la connexion à l’origine de la
                        communication ;{" "}
                      </li>
                      <li>
                        l’identifiant attribué par le système d’information au
                        contenu, objet de l’opération ;
                      </li>
                      <li>
                        les types de protocoles utilisés pour la connexion au
                        service et pour le transfert des contenus ;
                      </li>
                      <li>la nature de l’opération ;</li>
                      <li>la date et heure de l’opération ;</li>
                      <li>
                        l’identifiant utilisé par l’auteur de l’opération
                        lorsque celui-ci l’a fourni.
                      </li>
                    </ul>
                    <u>En cas de fermeture du Compte</u>, la Société, en sa
                    qualité d’hébergeur du Site, doit également conserver durant
                    un an à compter du jour de la fermeture du Compte les
                    informations fournies lors de la création du Compte par
                    l’Utilisateur, à savoir :
                    <ul>
                      <li>
                        au moment de la création du compte : l’identifiant de
                        cette connexion ;
                      </li>
                      <li>les nom et prénom ou la raison sociale ;</li>
                      <li>les adresses postales associées ;</li>
                      <li>les pseudonymes utilisés ; </li>
                      <li>
                        les adresses de courrier électronique ou de compte
                        associées ;
                      </li>
                      <li>les numéros de téléphone ;</li>
                      <li>
                        le mot de passe ainsi que les données permettant de le
                        vérifier ou de le modifier, dans leur dernière version
                        mise à jour.
                      </li>
                    </ul>
                    Nonobstant ces dispositions particulières, la Société pourra
                    conserver les Données à caractère personnel sous forme
                    d’archivage intermédiaire, et ce pour la durée nécessaire à
                    la satisfaction de ses obligations légales et dans un
                    intérêt administratif, notamment en cas de contentieux. Les
                    Données à caractère personnel pourront être « anonymisées »
                    afin de les conserver pour leur valeur statistique.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 10. CONSERVATION DES DONNÉES APRÈS LA MORT
                  </Typography>
                  <Typography>
                    L’Utilisateur peut formuler des directives relatives à la
                    conservation, à l'effacement et à la communication de ses
                    données à caractère personnel après son décès conformément à
                    l’article 40-1 de la loi 78-17 du 6 janvier 1978. Ces
                    directives peuvent être générales ou particulières.
                    L’Utilisateur peut formuler ses directives anticipées à
                    l’adresse suivante :{" "}
                    <a href={`mailto:contact@snadoc.fr`} rel="noopener">
                      contact@snapdoc.fr
                    </a>
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 11. OBLIGATIONS DE L’UTILISATEUR
                  </Typography>
                  <Typography>
                    L’Utilisateur reconnaît que les Données à caractère
                    personnel divulguées par lui sont valides, à jour et
                    adéquates.
                    <br />
                    <br />
                    Afin de mettre à jour vos préférences et informations, vous
                    pouvez nous contacter à l’adresse suivante :{" "}
                    <a href={`mailto:contact@snadoc.fr`} rel="noopener">
                      contact@snapdoc.fr
                    </a>
                    <br />
                    <br />
                    L’Utilisateur s’engage à ne pas porter atteinte à la vie
                    privée, à l’image et à la protection des Données à caractère
                    personnel de toute personne tierce et ainsi à ne pas
                    communiquer à la Société les données de personnes tierces
                    sans leur consentement.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 12. SÉCURISATION DES DONNÉES
                  </Typography>
                  <Typography>
                    La Société s’engage à mettre en œuvre les mesures techniques
                    et organisationnelles appropriées et raisonnablement
                    nécessaires afin de garantir un niveau de sécurité adapté
                    aux risques encourus pour les droits et libertés des
                    personnes physiques dans le cadre des traitements visées aux
                    présentes.
                    <br />
                    <br />
                    Lorsque des données à caractère personnel sont transférées à
                    des parties tierces pour traitement, la Société veillera à
                    ce que ces parties tierces mettent en œuvre toutes les
                    mesures techniques et organisationnelles appropriées pour
                    assurer la protection des données à caractère personnel
                    conformément aux lois applicables.
                    <br />
                    <br />
                    Ces mesures sont définies en prenant compte de l'état des
                    connaissances, des coûts de mise en œuvre et de la nature,
                    de la portée, du contexte et des finalités du traitement
                    ainsi que des risques identifiés.
                    <br />
                    <br />
                    Cette protection peut être assurée par la mention «{" "}
                    <i>https</i> » dans l'adresse URL du navigateur ainsi que
                    par la représentation d'un cadenas fermé en bas de l'écran.
                    Malheureusement, la transmission d’informations via Internet
                    n’est pas totalement sécurisée. Bien que la Société
                    s’efforce à protéger les données à caractère personnel des
                    Utilisateurs, la Société ne peut pas garantir qu’aucune
                    perte, mauvaise utilisation ou altération ne se produira.
                    Ainsi, la Société ne peut pas être tenue pour responsable si
                    de tels efforts étaient inefficaces.
                    <br />
                    <br />
                    Enfin, lorsque la Société détecte une violation des droits
                    concernant des Données personnelles susceptible d’engendrer
                    un risque élevé pour les droits et libertés des
                    Utilisateurs, ces derniers seront informés de cette
                    violation dans les meilleurs délais.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 13. TRANSFERT D’ENTREPRISE
                  </Typography>
                  <Typography>
                    Dans le cas d'une extension de la croissance et du
                    développement de la Société, une ou plusieurs parties des
                    actifs de la Société peuvent être transférées à un tiers, ou
                    la Société peut fusionner avec une tierce partie. Dans ce
                    cas, les Données à caractère personnel collectées seront
                    également transférées.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 14. REVISION ET ACTUALISATION DE LA POLITIQUE DE
                    CONFIDENTIALITÉ
                  </Typography>
                  <Typography>
                    La présente Politique de confidentialité sera actualisée
                    autant que de besoin afin de répondre aux exigences de la
                    réglementation applicable à la protection des Données à
                    caractère personnel.
                    <br />
                    <br />
                    Si une modification est apportée à la présente politique de
                    confidentialité, la Société s’engage à publier la nouvelle
                    version sur son Site.
                    <br />
                    <br />
                    Toute modification est applicable quinze (15) jours après la
                    notification par e-mail de la mise à jour des CGU aux
                    Utilisateurs.
                    <br />
                    <br />
                    La Société indique la date à laquelle elle a apporté des
                    modifications à la présente politique de confidentialité
                    pour la dernière fois en bas de celle-ci, et toute
                    modification prend effet au moment de sa publication.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 15. DROIT ET JURIDICTION
                  </Typography>
                  <Typography>
                    La présente politique de confidentialité sera régie et
                    interprétée conformément aux lois françaises.
                    <br />
                    <br />A défaut d’accord amiable, l’Utilisateur s’engage à
                    porter leur différend devant les juridictions du ressort de
                    la Cour d’Appel de Rouen, sans préjudice des dispositions
                    applicables aux Utilisateurs ayant la qualité de
                    consommateurs.
                  </Typography>
                  <Typography className={classes.title}>
                    ARTICLE 16. ENTRÉE EN VIGUEUR ET MISE À JOUR
                  </Typography>
                  <Typography>
                    La présente politique de confidentialité sera régie et
                    interprétée conformément aux lois françaises.
                    <br />
                    <br />
                    La présente politique de confidentialité est entrée en
                    vigueur le 01 / 11 /2019
                  </Typography>
                  <br />
                  <div className={classes.wrapper}>
                    <img src={logo2} alt="logo" className={classes.logo} />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default POLCONFPage;
