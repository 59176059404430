import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import { IconButton, Menu } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: 0,
  },
}));

export default function TableAction({ children, disabled }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="Actions"
        aria-owns={anchorEl ? "simple-menu" : null}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        className={classes.icon}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {children}
      </Menu>
    </>
  );
}
