import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";

import AdminService from "../../services/AdminService";
import AgencyAdminForm from "../../forms/AgencyAdminForm";
import TitleBar from "../../components/TitleBar";
import { superAdmin } from "../../routes";
import Loader from "../../components/Loader";

function EditAgencyAdminPage() {
  const history = useHistory();
  const { agencyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [agency, setAgency] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await AdminService.getAgency(agencyId)
        .then((response) => {
          setAgency(response);
          setLoading(false);
        })
        .catch(() => history.push({ pathname: superAdmin.listAgency.path }));
    }
    fetchData();
  }, [agencyId, history]);

  return (
    <>
      <TitleBar title={superAdmin.editAgency.text}></TitleBar>
      {loading ? <Loader /> : <AgencyAdminForm agency={agency} />}
    </>
  );
}

export default EditAgencyAdminPage;
