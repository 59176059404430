import { decorate, action, observable, computed } from "mobx";

import CookieService from "../services/CookieService";
import UserService from "../services/UserService";
import { isTokenValid } from "../utils/jwtUtils";

const HAS_SELECTED_OFFERS = "hasSelectedOffers";
class SessionStore {
  user = {};

  signOut = () => {
    localStorage.removeItem(HAS_SELECTED_OFFERS);
    CookieService.deleteCookie("token");
    this.user = {};
  };

  init = () => {
    if (isTokenValid(CookieService.getCookie("token"))) {
      return UserService.me().then((response) => {
        this.user = response;
      });
    }
    return Promise.resolve();
  };

  updateUser = (key, value) => {
    this.user[key] = value;
  };

  updateProject = (key, value) => {
    this.user.project[key] = value;
  };

  get projectStatus() {
    return this.user.project.status;
  }

  get fullname() {
    return `${this.user.firstname} ${this.user.lastname}`;
  }
  updateHasSelectedOffers = (value) => {
    localStorage.setItem(HAS_SELECTED_OFFERS, value);
  };

  get hasSelectedOffers() {
    return localStorage.getItem(HAS_SELECTED_OFFERS);
  }
}
decorate(SessionStore, {
  fullname: computed,
  init: action,
  projectStatus: computed,
  signOut: action,
  updateProject: action,
  updateHasSelectedOffers: action,
  hasSelectedOffers: computed,
  updateUser: action,
  user: observable,
});

const sessionStore = new SessionStore();
export default sessionStore;
