import AuthLayout from "./AuthLayout";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { string, object, ref } from "yup";
import { useParams, useHistory } from "react-router";
import { withSnackbar } from "notistack";

import UserService from "../../services/UserService";
import { disconnected } from "../../routes";
import { formatError, redirectPage } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    fontSize: "1.125rem",
    borderRadius: "0.25rem",
  },
  conditions: {
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
  },
  progress: {
    margin: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  linkStyle: {
    color: "#2093C6",
    fontSize: "0.8rem",
  },
}));

const resetSchema = () =>
  object().shape({
    password: string()
      .matches(
        /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/,
        "Le mot de passe doit contenir au moins 8 caractères dont : 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial (!@#$%^&*)"
      )
      .required("Ce champ est requis"),
    confirmPassword: string().oneOf(
      [ref("password"), null],
      "Le mot de passe n'est pas identique"
    ),
    resetToken: string().required("Ce champ est requis"),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
  });

function ResetPage({ sessionStore, enqueueSnackbar }) {
  const history = useHistory();
  const { resetToken = "", email = "" } = useParams();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    redirectPage(sessionStore, history);
  }, [history, sessionStore]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values, setSubmitting) => {
    UserService.changePasswordByToken(values)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: disconnected.login.path });
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
        setSubmitting(false);
      });
  };

  return (
    <AuthLayout title={disconnected.resetPassWithCode.text}>
      <Formik
        initialValues={{
          resetToken,
          email,
          password: "",
          confirmPassword: "",
        }}
        validationSchema={resetSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              error={errors.resetToken && touched.resetToken}
              disabled={isSubmitting}
              fullWidth
              id="resetToken"
              label="Code de réinitialisation"
              margin="normal"
              name="resetToken"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="92a47169-2f25-44ce-84f3-ce6284e33bca"
              required
              value={values.resetToken}
              variant="outlined"
              helperText={
                errors.resetToken && touched.resetToken && errors.resetToken
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={errors.email && touched.email}
              disabled={isSubmitting}
              fullWidth
              id="email"
              label="Adresse mail"
              margin="normal"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="email@snapdoc.fr"
              required
              value={values.email}
              variant="outlined"
              helperText={errors.email && touched.email && errors.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              disabled={isSubmitting}
              fullWidth
              id="password"
              label="Nouveau mot de passe"
              margin="normal"
              error={errors.password && touched.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="********"
              required
              type={showPassword ? "text" : "password"}
              value={values.password}
              variant="outlined"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled={isSubmitting}
              fullWidth
              id="confirmPassword"
              label="Confirmation du mot de passe"
              margin="normal"
              error={errors.confirmPassword && touched.confirmPassword}
              helperText={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="********"
              required
              type={showPassword ? "text" : "password"}
              value={values.confirmPassword}
              variant="outlined"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container>
              <Grid item xs>
                <Link
                  className={classes.linkStyle}
                  to={disconnected.login.path}
                  style={{ textDecoration: "none" }}
                >
                  Revenir à la connexion
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={disconnected.lostPass.path}
                  style={{ textDecoration: "none" }}
                  className={classes.linkStyle}
                >
                  {"Je n'ai pas de code de réinitialisation"}
                </Link>
              </Grid>
            </Grid>
            <div className={classes.wrapper}>
              <Button
                className={classes.submit}
                color="primary"
                disabled={isSubmitting}
                fullWidth
                type="submit"
                variant="contained"
              >
                Changer mon mot de passe
              </Button>
              {isSubmitting && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                  thickness={5}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
}

export default inject("sessionStore")(withSnackbar(ResetPage));
