import sessionStore from "../stores/sessionStore";

export const getMonthlyPayment = (offer) => {
  const project = sessionStore.user.project;
  const rate = offer.rate / 100;
  return (
    (project.loanAmount * (offer.rate / 100)) /
    12 /
    (1 - Math.pow(1 + offer.rate / 100 / 12, -project.loanDuration))
  );
};

export const getTotalCredit = (offer) => {
  const project = sessionStore.user.project;
  return (
    getMonthlyPayment(offer) * project.loanDuration -
    project.loanAmount +
    offer.applicationFees +
    offer.consultingFees
  );
};

export const getLoanAmount = ({
  estateAmount,
  agencyFees,
  notaryFees,
  worksAmount,
  contribution,
}) => {
  return new String(
    +estateAmount + +worksAmount + +agencyFees + +notaryFees - +contribution
  );
};
