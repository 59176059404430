import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { withSnackbar } from "notistack";

import AdminService from "../../services/AdminService";
import Loader from "../../components/Loader";
import SnapdocAdminForm from "../../forms/SnapdocAdminForm";
import TitleBar from "../../components/TitleBar";
import { formatError } from "../../utils/common";
import { superAdmin } from "../../routes";

function EditSnapdocAdminPage({ enqueueSnackbar }) {
  const history = useHistory();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await AdminService.getSnapdoc(userId)
        .then((response) => {
          setUser(response);
          setLoading(false);
        })
        .catch((error) => {
          enqueueSnackbar(formatError(error), {
            variant: "error",
          });
          history.push({ pathname: superAdmin.listSnapdoc.path });
        });
    }
    fetchData();
  }, [userId, history, enqueueSnackbar]);

  return <>{loading ? <Loader /> : <SnapdocAdminForm user={user} />}</>;
}

export default withSnackbar(EditSnapdocAdminPage);
