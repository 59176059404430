import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { Typography } from "@material-ui/core";

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#11699B",
    padding: "0.625rem",
    color: "#034370",
    fontSize: "0.875rem",
    width: 8,
    height: 8,
    fontWeight: "bold",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#FFFFFF",
    color: "#11699B",
  },
  completed: {
    backgroundColor: "#002345",
    color: "#11699B",
  },
  disabled: {
    display: "none",
  },
});

function StepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {String(props.icon)}
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: "#034370",
    padding: "0.625rem",
  },
  step: {
    paddingRight: "0.75rem",
    paddingLeft: 0,
  },
  text: {
    color: "#DBF5FF",
  },
  none: {
    display: "none",
  },
}));

export default function CustomizedSteppers({ stepperActiveStep }) {
  const classes = useStyles();
  const steps = [
    "Mes justificatifs",
    "Mes informations",
    "Mes propositions",
    "Mes offres de prêt",
  ];

  return (
    <Stepper
      activeStep={stepperActiveStep}
      className={classes.stepper}
      connector={null}
    >
      {steps.map((label, index) => {
        const isActive = stepperActiveStep === index;
        return (
          <Step key={label} className={classes.step}>
            {isActive ? (
              <StepLabel StepIconComponent={StepIcon}>
                <Typography className={classes.text}>{label}</Typography>
              </StepLabel>
            ) : (
              <StepLabel StepIconComponent={StepIcon}></StepLabel>
            )}
          </Step>
        );
      })}
    </Stepper>
  );
}
