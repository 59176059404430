import React from "react";
import SnapdocAgentForm from "../../forms/SnapdocAgentForm";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    marginTop: "4rem",
  },
}));
function AddSnapdocAgentPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SnapdocAgentForm />
    </div>
  );
}

export default AddSnapdocAgentPage;
