import AuthLayout from "./AuthLayout";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { string, object, boolean, ref } from "yup";
import { useParams, useHistory } from "react-router";
import { withSnackbar } from "notistack";
import { CancelOutlined } from "@material-ui/icons";
import CookieService from "../../services/CookieService";
import UserService from "../../services/UserService";
import { USER_ROLE } from "../../utils/enum";
import {
  disconnected,
  privateRoutes,
  superAdmin,
  userRoutes,
} from "../../routes";
import { formatError, redirectPage } from "../../utils/common";
import { Box, List, ListItem, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    fontSize: "1.125rem",
    borderRadius: "0.25rem",
  },
  textColorModifier: {
    color: "#034370",
    fontSize: "0.875rem",
  },
  checkboxColor: {
    color: "#E2E8F0",
  },
  conditions: {
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    color: "#034370",
  },
  progress: {
    margin: theme.spacing(2, 0, 2),
  },
  wrapper: {
    position: "relative",
    textAlign: "center",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  unavailablePassword: {
    color: "#9CA3AF",
    padding: 0,
  },
  availablePassword: {
    color: "#16A34A",
    padding: 0,
  },
  validationPasswordSignUp: {
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  spacingIconText: {
    marginLeft: 5,
  },
  validPasswordSignUp: {
    color: "#64748B",
    fontSize: "0.925rem",
    marginRight: "2.5rem",
  },
  centerText: {
    textAlign: "center",
  },
  textLinkStyle: {
    color: "#2093C6",
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

const registerSchema = () =>
  object().shape({
    password: string()
      .matches(
        /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/,
        "Le mot de passe doit contenir au moins 8 caractères dont : 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial (!@#$%^&*)"
      )
      .required("Ce champ est requis"),
    confirmPassword: string().oneOf(
      [ref("password"), null],
      "Le mot de passe n'est pas identique"
    ),
    activationCode: string().required("Ce champ est requis"),
    conditions: boolean().oneOf([true], "Ce champ est requis"),
    email: string()
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
  });

function RegisterPage({ sessionStore, enqueueSnackbar }) {
  const history = useHistory();
  const { activationCode = "", email = "" } = useParams();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    redirectPage(sessionStore, history);
  }, [history, sessionStore]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values, setSubmitting) => {
    UserService.register(values)
      .then((response) => {
        CookieService.setCookie("token", response.token, 1);
        setSubmitting(false);
        enqueueSnackbar("Compte activé, Bienvenue.", {
          variant: "success",
        });

        sessionStore.init().then(() => {
          if (response.user && response.user.role === USER_ROLE.ADMIN) {
            history.push({ pathname: superAdmin.listAgency.path });
          }
          if (response.user && response.user.role === USER_ROLE.AGENT) {
            history.push({ pathname: privateRoutes.listSnapdoc.path });
          }
          if (response.user && response.user.role === USER_ROLE.USER) {
            history.push({ pathname: userRoutes.index.path });
          }
        });
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
        setSubmitting(false);
      });
  };
  const isValidPasswordLength = (password) => password.length >= 8;
  const isValidPasswordUppercase = (password) => /[A-Z]/.test(password);
  const isValidPasswordNumberLength = (password) => /[0-9]/.test(password);
  const isValidPasswordSpecialChar = (password) =>
    /[^A-Za-z0-9\n]/.test(password);
  return (
    <AuthLayout
      title="Bienvenue"
      description="Inscrivez-vous et laissez-vous guider, on vous accompagne dans vos
          démarches de prêts immobilier !"
    >
      <Formik
        initialValues={{
          activationCode,
          email,
          password: "",
          confirmPassword: "",
          conditions: false,
        }}
        validationSchema={registerSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              error={errors.activationCode && touched.activationCode}
              disabled={isSubmitting}
              fullWidth
              id="activationCode"
              label="Code d'activation"
              margin="normal"
              name="activationCode"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="1B9D6-FD4B2"
              required
              value={values.activationCode}
              variant="outlined"
              helperText={
                errors.activationCode &&
                touched.activationCode &&
                errors.activationCode
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={errors.email && touched.email}
              disabled={isSubmitting}
              fullWidth
              id="email"
              label="Adresse mail"
              margin="normal"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="email@snapdoc.fr"
              required
              value={values.email}
              variant="outlined"
              helperText={errors.email && touched.email && errors.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              disabled={isSubmitting}
              fullWidth
              id="password"
              label="Mot de passe"
              margin="normal"
              error={errors.password && touched.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="********"
              required
              type={showPassword ? "text" : "password"}
              value={values.password}
              variant="outlined"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled={isSubmitting}
              fullWidth
              id="confirmPassword"
              label="Confirmation du mot de passe"
              margin="normal"
              error={errors.confirmPassword && touched.confirmPassword}
              helperText={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="********"
              required
              type={showPassword ? "text" : "password"}
              value={values.confirmPassword}
              variant="outlined"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box alignItems="flex-start">
              <Typography className={classes.validPasswordSignUp}>
                Votre mot de passe doit contenir au minimum :
              </Typography>
              <List>
                <ListItem
                  className={
                    isValidPasswordLength(values.password)
                      ? classes.availablePassword
                      : classes.unavailablePassword
                  }
                >
                  {isValidPasswordLength(values.password) ? (
                    <CheckCircleOutlineOutlinedIcon
                      className={classes.validationPasswordSignUp}
                    />
                  ) : (
                    <CancelOutlined
                      className={classes.validationPasswordSignUp}
                    />
                  )}

                  <Typography
                    className={`${classes.validationPasswordSignUp} ${classes.spacingIconText}`}
                  >
                    8 caractères
                  </Typography>
                </ListItem>
                <ListItem
                  className={
                    isValidPasswordUppercase(values.password)
                      ? classes.availablePassword
                      : classes.unavailablePassword
                  }
                >
                  {isValidPasswordUppercase(values.password) ? (
                    <CheckCircleOutlineOutlinedIcon
                      className={classes.validationPasswordSignUp}
                    />
                  ) : (
                    <CancelOutlined
                      className={classes.validationPasswordSignUp}
                    />
                  )}

                  <Typography
                    className={`${classes.validationPasswordSignUp} ${classes.spacingIconText}`}
                  >
                    Une majuscule
                  </Typography>
                </ListItem>
                <ListItem
                  className={
                    isValidPasswordNumberLength(values.password)
                      ? classes.availablePassword
                      : classes.unavailablePassword
                  }
                >
                  {isValidPasswordNumberLength(values.password) ? (
                    <CheckCircleOutlineOutlinedIcon
                      className={classes.validationPasswordSignUp}
                    />
                  ) : (
                    <CancelOutlined
                      className={classes.validationPasswordSignUp}
                    />
                  )}

                  <Typography
                    className={`${classes.validationPasswordSignUp} ${classes.spacingIconText}`}
                  >
                    Un chiffre
                  </Typography>
                </ListItem>
                <ListItem
                  className={
                    isValidPasswordSpecialChar(values.password)
                      ? classes.availablePassword
                      : classes.unavailablePassword
                  }
                >
                  {isValidPasswordSpecialChar(values.password) ? (
                    <CheckCircleOutlineOutlinedIcon
                      className={classes.validationPasswordSignUp}
                    />
                  ) : (
                    <CancelOutlined
                      className={classes.validationPasswordSignUp}
                    />
                  )}

                  <Typography
                    className={`${classes.validationPasswordSignUp} ${classes.spacingIconText}`}
                  >
                    Un caractère spécial ($,#,%...)
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <FormControl
              required
              error={errors.conditions && touched.conditions}
              component="fieldset"
              margin="normal"
            >
              <FormGroup>
                <FormControlLabel
                  className={classes.textColorModifier}
                  control={
                    <Checkbox
                      className={classes.checkboxColor}
                      checked={values.conditions}
                      disabled={isSubmitting}
                      name="conditions"
                      onChange={handleChange}
                    />
                  }
                  label={
                    <>
                      J'ai lu et j'accepte les{" "}
                      <Link
                        to={disconnected.cgu.path}
                        target="_blank"
                        rel="noopener"
                        className={classes.conditions}
                      >
                        conditions générales d'utilisation.
                      </Link>
                    </>
                  }
                />
              </FormGroup>
              <FormHelperText>
                {errors.conditions && touched.conditions && errors.conditions}
              </FormHelperText>
            </FormControl>
            <div className={classes.wrapper}>
              <Button
                className={classes.submit}
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                fullWidth
              >
                Inscription
              </Button>
              {isSubmitting && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                  thickness={5}
                />
              )}
            </div>
            <Typography className={classes.centerText}>
              <Link
                className={classes.textLinkStyle}
                to={disconnected.login.path}
                style={{ textDecoration: "none" }}
              >
                Déjà un compte ? Connexion
              </Link>
            </Typography>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
}

export default inject("sessionStore")(withSnackbar(RegisterPage));
