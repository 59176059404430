import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    background: "white",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
    },
    padding: "1.5rem",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    flexDirection: "row",
    justifyContent: "flex-end",
    zIndex: 5,
  },
}));
const ActionsFooter = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};
export default ActionsFooter;
