import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  centerLoader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.centerLoader}>
      <CircularProgress size={80} />
    </div>
  );
}

export default Loader;
