import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { inject } from "mobx-react";
import { Box, makeStyles } from "@material-ui/core";

import { ProjectInformations } from "../../components/ProjectInformations";
import CustomizedSteppers from "../../components/CustomStepper";
import InformationIcon from "../../components/InformationIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8.5rem",
    maxWidth: 345,
    color: "#034370",
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  header: {
    position: "fixed",
    width: "100%",
    zIndex: 5,
    top: "55px",
    [theme.breakpoints.up("md")]: {
      top: 0,
    },
  },
  logo: {
    width: "150px",
    margin: "auto",
    marginTop: "3rem",
    marginBottom: "1.5rem",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
    maxWidth: "14rem",
    margin: "auto",
  },
  description: {
    fontSize: 16,
    marginBottom: "1rem",
  },
  margin: {
    margin: theme.spacing(3, 0),
  },
  text: {
    marginTop: theme.spacing(3),
    fontSize: "18px",
  },
  link: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#034370",
  },
}));

function FinishWithoutOffersPage() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box className={classes.header}>
        <ProjectInformations activeStep={1} />
        <CustomizedSteppers stepperActiveStep={3} />
      </Box>
      <Box className={classes.root}>
        <InformationIcon variant="thanks" className={classes.logo} />
        <Typography className={classes.title}>
          Merci d’avoir utilisé snapdoc !
        </Typography>
        <Typography className={classes.description}>
          Nous sommes désolé que vous n’ayez pas trouvé d’offres de prêt
          correspondants à vos attentes.
        </Typography>
        <Typography className={classes.description}>
          Vous pouvez nous faire un retour à{" "}
          <a href="mailto:contact@snapdoc.fr" className={classes.link}>
            contact@snapdoc.fr
          </a>
          .
        </Typography>
        <Typography className={classes.description}>
          Nous vous souhaitons une excellente continuation.
        </Typography>
      </Box>
    </>
  );
}

export default inject("sessionStore")(FinishWithoutOffersPage);
