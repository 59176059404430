import queryString from "query-string";

import { USER_ROLE } from "./enum";
import { superAdmin, privateRoutes, userRoutes } from "../routes";

export const formatNumberWithComma = (number) =>
  typeof number === "string" ? number.replace(/\./gi, ",") : number;

export const formatQuery = (params) => {
  let query = queryString.stringify(params);
  if (query) {
    query = `?${query}`;
  }
  return query;
};

export const formatError = (error) => error?.data?.error || "Erreur interne.";

// To clean prevent erros from InputBase with initial values at null
export const formatObjectWithoutNull = (values) =>
  Object.entries(values).reduce(
    (acc, cur) => ({
      ...acc,
      [cur[0]]: cur[1] === null || cur[1] === undefined ? "" : cur[1],
    }),
    {}
  );

export const formatNumberDisplay = (number) =>
  new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }).format(number);

export const fileIcon = `<svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585786 1.25278C0.960859 0.877706 1.46957 0.666992 2 0.666992H6.66667C6.84348 0.666992 7.01305 0.73723 7.13807 0.862254L11.8047 5.52892C11.9298 5.65394 12 5.82351 12 6.00033V13.3337C12 13.8641 11.7893 14.3728 11.4142 14.7479C11.0391 15.1229 10.5304 15.3337 10 15.3337H2C1.46957 15.3337 0.960859 15.1229 0.585786 14.7479C0.210714 14.3728 0 13.8641 0 13.3337V2.66699C0 2.13656 0.210714 1.62785 0.585786 1.25278ZM2 2.00033C1.82319 2.00033 1.65362 2.07056 1.5286 2.19559C1.40357 2.32061 1.33333 2.49018 1.33333 2.66699V13.3337C1.33333 13.5105 1.40357 13.68 1.5286 13.8051C1.65362 13.9301 1.82319 14.0003 2 14.0003H10C10.1768 14.0003 10.3464 13.9301 10.4714 13.8051C10.5964 13.68 10.6667 13.5105 10.6667 13.3337V6.27647L6.39052 2.00033H2Z" fill="#8999AE"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66667 0.666992C7.03486 0.666992 7.33333 0.965469 7.33333 1.33366V5.33366H11.3333C11.7015 5.33366 12 5.63214 12 6.00033C12 6.36851 11.7015 6.66699 11.3333 6.66699H6.66667C6.29848 6.66699 6 6.36851 6 6.00033V1.33366C6 0.965469 6.29848 0.666992 6.66667 0.666992Z" fill="#8999AE"/></svg>')`;

export const removeIcon =
  '<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#8999AE" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" /></svg>';

export const redirectPage = (sessionStore = {}, history) => {
  if (sessionStore.user && sessionStore.user.role === USER_ROLE.ADMIN) {
    history.push({ pathname: superAdmin.listAgency.path });
  }
  if (sessionStore.user && sessionStore.user.role === USER_ROLE.AGENT) {
    history.push({ pathname: privateRoutes.listSnapdoc.path });
  }
  if (sessionStore.user && sessionStore.user.role === USER_ROLE.USER) {
    history.push({ pathname: userRoutes.index.path });
  }
};
