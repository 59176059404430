import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import AdminService from "../services/AdminService";
import { agencySchema } from "../utils/schema";
import { formatError } from "../utils/common";
import { superAdmin } from "../routes";
import { Box } from "@material-ui/core";
import TitleBar from "../components/TitleBar";
import ActionsFooter from "../components/ActionsFooter";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttons: {
    position: "fixed",
    bottom: 0,
    background: "white",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
    },
    padding: "1.5rem",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonDelete: {
    color: red[500],
    borderColor: red[500],
  },
  logo: {
    maxWidth: "100%",
    maxHeight: 150,
  },
  card: {
    marginTop: "2rem",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  dialogTitle: {
    color: "#7d819d",
  },
  dialogText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  dialogContents: {
    marginBottom: "7rem",
  },
  button: {
    fontSize: "1rem",
    borderRadius: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      marginRight: "1rem",
      width: "fit-content",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  grid: {
    margin: "1rem",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginTop: 0,
    },
    marginTop: "5rem",
    margin: "1rem",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginTop: "3rem",
    },
    marginBottom: "8rem",
    marginTop: "3rem",
    margin: "1rem",
  },
}));

const constructFormData = (values) => {
  const agencyData = new FormData();
  agencyData.set("logo", values.logo);
  agencyData.set("address", values.address);
  agencyData.set("city", values.city);
  agencyData.set("email", values.email);
  agencyData.set("firstname", values.firstname);
  agencyData.set("lastname", values.lastname.toUpperCase());
  agencyData.set("name", values.name);
  agencyData.set("phone", values.phone);
  agencyData.set("zipCode", values.zipCode);
  return agencyData;
};

const initialState = {
  name: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  address: "",
  zipCode: "",
  city: "",
};

function AgencyAdminForm({
  enqueueSnackbar,
  agency: { createdAt, id, updateAt, ...cleanAgency } = {},
}) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openConfirmModal = () => {
    setOpen(true);
  };

  const handlePostSubmit = (values, setSubmitting) => {
    const agencyData = constructFormData(values);
    AdminService.postAgency(agencyData)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: superAdmin.listAgency.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const handleEditSubmit = (values, setSubmitting) => {
    const agencyData = constructFormData(values);
    AdminService.patchAgency(id, agencyData)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: superAdmin.listAgency.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  return (
    <Formik
      initialValues={id ? { ...cleanAgency } : initialState}
      validationSchema={agencySchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!confirm) {
          openConfirmModal();
          setSubmitting(false);
        } else {
          if (id) {
            handleEditSubmit(values, setSubmitting);
          } else {
            handlePostSubmit(values, setSubmitting);
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <>
          <form onSubmit={openConfirmModal}>
            <Grid item xs={12} className={classes.grid}>
              <TitleBar title={superAdmin.addAgency.text}></TitleBar>
              <Card>
                <CardContent style={{ textAlign: "center" }}>
                  {values.logo ? (
                    <img
                      src={
                        typeof values.logo === "string"
                          ? values.logo
                          : URL.createObjectURL(values.logo)
                      }
                      className={classes.logo}
                      alt="logo"
                    />
                  ) : (
                    <>Pas de Logo</>
                  )}
                </CardContent>
                <Divider />
                <CardActions>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    name="logo"
                    value={
                      values.logo && values.logo[0] === "object"
                        ? values.logo[0]
                        : ""
                    }
                    onChange={(e) => {
                      setFieldValue("logo", e.target.files[0]);
                    }}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="outlined"
                      color="default"
                      fullWidth
                      component="span"
                      startIcon={<CloudUploadIcon />}
                    >
                      Télécharger un logo
                    </Button>
                  </label>
                  {values.logo && (
                    <Button
                      variant="outlined"
                      color="default"
                      className={classes.buttonDelete}
                      onClick={() => {
                        setFieldValue("logo", null);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Supprimer
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>

            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      fullWidth
                      id="name"
                      label="Nom de l'agence"
                      name="name"
                      required
                      helperText={errors.name && touched.name && errors.name}
                      error={errors.name && touched.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstname}
                      fullWidth
                      id="firstname"
                      label="Prénom du responsable"
                      name="firstname"
                      required
                      helperText={
                        errors.firstname &&
                        touched.firstname &&
                        errors.firstname
                      }
                      error={errors.firstname && touched.firstname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastname.toUpperCase()}
                      fullWidth
                      id="lastname"
                      label="Nom du responsable"
                      name="lastname"
                      required
                      helperText={
                        errors.lastname && touched.lastname && errors.lastname
                      }
                      error={errors.lastname && touched.lastname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      fullWidth
                      id="email"
                      label="Email de l'agence"
                      name="email"
                      required
                      helperText={errors.email && touched.email && errors.email}
                      error={errors.email && touched.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      fullWidth
                      id="phone"
                      label="Téléphone de l'agence"
                      name="phone"
                      required
                      helperText={errors.phone && touched.phone && errors.phone}
                      error={errors.phone && touched.phone}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      fullWidth
                      id="address"
                      label="Adresse de l'agence"
                      name="address"
                      required
                      helperText={
                        errors.address && touched.address && errors.address
                      }
                      error={errors.address && touched.address}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zipCode}
                      fullWidth
                      id="zipCode"
                      label="Code postal"
                      name="zipCode"
                      required
                      error={errors.zipCode && touched.zipCode}
                      helperText={
                        errors.zipCode && touched.zipCode && errors.zipCode
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      fullWidth
                      id="city"
                      label="Ville"
                      name="city"
                      required
                      helperText={errors.city && touched.city && errors.city}
                      error={errors.city && touched.city}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>

            <Box display="flex" className={classes.cardActions}>
              <ActionsFooter>
                <div className={classes.wrapper}>
                  <Button
                    color="default"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.button}
                    onClick={() =>
                      history.push({ pathname: superAdmin.listAgency.path })
                    }
                  >
                    Annuler
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    Enregistrer
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      className={classes.buttonProgress}
                      size={24}
                      thickness={5}
                    />
                  )}
                </div>
              </ActionsFooter>
            </Box>
          </form>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              Veuillez confirmer l'enregistrement
            </DialogTitle>
            <DialogContent className={classes.dialogContents}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Agence
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Représentant
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.firstname} {values.lastname.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Adresse mail
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Téléphone
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" className={classes.dialogTitle}>
                    Adresse
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {values.address} <br />
                    {values.zipCode} {values.city}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={classes.buttons}>
                <div className={classes.wrapper}>
                  <Button
                    color="default"
                    disabled={isSubmitting}
                    variant="contained"
                    onClick={handleClose}
                    className={classes.button}
                  >
                    Modifier
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    className={classes.button}
                    variant="contained"
                    onClick={() => {
                      setConfirm(true);
                      handleSubmit();
                    }}
                  >
                    Enregistrer
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      className={classes.buttonProgress}
                      size={24}
                      thickness={5}
                    />
                  )}
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Formik>
  );
}

export default withSnackbar(AgencyAdminForm);
