import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import clsx from "clsx";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import red from "@material-ui/core/colors/red";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { useHistory, useLocation } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import logo from "../assets/snapdoc-immo.svg";
import { USER_ROLE } from "../utils/enum";
import {
  CloseOutlined,
  MailOutlined,
  MenuOpen,
  PersonOutlineOutlined,
  PhoneOutlined,
} from "@material-ui/icons";
import { ListItemIconWithText } from "./ListItemIconWithText";
import { SectionList } from "./SectionList";
import { Box, Button, Drawer, Typography } from "@material-ui/core";
import AccountMenu from "./AccountMenu";

const styles = (theme) => ({
  paper: {
    width: "20rem",
    [theme.breakpoints.up("md")]: {
      width: 370,
    },
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  logo: {
    maxWidth: "208px",
    maxHeight: "130px",
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  snapdocLogo: {
    maxWidth: 85,
  },
  itemLogo: {
    padding: theme.spacing(2),
    justifyContent: "center",
    cursor: "pointer",
    marginTop: "2rem",
    padding: 0,
  },
  item: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  itemAdmin: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: "bold",
    justifyContent: "center",
  },
  admin: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: "center",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  itemIconActive: {
    color: theme.palette.primary.main,
  },
  itemActive: {
    backgroundColor: "#DBEEF9",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    paddinTop: "0",
    flex: 1,
  },
  containerMenu: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
  },
  divider: {
    backgroundColor: "#DCDCDC",
    paddingBottom: "1px",
    margin: theme.spacing(3, 0, 4, 4),
    width: "70%",
  },
  textPrimary: {
    fontSize: 14,
    color: "#7b7b7b",
  },
  textPrimaryClauses: {
    fontSize: 14,
    color: red[700],
  },
  textSecondary: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  button: {
    position: "absolute",
    right: 0,
    top: 16,
    padding: 0,
    height: "max-content",
    color: "#94A3B8",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  link: {
    color: "#034370",
    padding: theme.spacing(0, 0, 1, 0),
    textDecoration: "none",
    fontSize: 16,
    fontWeight: 700,
  },
  email: {
    width: "12.5rem",
    fontSize: 16,
    color: "#034370",
    fontWeight: 700,
  },
  listItem: {
    paddingBottom: 0,
  },
  agencyName: {
    fontSize: 18,
    color: "#034370",
    fontWeight: 600,
    marginTop: "1rem",
    paddingBottom: 0,
  },
  agencyInfos: {
    fontWeight: 600,
  },
  logoutButton: {
    alignSelf: "center",
    marginRight: "1.25rem",
    color: "#39B7DF",
    fontSize: "0.75rem",
  },
});

const active = (currentPath, pathname) => currentPath === pathname;

function Menu({ classes, routes, sessionStore, ...other }) {
  const location = useLocation();
  const history = useHistory();

  const logoURL =
    sessionStore.user.agency && sessionStore.user.agency.logo
      ? sessionStore.user.agency.logo
      : logo;
  const isAdmin = sessionStore.user.role === USER_ROLE.ADMIN;
  const isUser = sessionStore.user.role === USER_ROLE.USER;
  const isAgent = sessionStore.user.role === USER_ROLE.AGENT;

  const closeMenuOnMobile = () => {
    if (other.variant === "temporary" && other.open) {
      other.onClose();
    }
  };

  const RoutesMenu = () => (
    <List disablePadding onClick={closeMenuOnMobile}>
      {Object.keys(routes).map(
        (key) =>
          !routes[key].hide && (
            <Link
              to={routes[key].path}
              style={{ textDecoration: "none" }}
              key={routes[key].text}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  active(location.pathname, routes[key].path) &&
                    classes.itemActive
                )}
              >
                <ListItemIcon
                  className={clsx(
                    classes.itemIcon,
                    active(location.pathname, routes[key].path) &&
                      classes.itemIconActive
                  )}
                >
                  {routes[key].icon}
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.item,
                  }}
                >
                  {routes[key].text}
                </ListItemText>
              </ListItem>
            </Link>
          )
      )}
    </List>
  );

  const agency =
    sessionStore.user && sessionStore.user.agency
      ? sessionStore.user.agency
      : {};
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Drawer
      variant="permanent"
      {...other}
      classes={{
        paper: classes.paper,
      }}
      anchor={!isDesktop ? "right" : "left"}
    >
      <List className={classes.list}>
        <Box className={classes.containerMenu}>
          <Box>
            <Box display="flex" flexDirection="row">
              <ListItem
                className={classes.itemLogo}
                onClick={() => history.push({ pathname: "/" })}
              >
                <img src={logoURL} alt="logo" className={classes.logo} />
              </ListItem>
              <Button
                onClick={() => other.onClose()}
                className={classes.button}
              >
                <CloseOutlined />
              </Button>
            </Box>
            <ListItem className={classes.listItem}>
              <Typography component="span" className={classes.agencyName}>
                {agency.name}
              </Typography>
            </ListItem>
            {isUser && (
              <List>
                <SectionList
                  title={agency.address.toUpperCase() + " - " + agency.name}
                >
                  <ListItemIconWithText icon={<MailOutlined />}>
                    <a href={`mailto:${agency.email}`} className={classes.link}>
                      <Typography
                        component="span"
                        noWrap
                        className={classes.email}
                      >
                        {agency.email}
                      </Typography>
                    </a>
                  </ListItemIconWithText>
                  <ListItemIconWithText icon={<PhoneOutlined />}>
                    <a className={classes.link} href={`tel:${agency.phone}`}>
                      {agency.phone}
                    </a>
                  </ListItemIconWithText>
                </SectionList>
                <SectionList title="SUPPORT">
                  <ListItemIconWithText icon={<HelpOutlineOutlinedIcon />}>
                    <Link
                      target="_blank"
                      to="/comment-ca-marche"
                      className={classes.link}
                    >
                      Comment ça marche ?
                    </Link>
                  </ListItemIconWithText>
                  <ListItemIconWithText icon={<MenuOpen />}>
                    <Link target="_blank" to="/cgu" className={classes.link}>
                      Conditions générales
                    </Link>
                  </ListItemIconWithText>
                  <ListItemIconWithText icon={<MenuOpen />}>
                    <Link
                      target="_blank"
                      to="/politique-de-confidentialité"
                      className={classes.link}
                    >
                      Politique de confidentialité
                    </Link>
                  </ListItemIconWithText>
                  <ListItemIconWithText icon={<MailOutlined />} fontWeight>
                    <a
                      href={`mailto:contact@snadoc.fr`}
                      target="_blank"
                      rel="noopener"
                      className={classes.link}
                    >
                      contact@snapdoc.fr
                    </a>
                  </ListItemIconWithText>
                </SectionList>
              </List>
            )}

            {isAdmin && (
              <ListItem className={classes.itemAdmin}>
                <Chip className={classes.admin} color="primary" label="ADMIN" />
              </ListItem>
            )}
            {isAgent && (
              <List>
                <ListItemIconWithText icon={<MenuOpen />}>
                  <Link target="_blank" to="/cgu" className={classes.link}>
                    Conditions générales
                  </Link>
                </ListItemIconWithText>
              </List>
            )}
            {!isUser && <RoutesMenu />}
          </Box>
          <Box>
            {isAgent && (
              <ListItemIconWithText icon={<MailOutlined />} fontWeight>
                <a
                  href={`mailto:contact@snadoc.fr`}
                  target="_blank"
                  rel="noopener"
                  className={classes.link}
                >
                  contact@snapdoc.fr
                </a>
              </ListItemIconWithText>
            )}
            <SectionList title="MON COMPTE">
              <AccountMenu>
                <ListItemIconWithText icon={<PersonOutlineOutlined />}>
                  {sessionStore.fullname}
                </ListItemIconWithText>
              </AccountMenu>
            </SectionList>
            <Box
              display="flex"
              marginBottom="1rem"
              className={classes.itemLogo}
            >
              <img src={logo} width="85" />
            </Box>
          </Box>
        </Box>
      </List>
    </Drawer>
  );
}

export default inject("sessionStore")(withStyles(styles)(Menu));
