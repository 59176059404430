import React from "react";

import BankAdminForm from "../../forms/BankAdminForm";

function AddBankAdminPage() {
  return (
    <>
      <BankAdminForm />
    </>
  );
}

export default AddBankAdminPage;
