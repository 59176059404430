import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { inject } from "mobx-react";
import { Box, makeStyles } from "@material-ui/core";

import { ProjectInformations } from "../../components/ProjectInformations";
import CustomizedSteppers from "../../components/CustomStepper";
import InformationIcon from "../../components/InformationIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8.5rem",
    maxWidth: 345,
    color: "#034370",
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  header: {
    position: "fixed",
    width: "100%",
    zIndex: 5,
    top: "55px",
    [theme.breakpoints.up("md")]: {
      top: 0,
    },
  },
  logo: {
    width: "150px",
    margin: "auto",
    marginTop: "3rem",
    marginBottom: "1.5rem",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
    maxWidth: "14rem",
    margin: "auto",
  },
  description: {
    fontSize: 16,
  },
  margin: {
    margin: theme.spacing(3, 0),
  },
  text: {
    marginTop: theme.spacing(3),
    fontSize: "18px",
  },
  bold: {
    fontWeight: 700,
  },
}));

function FinishWithOffersPage({ sessionStore }) {
  const classes = useStyles();
  const project = sessionStore.user.project || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxDate = moment(project.dateLimitClauses);

  const currentDate = moment().startOf("day");

  const dateDiff = moment(maxDate).diff(moment(currentDate), "days");
  return (
    <>
      <Box className={classes.header}>
        <ProjectInformations activeStep={1} />
        <CustomizedSteppers stepperActiveStep={3} />
      </Box>
      <Box className={classes.root}>
        <InformationIcon variant="contact" className={classes.logo} />
        <Typography component="p" className={classes.title}>
          C'est parti !
        </Typography>
        <Typography component="p">
          Il vous reste{" "}
          <span className={classes.bold}>
            {" "}
            {dateDiff} jour{dateDiff > 1 && "s"}
          </span>
        </Typography>
        <Typography className={classes.description}>
          <Typography component="p" className={classes.bold}>
            pour adresser vos offres de pêts à votre agent immobilier
          </Typography>
          <br />
          <Typography component="p" className={classes.bold}>
            Vous pouvez quitter votre espace snapdoc Immo.
          </Typography>
        </Typography>
        <br />
        <Typography component="p" className={classes.description}>
          À très bientôt !
        </Typography>
      </Box>
    </>
  );
}

export default inject("sessionStore")(FinishWithOffersPage);
