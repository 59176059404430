import React from "react";
import { useLocation } from "react-router";

import { superAdmin, privateRoutes, userRoutes } from "../routes";
import { inject, observer } from "mobx-react";
import { USER_ROLE } from "../utils/enum";
import Layout from "./Layout";

const AppShell = ({ children, sessionStore }) => {
  const location = useLocation();

  if (
    location.pathname === "/cgu" ||
    location.pathname === "/politique-de-confidentialité" ||
    location.pathname === "/comment-ca-marche" ||
    location.pathname === "/merci" ||
    location.pathname === "/contact"
  ) {
    return children;
  }

  if (sessionStore.user.role === USER_ROLE.ADMIN) {
    return <Layout routes={superAdmin}>{children}</Layout>;
  }

  if (sessionStore.user.role === USER_ROLE.AGENT) {
    return <Layout routes={privateRoutes}>{children}</Layout>;
  }

  if (sessionStore.user.role === USER_ROLE.USER) {
    return <Layout routes={userRoutes}>{children}</Layout>;
  }

  return children;
};

export default inject("sessionStore")(observer(AppShell));
