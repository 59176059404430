import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import fileDownload from "js-file-download";
import moment from "moment";
import { FilePond, registerPlugin } from "react-filepond";
import { Formik } from "formik";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";

import "filepond/dist/filepond.min.css";
import UserService from "../services/UserService";
import { fileIcon, formatError, removeIcon } from "../utils/common";
import Infobar from "../components/Infobar";

import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import {
  userDocumentSchema,
  VALID_TYPE_DOCUMENT,
  getDocumentType,
} from "../utils/schema";
import { ProjectInformations } from "../components/ProjectInformations";
import CustomizedSteppers from "../components/CustomStepper";
import { Box, Typography } from "@material-ui/core";
import CardInformations from "../components/CardInformations";

// Register the plugins
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const useStyles = makeStyles((theme) => ({
  header: {
    position: "fixed",
    width: "100%",
    zIndex: 5,
    top: "55px",
    [theme.breakpoints.up("md")]: {
      top: 0,
    },
  },
  root: {
    height: "100%",
    marginBottom: "5rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  textHeader: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "10rem",
    [theme.breakpoints.up("md")]: {
      paddingTop: "6.5rem",
    },
    margin: "1.5rem",
  },
  title: {
    color: "#034370",
    fontSize: "1.5rem",
  },
  description: {
    color: "#034370",
    fontSize: "1rem",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    fontSize: "1rem",
    borderRadius: "0.5rem",
    [theme.breakpoints.up("md")]: {
      width: "fit-content",
    },
  },
  boldText: {
    fontWeight: "bold",
  },
  buttons: {
    position: "fixed",
    bottom: 0,
    background: "white",
    width: "100%",
    padding: "1.5rem",
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
      padding: "1.5rem",
      paddingTop: 0,
      paddingBottom: 0,
    },
    zIndex: 5,
  },
  buttonProgress: {
    color: "white",
    marginLeft: "1rem",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  helperError: {
    fontSize: "0.75rem",
    color: "#f44336",
    marginLeft: theme.spacing(2),
  },
  filePointer: {
    cursor: "pointer",
  },
}));

const constructFormData = (values) => {
  const folderData = new FormData();
  folderData.set("taxNotice", values.taxNotices[0]);
  return folderData;
};

function UserDocumentForm({
  sessionStore,
  enqueueSnackbar,
  user: { createdAt, id, updateAt, agency, project, ...user } = {},
}) {
  const classes = useStyles();

  const handleSubmit = (values, setSubmitting) => {
    const folderData = constructFormData(values);
    UserService.postDocument(folderData)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        sessionStore.init();
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const downloadDocument = (documentName) => {
    UserService.downloadDocument(documentName)
      .then((response) => {
        fileDownload(response, documentName);
      })
      .catch((error) => {
        enqueueSnackbar("Impossible de télécharger le fichier.", {
          variant: "error",
        });
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box className={classes.header}>
        <ProjectInformations activeStep={1} />
        <CustomizedSteppers stepperActiveStep={0} />
      </Box>

      <Formik
        initialValues={{
          ...user,
          ...project,
          agreements: [
            new File([], project.agreement, {
              type: "application/pdf",
            }),
          ],
          taxNotices: project.taxNotice
            ? [new File([], project.taxNotice)]
            : [],
        }}
        validationSchema={userDocumentSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.root}>
              <Grid item xs={12}>
                <Box className={classes.textHeader}>
                  <Typography
                    component="span"
                    className={`${classes.title} ${classes.boldText}`}
                  >
                    Mes documents
                  </Typography>
                  <Typography component="span" className={classes.description}>
                    Pour découvrir vos offres personnalisées justifiez vos
                    revenus et votre plan de financement.
                  </Typography>
                </Box>
                <CardInformations title="Mes revenus" className={classes.card}>
                  {values.taxNotices.length === 0 ? (
                    <Infobar
                      icon={AccessTimeIcon}
                      message={
                        <Typography component="span">
                          Importez votre dernier avis d’impôt{" "}
                          <span className={classes.boldText}>
                            avant le{" "}
                            {moment(user.activationDate)
                              .add(2, "days")
                              .format("DD MMMM")}{" "}
                          </span>
                        </Typography>
                      }
                    />
                  ) : (
                    <Infobar
                      color="#16A34A"
                      textProps={{ color: "#14532D" }}
                      message="Votre avis d'imposition est bien importé"
                      icon={CheckCircleOutlineOutlinedIcon}
                      backgroundColor="#F0FDF4"
                    />
                  )}

                  <Grid item xs={12}>
                    <FilePond
                      id="name"
                      name="files"
                      files={values.taxNotices}
                      allowMultiple={false}
                      onupdatefiles={(fileItems) =>
                        setFieldValue(
                          "taxNotices",
                          fileItems.map((fileItem) => fileItem.file)
                        )
                      }
                      acceptedFileTypes={VALID_TYPE_DOCUMENT}
                      fileValidateTypeDetectType={getDocumentType}
                      labelFileTypeNotAllowed="Fichier invalide"
                      fileValidateTypeLabelExpectedTypes="Accepte les images et les PDF"
                      maxFileSize="20MB"
                      labelMaxFileSizeExceeded="Fichier trop lourd"
                      labelMaxFileSize="Taille maximum : {filesize}"
                      labelIdle="Mon dernier avis d'impôt sur les revenus"
                      iconRemove={removeIcon}
                    />
                    {errors.taxNotices && touched.taxNotices && (
                      <span className={classes.helperError}>
                        {errors.taxNotices}
                      </span>
                    )}
                  </Grid>
                </CardInformations>
              </Grid>

              <Grid item xs={12}>
                <CardInformations title="Mon plan de financement">
                  <Infobar
                    color="#16A34A"
                    textProps={{ color: "#14532D" }}
                    message="Votre compromis est déjà importé"
                    icon={CheckCircleOutlineOutlinedIcon}
                    backgroundColor="#F0FDF4"
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className={classes.filePointer}>
                        <FilePond
                          id="name"
                          name="files"
                          files={values.agreements}
                          allowMultiple={false}
                          allowDrop={false}
                          onactivatefile={(file) => {
                            downloadDocument(file.filename);
                          }}
                          beforeRemoveFile={(file) => {
                            downloadDocument(file.filename);
                            return false;
                          }}
                          labelIdle="Télécharger le compromis"
                          iconRemove={fileIcon}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <CardActions className={classes.cardActions}></CardActions>
                </CardInformations>
              </Grid>
            </div>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.buttons}
              boxShadow={3}
            >
              <Button
                className={classes.submit}
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                fullWidth
              >
                Valider
                {isSubmitting && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                    thickness={5}
                  />
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default inject("sessionStore")(withSnackbar(UserDocumentForm));
