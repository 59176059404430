import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import AdminService from "../../services/AdminService";
import ConfirmationModal, {
  stopPropagationForConfirmation,
} from "../../components/ConfirmationModal";
import SnapdocTable from "../../components/SnapdocTable";
import TableAction from "../../components/TableActions";
import TitleBar from "../../components/TitleBar";
import { formatError } from "../../utils/common";
import { superAdmin } from "../../routes";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: "#7d819d",
  },
  dialogText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  margin: {
    margin: theme.spacing(2),
  },
  box: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    marginTop: "4rem",
  },
}));

function ListAgencyAdminPage({ enqueueSnackbar }) {
  const history = useHistory();
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const handleClickOpen = (data) => {
    setOpen(true);
    setData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletion = (agencyId) => {
    AdminService.deleteAgency(agencyId)
      .then((response) => {
        enqueueSnackbar(response, {
          variant: "success",
        });
        setRefresh(!refresh);
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const columns = [
    {
      id: "name",
      label: "Nom",
      sort: true,
    },
    {
      id: "firstname",
      label: "Représentant",
      sort: true,
    },
    {
      id: "createdAt",
      label: "Date d'ajout",
      sort: true,
    },
    {
      id: "actions",
      label: "Actions",
      sort: false,
    },
  ];

  function renderBody(data) {
    return (
      <TableBody>
        {data.map((row) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            style={{ cursor: "pointer" }}
          >
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.name}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.firstname} {row.lastname} <br /> {row.phone}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {moment(row.createdAt).format("DD MMM YYYY")}
            </TableCell>
            <TableCell>
              <TableAction>
                <MenuItem
                  onClick={() =>
                    history.push(
                      superAdmin.editAgency.path.replace(":agencyId", row.id)
                    )
                  }
                >
                  Modifier
                </MenuItem>
                <ConfirmationModal
                  situationText={`Êtes-vous sûr de vouloir supprimer l'agence : ${row.name} ?`}
                >
                  {(confirmBeforeAct) => (
                    <MenuItem
                      onClick={(e) =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleDeletion(row.id))
                        )
                      }
                    >
                      Supprimer
                    </MenuItem>
                  )}
                </ConfirmationModal>
              </TableAction>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  return (
    <>
      <Box margin="1rem" className={classes.box}>
        <TitleBar title={superAdmin.listAgency.text}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({ pathname: superAdmin.addAgency.path })
            }
            startIcon={<AddIcon />}
          >
            {superAdmin.addAgency.text}
          </Button>
        </TitleBar>
        <SnapdocTable
          refresh={refresh}
          columns={columns}
          body={renderBody}
          query={AdminService.getAllAgencies}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        TransitionProps={{ onExit: () => setData({}) }}
      >
        <DialogTitle id="alert-dialog-title">Agence : {data.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Email de l'agence
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Adresse de l'agence
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.address} <br />
                {data.zipCode} {data.city}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withSnackbar(ListAgencyAdminPage);
