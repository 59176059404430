import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Box, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import AdminService from "../../services/AdminService";
import MenuItem from "@material-ui/core/MenuItem";
import SnapdocTable from "../../components/SnapdocTable";
import TableAction from "../../components/TableActions";
import TitleBar from "../../components/TitleBar";
import { formatError } from "../../utils/common";
import { superAdmin } from "../../routes";
import ConfirmationModal, {
  stopPropagationForConfirmation,
} from "../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: "#7d819d",
  },
  dialogText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  margin: {
    margin: theme.spacing(2),
  },
  box: {
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    marginTop: "4rem",
  },
}));

function ListAgentAdminPage({ enqueueSnackbar }) {
  const history = useHistory();
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const handleClickOpen = (data) => {
    setOpen(true);
    setData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletion = (userId) => {
    AdminService.deleteUser(userId)
      .then((response) => {
        enqueueSnackbar(response, {
          variant: "success",
        });
        setRefresh(!refresh);
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const handleUpdateActivation = (userId) => {
    AdminService.updateActivationUser(userId)
      .then((response) => {
        enqueueSnackbar(response, {
          variant: "success",
        });
        setRefresh(!refresh);
      })
      .catch((error) => {
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const columns = [
    {
      id: "agency.name",
      label: "Agence",
      sort: true,
    },
    {
      id: "user.firstname",
      label: "Prénom",
      sort: true,
    },
    {
      id: "user.lastname",
      label: "Nom",
      sort: true,
    },
    {
      id: "user.createdAt",
      label: "Date d'ajout",
      sort: true,
    },
    {
      id: "user.activationDate",
      label: "Date activation",
      sort: false,
    },
    {
      id: "user.active",
      label: "Activé",
      sort: true,
    },
    { id: "actions", label: "Actions", sort: false },
  ];

  function renderBody(data) {
    return (
      <TableBody>
        {data.map((row) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            style={{ cursor: "pointer" }}
          >
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.agency && row.agency.name}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.firstname}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.lastname}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {moment(row.createdAt).format("DD MMM YYYY")}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              {row.active &&
                moment(row.activationDate).format("DD MMM YYYY, HH:mm")}
              {!row.active &&
                row.activationCode &&
                `Code activation : ${row.activationCode}`}
              {!row.active && row.endActivationDate && (
                <>
                  <br /> Expire :{" "}
                  {moment(row.endActivationDate).format("DD MMM YYYY, HH:mm")}
                </>
              )}
            </TableCell>
            <TableCell onClick={() => handleClickOpen(row)}>
              <Checkbox
                disabled
                checked={row.active}
                value="checked"
                inputProps={{
                  "aria-label": "primary checkbox",
                }}
              />
            </TableCell>
            <TableCell>
              <TableAction>
                <MenuItem
                  onClick={() =>
                    history.push(
                      superAdmin.editAgent.path.replace(":agentId", row.id)
                    )
                  }
                >
                  Modifier
                </MenuItem>
                <ConfirmationModal
                  situationText={`Êtes-vous sûr de vouloir supprimer l'utilisateur : ${row.email} ?`}
                >
                  {(confirmBeforeAct) => (
                    <MenuItem
                      onClick={(e) =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleDeletion(row.id))
                        )
                      }
                    >
                      Supprimer
                    </MenuItem>
                  )}
                </ConfirmationModal>
                {!row.active && (
                  <ConfirmationModal
                    situationText={`Êtes-vous sûr de vouloir prolonger le code d'activation de : ${row.firstname} ${row.lastname} ?`}
                  >
                    {(confirmBeforeAct) => (
                      <MenuItem
                        onClick={(e) =>
                          stopPropagationForConfirmation(
                            e,
                            confirmBeforeAct(() =>
                              handleUpdateActivation(row.id)
                            )
                          )
                        }
                      >
                        Prolonger code d'activation
                      </MenuItem>
                    )}
                  </ConfirmationModal>
                )}
              </TableAction>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  return (
    <>
      <Box margin="1rem" className={classes.box}>
        <TitleBar title={superAdmin.listAgent.text}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push({ pathname: superAdmin.addAgent.path })}
            startIcon={<AddIcon />}
          >
            {superAdmin.addAgent.text}
          </Button>
        </TitleBar>
        <SnapdocTable
          refresh={refresh}
          columns={columns}
          body={renderBody}
          query={AdminService.getAllUsers}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        TransitionProps={{ onExit: () => setData({}) }}
      >
        <DialogTitle id="alert-dialog-title">
          Agent : {data.firstname} {data.lastname} (
          {data.agency && data.agency.name})
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Email
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" className={classes.dialogTitle}>
                Téléphone
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {data.phone}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withSnackbar(ListAgentAdminPage);
