export default class CookieService {
  static setCookie(key, value, expirationDays) {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=/`;
  }

  static getCookie(cname) {
    const name = `${cname}=`;
    const cookies = decodeURIComponent(document.cookie).split(";");

    for (let i = 0; i < cookies.length; i += 1) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  }

  static deleteCookie(name) {
    CookieService.setCookie(name, "", 0);
  }
}
