import Close from "@material-ui/icons/Close";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MomentUtils from "@date-io/moment";
import React from "react";
import ReactDOM from "react-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Provider } from "mobx-react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

import "filepond/dist/filepond.min.css";
import "./index.css";
import "moment/locale/fr";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import CircularStd from "./assets/fonts/CircularStd-Book.ttf";
import sessionStore from "./stores/sessionStore";

const stores = {
  sessionStore,
};

const circular = {
  fontFamily: "Circular-std",
  fontStyle: "normal",
  src: `
    url(${CircularStd}) format('truetype')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#202D54",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#36B6DF",
      contrastText: "#FFF",
    },
    background: {
      default: "#f4f6f8",
    },
  },
  typography: {
    fontFamily: '"Lato", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: "Circular-std",
      fontWeight: "bold",
      fontSize: 38,
    },
    h2: {
      fontFamily: "Circular-std",
      fontWeight: "bold",
      fontSize: 34,
    },
    h3: {
      fontFamily: "Circular-std",
      fontSize: 26,
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Circular-std",
    },
    h5: {
      fontFamily: "Circular-std",
    },
    h6: {
      fontFamily: "Circular-std",
      fontWeight: "bold",
    },
    subtitle2: {
      fontWeight: "bold",
    },
  },
  shape: {
    borderRadius: 4,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [circular],
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: "#f4f6f8",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
  },
});

const DismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <Close />
    </IconButton>
  );
};

ReactDOM.render(
  <Provider {...stores}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        action={(key) => <DismissAction id={key} />}
        preventDuplicate
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
