import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { FilePond, registerPlugin } from "react-filepond";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { withSnackbar } from "notistack";

import "filepond/dist/filepond.min.css";
import { privateRoutes } from "../routes";
import { formatError } from "../utils/common";
import AgentService from "../services/AgentService";
import {
  folderAgentSchema,
  VALID_TYPE_DOCUMENT,
  getDocumentType,
} from "../utils/schema";
import { getLoanAmount } from "../utils/offers";

// Register the plugins
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    color: theme.palette.primary.main,
  },
  group: {
    flexDirection: "row",
  },
  formLabel: {
    fontSize: "12px",
  },
  helperError: {
    fontSize: "0.75rem",
    color: "#f44336",
    marginLeft: theme.spacing(2),
  },
  dialogTitle: {
    color: "#7d819d",
  },
  dialogText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const constructFormData = (values) => {
  const folderData = new FormData();
  if (values.address) {
    folderData.set("address", values.address);
  }
  folderData.set("agencyFees", values.agencyFees || 0);
  folderData.set("agreement", values.files[0]);
  if (values.city) {
    folderData.set("city", values.city);
  }
  folderData.set("dateLimitClauses", moment(values.dateLimitClauses).format());
  folderData.set(
    "dateSignAgreement",
    moment(values.dateSignAgreement).format()
  );
  folderData.set("dateSignNotary", moment(values.dateSignNotary).format());
  folderData.set("email", values.email);
  if (values.emailNotary) {
    folderData.set("emailNotary", values.emailNotary);
  }
  folderData.set("estateAmount", values.estateAmount);
  folderData.set("firstname", values.firstname);
  folderData.set("lastname", values.lastname);
  folderData.set("loanAmount", getLoanAmount(values));
  folderData.set("loanDuration", values.loanDuration);
  folderData.set("loanType", values.loanType);
  folderData.set("notaryFees", values.notaryFees);
  folderData.set("phone", values.phone);
  folderData.set("contribution", values.contribution);
  folderData.set("worksAmount", values.worksAmount || 0);
  if (values.zipCode) {
    folderData.set("zipCode", values.zipCode);
  }
  if (values.loanType === "deux") {
    folderData.set("secondBuyerEmail", values.secondBuyerEmail);
    folderData.set("secondBuyerFirstname", values.secondBuyerFirstname);
    folderData.set("secondBuyerLastname", values.secondBuyerLastname);
    folderData.set("secondBuyerPhone", values.secondBuyerPhone);
  }

  return folderData;
};

const initialState = {
  address: "",
  agencyFees: "",
  city: "",
  contribution: "",
  dateLimitClauses: null,
  dateSignAgreement: null,
  dateSignNotary: null,
  email: "",
  emailNotary: "",
  estateAmount: "",
  files: [],
  firstname: "",
  lastname: "",
  loanAmount: "",
  loanDuration: "",
  loanType: "",
  notaryFees: "",
  phone: "",
  secondBuyerEmail: "",
  secondBuyerFirstname: "",
  secondBuyerLastname: "",
  secondBuyerPhone: "",
  worksAmount: "",
  zipCode: "",
};

function SnapdocAgentForm({ enqueueSnackbar }) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openConfirmModal = () => {
    setOpen(true);
  };

  const postForm = (values, setSubmitting) => {
    const folderData = constructFormData(values);
    AgentService.postSnapdoc(folderData)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
        history.push({ pathname: privateRoutes.listSnapdoc.path });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(formatError(error), {
          variant: "error",
        });
      });
  };

  const ButtonsFooter = ({
    handleSubmit,
    isSubmitting,
    validateForm,
    loanType,
  }) => {
    //handleSubmit allowed to show errors if the form is not valid, so I call the handleSubmit to show them
    const isValidForm = async () => {
      const validation = await validateForm();
      const isValid = validation === {} ? openConfirmModal() : handleSubmit();

      return isValid;
    };
    return (
      <CardActions className={classes.cardActions}>
        <Typography variant="caption">
          En validant ces informations, un email sera envoyé à l'acquéreur{" "}
          {loanType === "deux" ? "1" : ""} pour activer son compte snapdoc
        </Typography>
        <Button
          color="default"
          disabled={isSubmitting}
          variant="contained"
          onClick={() =>
            history.push({
              pathname: privateRoutes.listSnapdoc.path,
            })
          }
        >
          Annuler
        </Button>
        <div className={classes.buttons}>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              onClick={isValidForm}
            >
              Enregistrer
            </Button>
            {isSubmitting && (
              <CircularProgress
                className={classes.buttonProgress}
                size={24}
                thickness={5}
              />
            )}
          </div>
        </div>
      </CardActions>
    );
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={folderAgentSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!confirm) {
          openConfirmModal();
          setSubmitting(false);
        } else {
          postForm(values, setSubmitting);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        validateForm,
        isSubmitting,
        setFieldValue,
      }) => {
        return (
          <>
            <form onSubmit={openConfirmModal}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title="Projet"
                      titleTypographyProps={{ variant: "h6" }}
                      className={classes.cardHeader}
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                            fullWidth
                            id="address"
                            label="Adresse du bien"
                            name="address"
                            helperText={
                              errors.address &&
                              touched.address &&
                              errors.address
                            }
                            error={errors.address && touched.address}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zipCode}
                            fullWidth
                            id="zipCode"
                            label="Code postal"
                            name="zipCode"
                            helperText={
                              errors.zipCode &&
                              touched.zipCode &&
                              errors.zipCode
                            }
                            error={errors.zipCode && touched.zipCode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            fullWidth
                            id="city"
                            label="Ville"
                            name="city"
                            helperText={
                              errors.city && touched.city && errors.city
                            }
                            error={errors.city && touched.city}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <DatePicker
                            autoOk
                            id="dateSignAgreement"
                            name="dateSignAgreement"
                            cancelLabel=""
                            okLabel=""
                            margin="dense"
                            format="DD MMM YYYY"
                            inputVariant="outlined"
                            fullWidth
                            label="Signature projet"
                            disableToolbar
                            minDate={moment().subtract(7, "days")}
                            required
                            error={
                              errors.dateSignAgreement &&
                              touched.dateSignAgreement
                            }
                            helperText={
                              errors.dateSignAgreement &&
                              touched.dateSignAgreement &&
                              errors.dateSignAgreement
                            }
                            value={values.dateSignAgreement}
                            onChange={(value) => {
                              setFieldValue("dateSignAgreement", value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="Calendar">
                                    <DateRangeIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <DatePicker
                            autoOk
                            id="dateLimitClauses"
                            name="dateLimitClauses"
                            cancelLabel=""
                            okLabel=""
                            margin="dense"
                            format="DD MMM YYYY"
                            inputVariant="outlined"
                            fullWidth
                            label="Date limite de clauses suspensives"
                            disableToolbar
                            disablePast
                            required
                            error={
                              errors.dateLimitClauses &&
                              touched.dateLimitClauses
                            }
                            helperText={
                              errors.dateLimitClauses &&
                              touched.dateLimitClauses &&
                              errors.dateLimitClauses
                            }
                            value={values.dateLimitClauses}
                            onChange={(value) => {
                              setFieldValue("dateLimitClauses", value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="Calendar">
                                    <DateRangeIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <DatePicker
                            autoOk
                            id="dateSignNotary"
                            name="dateSignNotary"
                            cancelLabel=""
                            okLabel=""
                            margin="dense"
                            format="DD MMM YYYY"
                            inputVariant="outlined"
                            fullWidth
                            label="Signature définitive"
                            disableToolbar
                            disablePast
                            required
                            error={
                              errors.dateSignNotary && touched.dateSignNotary
                            }
                            helperText={
                              errors.dateSignNotary &&
                              touched.dateSignNotary &&
                              errors.dateSignNotary
                            }
                            value={values.dateSignNotary}
                            onChange={(value) => {
                              setFieldValue("dateSignNotary", value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="Calendar">
                                    <DateRangeIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <FilePond
                            id="name"
                            name="files"
                            files={values.files}
                            allowMultiple={false}
                            required={true}
                            onupdatefiles={(fileItems) =>
                              setFieldValue(
                                "files",
                                fileItems.map((fileItem) => fileItem.file)
                              )
                            }
                            acceptedFileTypes={VALID_TYPE_DOCUMENT}
                            fileValidateTypeDetectType={getDocumentType}
                            labelFileTypeNotAllowed="Fichier invalide"
                            fileValidateTypeLabelExpectedTypes="Accepte les images et les PDF"
                            maxFileSize="20MB"
                            labelMaxFileSizeExceeded="Fichier trop lourd"
                            labelMaxFileSize="Taille maximum : {filesize}"
                            labelIdle="Télécharger le compromis"
                            iconRemove='<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" /></svg>'
                          />
                          {errors.files && touched.files && (
                            <span className={classes.helperError}>
                              {errors.files}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.emailNotary}
                            fullWidth
                            id="emailNotary"
                            label="Email notaire"
                            name="emailNotary"
                            helperText={
                              errors.emailNotary &&
                              touched.emailNotary &&
                              errors.emailNotary
                            }
                            error={errors.emailNotary && touched.emailNotary}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <FormControl
                            component="fieldset"
                            error={errors.loanType && touched.loanType}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.formLabel}
                              required
                            >
                              Type d'emprunt
                            </FormLabel>
                            <RadioGroup
                              className={classes.group}
                              required
                              onChange={handleChange}
                              value={values.loanType}
                              aria-label="loanType"
                              id="loanType"
                              name="loanType"
                            >
                              <FormControlLabel
                                value="seul"
                                control={<Radio color="primary" />}
                                label="Seul"
                              />
                              <FormControlLabel
                                value="deux"
                                control={<Radio color="primary" />}
                                label="À deux"
                              />
                            </RadioGroup>
                            {errors.loanType && touched.loanType && (
                              <span className={classes.helperError}>
                                {errors.loanType}
                              </span>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title="Plan de financement"
                      titleTypographyProps={{ variant: "h6" }}
                      className={classes.cardHeader}
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.estateAmount}
                            fullWidth
                            id="estateAmount"
                            label="Acquisition"
                            name="estateAmount"
                            placeholder="250000"
                            required
                            helperText={
                              errors.estateAmount &&
                              touched.estateAmount &&
                              errors.estateAmount
                            }
                            error={errors.estateAmount && touched.estateAmount}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.agencyFees}
                            fullWidth
                            id="agencyFees"
                            label="Frais d'agence"
                            name="agencyFees"
                            placeholder="90000"
                            helperText={
                              errors.agencyFees &&
                              touched.agencyFees &&
                              errors.agencyFees
                            }
                            error={errors.agencyFees && touched.agencyFees}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.worksAmount}
                            fullWidth
                            id="worksAmount"
                            label="Travaux"
                            name="worksAmount"
                            placeholder="30000"
                            helperText={
                              errors.worksAmount &&
                              touched.worksAmount &&
                              errors.worksAmount
                            }
                            error={errors.worksAmount && touched.worksAmount}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.notaryFees}
                            fullWidth
                            id="notaryFees"
                            label="Frais de notaire estimés"
                            name="notaryFees"
                            placeholder="14500"
                            required
                            helperText={
                              errors.notaryFees &&
                              touched.notaryFees &&
                              errors.notaryFees
                            }
                            error={errors.notaryFees && touched.notaryFees}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contribution}
                            fullWidth
                            id="contribution"
                            label="Apport"
                            name="contribution"
                            placeholder="10000"
                            required
                            helperText={
                              errors.contribution &&
                              touched.contribution &&
                              errors.contribution
                            }
                            error={errors.contribution && touched.contribution}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.loanAmount}
                            fullWidth
                            id="loanAmount"
                            label="Montant du prêt"
                            name="loanAmount"
                            helperText={
                              errors.loanAmount &&
                              touched.loanAmount &&
                              errors.loanAmount
                            }
                            disabled
                            error={errors.loanAmount && touched.loanAmount}
                            InputProps={{
                              disabled: true,
                              value: getLoanAmount(values),
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.loanDuration}
                            fullWidth
                            id="loanDuration"
                            label="Durée du prêt"
                            name="loanDuration"
                            placeholder="120"
                            required
                            helperText={
                              errors.loanDuration &&
                              touched.loanDuration &&
                              errors.loanDuration
                            }
                            error={errors.loanDuration && touched.loanDuration}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  mois
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={`Informations acquéreur ${
                        values.loanType === "deux" ? "1" : ""
                      }`}
                      titleTypographyProps={{ variant: "h6" }}
                      className={classes.cardHeader}
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstname}
                            fullWidth
                            id="firstname"
                            label="Prénom"
                            name="firstname"
                            required
                            helperText={
                              errors.firstname &&
                              touched.firstname &&
                              errors.firstname
                            }
                            error={errors.firstname && touched.firstname}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastname}
                            fullWidth
                            id="lastname"
                            label="Nom"
                            name="lastname"
                            required
                            helperText={
                              errors.lastname &&
                              touched.lastname &&
                              errors.lastname
                            }
                            error={errors.lastname && touched.lastname}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            required
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                            error={errors.email && touched.email}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            fullWidth
                            id="phone"
                            label="Téléphone"
                            name="phone"
                            required
                            helperText={
                              errors.phone && touched.phone && errors.phone
                            }
                            error={errors.phone && touched.phone}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    {values.loanType !== "deux" && (
                      <ButtonsFooter
                        handleSubmit={handleSubmit}
                        validateForm={validateForm}
                        isSubmitting={isSubmitting}
                      />
                    )}
                  </Card>
                </Grid>

                {values.loanType === "deux" && (
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title="Informations acquéreur 2"
                        titleTypographyProps={{ variant: "h6" }}
                        className={classes.cardHeader}
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.secondBuyerFirstname}
                              fullWidth
                              id="secondBuyerFirstname"
                              label="Prénom"
                              name="secondBuyerFirstname"
                              required
                              helperText={
                                errors.secondBuyerFirstname &&
                                touched.secondBuyerFirstname &&
                                errors.secondBuyerFirstname
                              }
                              error={
                                errors.secondBuyerFirstname &&
                                touched.secondBuyerFirstname
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.secondBuyerLastname}
                              fullWidth
                              id="secondBuyerLastname"
                              label="Nom"
                              name="secondBuyerLastname"
                              required
                              helperText={
                                errors.secondBuyerLastname &&
                                touched.secondBuyerLastname &&
                                errors.secondBuyerLastname
                              }
                              error={
                                errors.secondBuyerLastname &&
                                touched.secondBuyerLastname
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.secondBuyerEmail}
                              fullWidth
                              id="secondBuyerEmail"
                              label="Email"
                              name="secondBuyerEmail"
                              required
                              helperText={
                                errors.secondBuyerEmail &&
                                touched.secondBuyerEmail &&
                                errors.secondBuyerEmail
                              }
                              error={
                                errors.secondBuyerEmail &&
                                touched.secondBuyerEmail
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.secondBuyerPhone}
                              fullWidth
                              id="secondBuyerPhone"
                              label="Téléphone"
                              name="secondBuyerPhone"
                              required
                              helperText={
                                errors.secondBuyerPhone &&
                                touched.secondBuyerPhone &&
                                errors.secondBuyerPhone
                              }
                              error={
                                errors.secondBuyerPhone &&
                                touched.secondBuyerPhone
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <ButtonsFooter
                        handleSubmit={handleSubmit}
                        validateForm={validateForm}
                        isSubmitting={isSubmitting}
                        loanType={values.loanType}
                      />
                    </Card>
                  </Grid>
                )}
              </Grid>
            </form>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              fullWidth
            >
              <DialogTitle id="alert-dialog-title">
                Veuillez confirmer l'enregistrement du dossier
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Acquéreur
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.firstname} {values.lastname}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Adresse mail
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Téléphone
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.phone}
                    </Typography>
                  </Grid>

                  {values.loanType === "deux" && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="body2"
                          className={classes.dialogTitle}
                        >
                          Acquéreur 2
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.dialogText}
                        >
                          {values.secondBuyerFirstname}{" "}
                          {values.secondBuyerLastname}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="body2"
                          className={classes.dialogTitle}
                        >
                          Adresse mail
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.dialogText}
                        >
                          {values.secondBuyerEmail}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="body2"
                          className={classes.dialogTitle}
                        >
                          Téléphone
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.dialogText}
                        >
                          {values.secondBuyerPhone}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Signature définitive
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {moment(values.dateSignAgreement).format("DD MMM YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Limite clauses suspensives
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {moment(values.dateLimitClauses).format("DD MMM YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Signature notariée
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {moment(values.dateSignNotary).format("DD MMM YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Acquisition
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.estateAmount} €
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Montant du prêt
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {getLoanAmount(values)} €
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Durée
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.loanDuration} mois
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Apport
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.contribution} €
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Frais de notaire estimés
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.notaryFees} €
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Email notaire
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.emailNotary}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className={classes.dialogTitle}>
                      Adresse du bien
                    </Typography>
                    <Typography variant="body1" className={classes.dialogText}>
                      {values.address} <br />
                      {values.zipCode} {values.city}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color="default"
                  disabled={isSubmitting}
                  variant="contained"
                  onClick={handleClose}
                >
                  Modifier
                </Button>
                <div className={classes.buttons}>
                  <div className={classes.wrapper}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        setConfirm(true);
                        handleSubmit();
                      }}
                    >
                      Enregistrer
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.buttonProgress}
                        size={24}
                        thickness={5}
                      />
                    )}
                  </div>
                </div>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
}

export default withSnackbar(SnapdocAgentForm);
