import { Box, makeStyles, Typography } from "@material-ui/core";

export const SectionList = ({ title, children, className }) => {
  const useStyle = makeStyles(() => ({
    title: {
      fontSize: "0.875rem",
      color: "#64748B",
      paddingLeft: "1.25rem",
      paddingTop: "2px",
      padding: "0.5rem",
    },
    children: {
      backgroundColor: "#F8FAFC",
      paddingTop: "0.5rem",
      display: "flex",
      flexDirection: "column",
    },
  }));
  const classes = useStyle();
  return (
    <Box
      alignItems="center"
      textAlign="start"
      width="100%"
      marginTop="1rem"
      className={className}
    >
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.children}>{children}</Typography>
    </Box>
  );
};
