import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../../assets/snapdoc-immo.svg";
import { disconnected } from "../../routes";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(-140deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  container: {
    flex: 1,
  },
  wrap: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    borderRadius: "8px",
    marginTop: "1.5rem",
    padding: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "1rem",
    },
  },
  logo: {
    marginBottom: theme.spacing(4),
    height: "8rem",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
  },
  footer: {
    width: "100%",
    color: "white",
    textAlign: "center",
    padding: theme.spacing(2, 0),
  },
  typo: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    color: "#034370",
  },
  dash: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  links: {
    color: "#F3F4F6",
  },
  copyright: {
    background: "#002345",
    color: "white",
  },
}));

const AuthLayout = ({ title, description, children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={11} sm={8} md={8} lg={4} xl={4}>
            <Paper className={classes.paper}>
              <div className={classes.wrap}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography
                  component="h1"
                  variant="h3"
                  align="center"
                  className={classes.typo}
                >
                  {title}
                </Typography>
                <Box marginTop={4}>
                  <Typography align="center" className={classes.typo}>
                    {description}
                  </Typography>
                </Box>

                {children}
              </div>
            </Paper>
          </Grid>
        </Grid>
        <div className={classes.footer}>
          <Typography component="p" variant="body2" className={classes.typo}>
            <span className={classes.dash}>-</span>
            <span>
              <Link
                className={classes.links}
                href={disconnected.cgu.path}
                target="_blank"
                rel="noopener"
              >
                Conditions générales
              </Link>
            </span>
            <span className={classes.dash}>-</span>
            <span>
              <Link
                className={classes.links}
                href={disconnected.polconf.path}
                target="_blank"
                rel="noopener"
              >
                Politique de confidentialité
              </Link>
            </span>
            <span className={classes.dash}>-</span>
            <span>
              <Link
                className={classes.links}
                href={`mailto:contact@snadoc.fr`}
                target="_blank"
                rel="noopener"
              >
                Nous contacter
              </Link>
            </span>
          </Typography>
        </div>
      </div>
      <Box
        padding={1}
        variant="contained"
        className={classes.copyright}
        textAlign="center"
        position="relative"
        bottom={0}
        width="100%"
      >
        © 2022 - snapdoc
      </Box>
    </>
  );
};

export default AuthLayout;
